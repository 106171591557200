import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ActionButtonWrapper from '~/components/Table/components/ActionButtonWrapper';
import { Transcription } from '~/types/services/transcription-service';

interface ActionButtonsProps {
    record: Transcription;
    url: string;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ record, url }) => {
    const { t } = useTranslation();

    return (
        <ActionButtonWrapper>
            <Link to={`${url}/view/${record.id}`}>
                <Button>{t('basic.viewButton')}</Button>
            </Link>
        </ActionButtonWrapper>
    );
};

export default ActionButtons;
