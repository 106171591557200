import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { usePromiseTracker } from 'react-promise-tracker';
import { useTranslation } from 'react-i18next';
import ServiceEnums from '~/types/shared/service-enums';
import ToastrService from '~/services/toastr-service';
import setFieldBackendError from '~/shared/helpers/backend-responses';
import { ApiResponse } from '~/types/services/common';
import TranscriptionEditForm from '~/pages/Transcription/Edit/components/TranscriptionEditForm';
import TranscriptionService from '~/services/transcription-service';
import { Transcription } from '~/types/services/transcription-service';

const transcriptionService = new TranscriptionService();

const TranscriptionEditPage: React.FC = () => {
    const { t } = useTranslation();
    const { id: transcriptionId } = useParams();
    const [transcription, setTranscription] = useState<Transcription>();
    const history = useHistory();
    const { promiseInProgress } = usePromiseTracker({
        area: ServiceEnums.Transcription
    });

    const isEditing = transcriptionId !== 'new';
    const isViewing = history.location.pathname.indexOf('view') !== -1;

    useEffect(() => {
        if (!isEditing) return;
        const promise = (): Promise<ApiResponse<Transcription>> =>
            transcriptionService.fetchTranscription(transcriptionId);

        promise()
            .then(res => setTranscription(res.data))
            .catch(() => {
                ToastrService.error(
                    t('pages.content.edit.transcriptionNotFound')
                );
                history.push('/content/atirasok');
            });
    }, [history, transcriptionId, isEditing]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = (values, helpers): void => {
        if (!isViewing) {
            const promise = (): Promise<unknown> =>
                isEditing
                    ? transcriptionService.updateTranscription(
                          transcriptionId,
                          values
                      )
                    : transcriptionService.createTranscription(values);

            promise()
                .then(() => {
                    ToastrService.success(
                        t('pages.content.edit.transcriptionSuccessSave')
                    );
                })
                .catch(e => {
                    if (e.response.status === 422) {
                        setFieldBackendError(e.response.data, helpers);
                        if (e.response.data.errors.pylons) {
                            ToastrService.error(e.response.data.errors.pylons);
                        }
                    } else {
                        ToastrService.error(
                            t('pages.content.edit.transcriptionFailedSave')
                        );
                    }
                });
        }
    };

    const handleGenerateAnnex = (sapClientNumber): void => {
        if (isEditing) {
            transcriptionService
                .downloadAnnex(transcriptionId, sapClientNumber)
                .catch(error => {
                    ToastrService.error(error.errors);
                });
        }
    };

    const handleSendBillingEmail = (): void => {
        transcriptionService
            .sendBillingEmail(transcriptionId)
            .then(() => {
                ToastrService.success(t('basic.billingEmailSent'));
            })
            .catch(() => {
                ToastrService.error(t('basic.billingEmailFailed'));
            });
    };

    const action = isViewing
        ? t('pages.content.edit.transcriptionViewTitle')
        : t(
              isEditing
                  ? 'pages.content.edit.transcriptionEditTitle'
                  : 'pages.content.edit.transcriptionCreateTitle'
          );

    return (
        <>
            <h1>{action}</h1>
            <TranscriptionEditForm
                isEditing={isEditing}
                isViewing={isViewing}
                onSubmit={handleSubmit}
                onGenerateAnnex={handleGenerateAnnex}
                onSendBillingEmail={handleSendBillingEmail}
                transcription={transcription}
                loading={promiseInProgress}
            />
        </>
    );
};

export default TranscriptionEditPage;
