import PlanningBuilderFieldNameEnums from '~/types/shared/planning-builder-field-name-enums';
import PylonFieldNamesEnums from '~/types/shared/pylon-field-names-enums';
import PylonFilesEnums from '~/types/shared/pylon-files-enums';
import DeletionBuilderFieldNameEnums from '~/types/shared/deletion-builder-field-name-enums';
import TranscriptionBuilderFieldNameEnums from '~/types/shared/transcription-builder-field-name-enums';
import ExpansionBuilderFieldNameEnums from '~/types/shared/expansion-builder-field-name-enums';

const getPylonsSetFieldName = (fieldName): string | undefined => {
    if (fieldName === PylonFilesEnums.AcceptedPylonFiles) {
        return PylonFieldNamesEnums.ClaimAcceptedPylons;
    }

    if (fieldName === PylonFilesEnums.ValidatedPylonFiles) {
        return PylonFieldNamesEnums.ClaimValidatedPylons;
    }

    if (fieldName === PlanningBuilderFieldNameEnums.PlanningPoles) {
        return PylonFieldNamesEnums.PlanningPylonsField;
    }
    if (fieldName === TranscriptionBuilderFieldNameEnums.TranscriptionPoles) {
        return PylonFieldNamesEnums.TranscriptionPylonsField;
    }

    if (fieldName === DeletionBuilderFieldNameEnums.DeletionPoles) {
        return PylonFieldNamesEnums.DeletionPylonsField;
    }

    if (fieldName === ExpansionBuilderFieldNameEnums.ExpansionPoles) {
        return PylonFieldNamesEnums.ExpansionPylonsField;
    }

    return undefined;
};

export default getPylonsSetFieldName;
