import { Col } from 'antd';
import React from 'react';

interface TableColumnProps {
    visible: boolean;
}
const TableColumn: React.FC<TableColumnProps> = ({ children, visible }) => {
    if (!visible) {
        return null;
    }
    return (
        <Col className="gutter-row" flex="auto">
            {children}
        </Col>
    );
};

export default TableColumn;
