import React from 'react';
import { Button } from 'antd';
import { ActionProps } from '~/components/Table/types/ActionProps';
import ActionButtonWrapper from '~/components/Table/components/ActionButtonWrapper';
import i18n from '~/shared/utils/i18n';

interface MassActionsProps {
    selectedRows: number[];
    filters: object;
    sort: object;
    loading: boolean;
    actions: ActionProps[];
}

const MassActions: React.FC<MassActionsProps> = ({
    selectedRows,
    filters,
    sort,
    loading,
    actions
}) => {
    const hasSelected = selectedRows.length > 0;

    return (
        <div style={{ marginBottom: 16 }}>
            <ActionButtonWrapper>
                {actions.map(({ label, onClick, ...action }) => (
                    <Button
                        key={label}
                        type={action.type || 'primary'}
                        onClick={(): void =>
                            onClick(selectedRows, filters, sort)
                        }
                        disabled={
                            (action.multiple && !hasSelected) || action.disabled
                        }
                        loading={action.loading || loading}
                        {...action}
                    >
                        {label}
                    </Button>
                ))}
                <span style={{ marginLeft: 8 }}>
                    {hasSelected
                        ? i18n.t('basic.selectedXCount', {
                              count: selectedRows.length
                          })
                        : ''}
                </span>
            </ActionButtonWrapper>
        </div>
    );
};
export default MassActions;
