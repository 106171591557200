import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { usePromiseTracker } from 'react-promise-tracker';
import ServiceEnums from '~/types/shared/service-enums';
import { ApiResponse } from '~/types/services/common';
import ToastrService from '~/services/toastr-service';
import setFieldBackendError from '~/shared/helpers/backend-responses';
import ExpansionEditForm from '~/pages/Expansion/Edit/components/ExpansionEditForm';
import ExpansionService from '~/services/expansion-service';
import { Expansion } from '~/types/services/expansion-service';

const expansionService = new ExpansionService();

const ExpansionEditPage: React.FC = () => {
    const { t } = useTranslation();
    const { id: expansionId } = useParams();
    const [expansion, setExpansion] = useState<Expansion>();
    const history = useHistory();
    const { promiseInProgress } = usePromiseTracker({
        area: ServiceEnums.Expansion
    });

    const isEditing = expansionId !== 'new';
    const isViewing = history.location.pathname.indexOf('view') !== -1;

    useEffect(() => {
        if (!isEditing) return;
        const promise = (): Promise<ApiResponse<Expansion>> =>
            expansionService.fetchExpansion(expansionId);

        promise()
            .then(res => setExpansion(res.data))
            .catch(() => {
                ToastrService.error(t('pages.content.edit.expansionNotFound'));
                history.push('/content/bovitesek');
            });
    }, [history, expansionId, isEditing]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = (values, helpers): void => {
        if (!isViewing) {
            const promise = (): Promise<unknown> =>
                isEditing
                    ? expansionService.updateExpansion(expansionId, values)
                    : expansionService.createExpansion(values);

            promise()
                .then(() => {
                    ToastrService.success(
                        t('pages.content.edit.expansionSuccessSave')
                    );
                })
                .catch(e => {
                    if (e.response.status === 422) {
                        setFieldBackendError(e.response.data, helpers);
                        if (e.response.data.errors.pylons) {
                            ToastrService.error(e.response.data.errors.pylons);
                        }
                    } else {
                        ToastrService.error(
                            t('pages.content.edit.expansionFailedSave')
                        );
                    }
                });
        }
    };

    const handleGenerateAnnex = (): void => {
        if (isEditing) {
            expansionService.downloadAnnex(expansionId).catch(error => {
                ToastrService.error(error.errors);
            });
        }
    };

    const handleSendBillingEmail = (): null => {
        expansionService
            .sendBillingEmail(expansionId)
            .then(() => {
                ToastrService.success(t('basic.billingEmailSent'));
            })
            .catch(() => {
                ToastrService.error(t('basic.billingEmailFailed'));
            });
        return null;
    };

    const action = isViewing
        ? t('pages.content.edit.expansionViewTitle')
        : t(
              isEditing
                  ? 'pages.content.edit.expansionEditTitle'
                  : 'pages.content.edit.expansionCreateTitle'
          );

    return (
        <>
            <h1>{action}</h1>
            <ExpansionEditForm
                onSubmit={handleSubmit}
                onGenerateAnnex={handleGenerateAnnex}
                onSendBillingEmail={handleSendBillingEmail}
                loading={promiseInProgress}
                expansion={expansion}
                isEditing={isEditing}
                isViewing={isViewing}
            />
        </>
    );
};

export default ExpansionEditPage;
