import React, { useEffect, useState } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { Input, Typography, Space, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import ModuleTable from './components/ModuleTable';
import {
    claimColumns,
    deletionColumns,
    expansionColumns,
    permitColumns,
    planningColumns,
    transcriptionColumns
} from './components/columns';
import ContentService from '~/services/content-service';
import { ModulesPaginate } from '~/types/state/ModulesPaginate';
import ModulePaginateEnums from '~/types/shared/module-paginate-enums';
import { Modules } from '~/types/services/content-service';
import ServiceEnums from '~/types/shared/service-enums';
import TableSelector from '~/pages/InProgressPage/components/TableSelector';
import TableColumn from '~/pages/InProgressPage/components/TableColumn';
import ModuleEnums from '~/types/shared/module-enums';

const contentService = new ContentService();

const InProgressPage: React.FC = () => {
    const { t } = useTranslation();
    const initialPaginate = {
        claim_page: 1,
        permit_page: 1,
        planning_page: 1,
        transcription_page: 1,
        deletion_page: 1,
        expansion_page: 1
    };
    const [tablesData, setTablesData] = useState<Modules>({
        claims: { data: [], total: 0 },
        permits: { data: [], total: 0 },
        plannings: { data: [], total: 0 },
        transcriptions: { data: [], total: 0 },
        deletions: { data: [], total: 0 },
        expansions: { data: [], total: 0 }
    });
    const [modulesPaginate, setModulesPaginate] = useState<ModulesPaginate>(
        initialPaginate
    );
    const [search, setSearch] = useState();
    const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([]);

    const { promiseInProgress } = usePromiseTracker({
        area: ServiceEnums.Content
    });
    const localCheckedList = localStorage.getItem('in-progress-checked-list');
    const defaultCheckedList = JSON.parse(localCheckedList) ?? [
        ModuleEnums.Claim
    ];
    const fetchTablesData = ({ ...params }): void => {
        contentService
            .fetchInProgress(
                params.modulesPaginate,
                params.search,
                params.types
            )
            .then(res => {
                setTablesData(res.data);
            });
    };

    useEffect(() => {
        localStorage.setItem(
            'in-progress-checked-list',
            JSON.stringify(checkedList)
        );
    }, [checkedList]);

    useEffect(() => {
        fetchTablesData({ modulesPaginate, search, types: checkedList });
    }, [modulesPaginate, search, checkedList]);

    const changeModulesPaginate = ({ name, page }): void => {
        setModulesPaginate(prevState => {
            return { ...prevState, [name]: page };
        });
    };

    const searching = (value): void => {
        setSearch(value);
        setModulesPaginate(initialPaginate);
    };

    return (
        <>
            <Typography.Title level={1}>
                {t('menu.inProgress')}
            </Typography.Title>
            <Space direction="vertical" style={{ display: 'flex' }}>
                <Input.Search
                    placeholder={t('basic.search')}
                    onBlur={(value): void => searching(value.target.value)}
                    enterButton
                    onSearch={(value): void => searching(value)}
                />
                <TableSelector
                    onChange={setCheckedList}
                    defaultCheckedList={defaultCheckedList}
                />
            </Space>
            <Row gutter={16}>
                <TableColumn visible={checkedList.includes(ModuleEnums.Claim)}>
                    <ModuleTable
                        modulePaginateName={ModulePaginateEnums.Claim}
                        title={t('pages.content.list.claimList')}
                        modulePaginate={changeModulesPaginate}
                        modulePage={modulesPaginate.claim_page}
                        columns={claimColumns(t)}
                        dataSource={tablesData.claims.data}
                        loading={promiseInProgress}
                        total={tablesData.claims.total}
                    />
                </TableColumn>
                <TableColumn visible={checkedList.includes(ModuleEnums.Permit)}>
                    <ModuleTable
                        modulePaginateName={ModulePaginateEnums.Permit}
                        title={t('pages.content.list.principlePermitList')}
                        modulePaginate={changeModulesPaginate}
                        modulePage={modulesPaginate.permit_page}
                        columns={permitColumns(t)}
                        dataSource={tablesData.permits.data}
                        loading={promiseInProgress}
                        total={tablesData.permits.total}
                    />
                </TableColumn>
                <TableColumn
                    visible={checkedList.includes(ModuleEnums.Planning)}
                >
                    <ModuleTable
                        modulePaginateName={ModulePaginateEnums.Planning}
                        title={t('pages.content.list.planningList')}
                        modulePaginate={changeModulesPaginate}
                        modulePage={modulesPaginate.planning_page}
                        columns={planningColumns(t)}
                        dataSource={tablesData.plannings.data}
                        loading={promiseInProgress}
                        total={tablesData.plannings.total}
                    />
                </TableColumn>
                <TableColumn
                    visible={checkedList.includes(ModuleEnums.Transcription)}
                >
                    <ModuleTable
                        modulePaginateName={ModulePaginateEnums.Transcription}
                        title={t('pages.content.list.transcriptionList')}
                        modulePaginate={changeModulesPaginate}
                        modulePage={modulesPaginate.transcription_page}
                        columns={transcriptionColumns(t)}
                        dataSource={tablesData.transcriptions.data}
                        loading={promiseInProgress}
                        total={tablesData.transcriptions.total}
                    />
                </TableColumn>
                <TableColumn
                    visible={checkedList.includes(ModuleEnums.Deletion)}
                >
                    <ModuleTable
                        modulePaginateName={ModulePaginateEnums.Deletion}
                        title={t('pages.content.list.deletionList')}
                        modulePaginate={changeModulesPaginate}
                        modulePage={modulesPaginate.deletion_page}
                        columns={deletionColumns(t)}
                        dataSource={tablesData.deletions.data}
                        loading={promiseInProgress}
                        total={tablesData.deletions.total}
                    />
                </TableColumn>
                <TableColumn
                    visible={checkedList.includes(ModuleEnums.Expansion)}
                >
                    <ModuleTable
                        modulePaginateName={ModulePaginateEnums.Expansion}
                        title={t('pages.content.list.expansionList')}
                        modulePaginate={changeModulesPaginate}
                        modulePage={modulesPaginate.expansion_page}
                        columns={expansionColumns(t)}
                        dataSource={tablesData.expansions.data}
                        loading={promiseInProgress}
                        total={tablesData.expansions.total}
                    />
                </TableColumn>
            </Row>
        </>
    );
};

export default InProgressPage;
