import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ToastrService from '~/services/toastr-service';
import { ApiResponse } from '~/types/services/common';
import InvoiceEditForm from '~/pages/Invoice/Edit/components/InvoiceEditForm';
import InvoiceService from '~/services/invoice-service';
import { Invoice } from '~/types/services/invoice-service';

const invoiceService = new InvoiceService();

const InvoiceEditPage: React.FC = () => {
    const { t } = useTranslation();
    const { id: invoiceId } = useParams();
    const [invoice, setInvoice] = useState<Invoice>();
    const history = useHistory();

    const isEditing = invoiceId !== 'new';
    const isViewing = history.location.pathname.indexOf('view') !== -1;

    useEffect(() => {
        if (!isEditing) return;
        const promise = (): Promise<ApiResponse<Invoice>> =>
            invoiceService.fetchInvoice(invoiceId);

        promise()
            .then(res => setInvoice(res.data))
            .catch(() => {
                ToastrService.error(t('pages.content.edit.invoiceNotFound'));
                history.push('/content/szamlak');
            });
    }, [history, invoiceId, isEditing]); // eslint-disable-line react-hooks/exhaustive-deps

    const action = t('pages.content.edit.invoiceViewTitle');

    return (
        <>
            <h1>{action}</h1>
            <InvoiceEditForm isViewing={isViewing} invoice={invoice} />
        </>
    );
};

export default InvoiceEditPage;
