import BaseService from '~/services/base-service';
import ServiceEnums from '~/types/shared/service-enums';
import { ApiResponse, PaginatedResponse } from '~/types/services/common';
import { Transcription } from '~/types/services/transcription-service';

export default class TranscriptionService extends BaseService {
    constructor() {
        super(ServiceEnums.Transcription);
    }

    fetchTranscriptions(
        filter: object,
        sort: object,
        page: number
    ): Promise<PaginatedResponse<Transcription>> {
        return this.getRequest(``, {
            filter,
            sort,
            page
        });
    }

    fetchTranscription(
        id: string | number
    ): Promise<ApiResponse<Transcription>> {
        return this.getRequest(`${id}`);
    }

    createTranscription<T>(data: Transcription): Promise<T> {
        return this.postRequest(``, data);
    }

    updateTranscription<T>(
        id: string | number,
        data: Transcription
    ): Promise<T> {
        return this.putRequest(`${id}`, data);
    }

    exportTranscriptions(filter: object, sort: object): void {
        this.download('export', {
            filter,
            sort
        });
    }

    deleteTranscriptions<T>(transcriptionIds: string[] | number[]): Promise<T> {
        return this.deleteRequest(``, {
            transcriptions: transcriptionIds
        });
    }

    downloadAnnex(id: string | number, sapClientNumber: number): Promise<void> {
        return this.download(`annex/${id}`, { sapClientNumber }).catch(
            error => {
                return error.response.data.text().then(text => {
                    throw JSON.parse(text);
                });
            }
        );
    }

    sendBillingEmail<T>(id: string): Promise<T> {
        return this.getRequest(`billing-email/${id}`);
    }
}
