/* eslint-disable @typescript-eslint/camelcase */
import Yup from '~/shared/utils/yup';

export default Yup.object().shape({
    label: Yup.string()
        .max(50)
        .required('pages.masterData.validations.requiredLabel'),
    key: Yup.string()
        .max(50)
        .required('pages.masterData.validations.requiredKey'),
    value: Yup.string()
        .max(50)
        .required('pages.masterData.validations.requiredValue'),
    description: Yup.string()
        .max(255)
        .nullable(),
    status: Yup.boolean().typeError('')
});
