import React, { ReactElement } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { Button, Switch } from 'antd';
import { User } from '~/types/services/user-service';
import Spinner from '~/components/Spinner';
import EditFormSchema from './EditForm.validation';
import StatusSelector from '~/pages/User/Edit/components/StatusSelector';
import EditFormWrapper from '~/components/EditForm';
import { Group } from '~/types/services/permission-service';
import GroupTreeSelect from '~/pages/User/Edit/components/GroupTreeSelect';
import FormBuilder from '~/containers/FormBuilder';
import ServiceEnums from '~/types/shared/service-enums';

interface EditFormProps {
    onSubmit: (values, helpers: FormikHelpers<[]>) => void;
    requestPasswordReset?: (email: string) => void;
    user?: User;
    groups?: Group[];
    loading: boolean;
}

const EditForm: React.FC<EditFormProps> = ({
    onSubmit,
    requestPasswordReset,
    user,
    groups = [],
    loading
}) => {
    const { t } = useTranslation();

    const buttonItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 }
    };

    const initialGroups = groups.map(group => ({
        value: group.id,
        label: group.name
    }));
    const initialGroupIds = groups.map(group => group.id);
    let initialValue = {};

    if (!user) {
        return <Spinner />;
    }
    initialValue = {
        /* eslint-disable-next-line @typescript-eslint/camelcase */
        first_name: user.first_name,
        /* eslint-disable-next-line @typescript-eslint/camelcase */
        last_name: user.last_name,
        username: user.username,
        email: user.email,
        phone: user.phone,
        status: user.status,
        adfsUser: user.adfsUser,
        groups: initialGroupIds,
        builder: get(user, 'builder', {})
    };

    return (
        <Formik
            validationSchema={EditFormSchema}
            validateOnBlur
            onSubmit={onSubmit}
            initialValues={initialValue}
        >
            {({
                values,
                setFieldValue,
                handleChange,
                handleBlur
            }): ReactElement => (
                <EditFormWrapper>
                    <Form.Item
                        name="name"
                        label={t('pages.users.fields.firstName')}
                        noStyle
                    >
                        <Form.Item
                            name="first_name"
                            label={t('pages.users.fields.firstName')}
                            required
                        >
                            <Input
                                name="first_name"
                                placeholder={t('pages.users.fields.firstName')}
                            />
                        </Form.Item>
                        <Form.Item
                            name="last_name"
                            label={t('pages.users.fields.lastName')}
                            required
                        >
                            <Input
                                name="last_name"
                                placeholder={t('pages.users.fields.lastName')}
                            />
                        </Form.Item>
                        <Form.Item
                            name="username"
                            label={t('basic.fields.username')}
                            required
                        >
                            <Input
                                name="username"
                                placeholder={t('basic.fields.username')}
                            />
                        </Form.Item>
                    </Form.Item>
                    <Form.Item
                        name="email"
                        label={t('basic.fields.email')}
                        required
                    >
                        <Input
                            type="email"
                            name="email"
                            placeholder={t('basic.fields.email')}
                        />
                    </Form.Item>
                    <Form.Item name="lost-password">
                        <Button
                            danger
                            onClick={(): void =>
                                requestPasswordReset(values.email)
                            }
                            loading={loading}
                        >
                            {t(
                                'pages.authentication.lostPassword.forgotPassword'
                            )}
                        </Button>
                    </Form.Item>
                    <FormBuilder
                        formKey="user_data"
                        area={ServiceEnums.Auth}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <Form.Item
                        name="phone"
                        label={t('pages.users.fields.phone')}
                    >
                        <Input
                            name="phone"
                            placeholder={t('pages.users.fields.phone')}
                        />
                    </Form.Item>
                    <GroupTreeSelect
                        key={initialGroupIds.length}
                        name="groups"
                        label={t('pages.users.fields.group')}
                        initialValues={initialGroups}
                        setFieldValue={setFieldValue}
                        data={values.groups}
                    />
                    <StatusSelector user={user} />
                    <Form.Item
                        label={t('pages.users.fields.adfsUser')}
                        name="adfsUser"
                    >
                        <Switch disabled defaultChecked={values.adfsUser} />
                    </Form.Item>
                    <Form.Item name="submit" {...buttonItemLayout}>
                        <SubmitButton type="primary" loading={loading}>
                            {t('basic.saveButton')}
                        </SubmitButton>
                    </Form.Item>
                </EditFormWrapper>
            )}
        </Formik>
    );
};

export default EditForm;
