import { ColumnsType } from 'antd/es/table';
import get from 'lodash/get';
import findKey from 'lodash/findKey';
import React from 'react';
import { Claim } from '~/types/services/claim-service';
import ClaimBuilderFieldNameEnums from '~/types/shared/claim-builder-field-name-enums';
import { PrinciplePermit } from '~/types/services/principle-permit';
import PrinciplePermitBuilderFieldNameEnums from '~/types/shared/principle-permit-builder-field-name-enums';
import { Planning } from '~/types/services/planning-service';
import PlanningBuilderFieldNameEnums from '~/types/shared/planning-builder-field-name-enums';
import { Transcription } from '~/types/services/transcription-service';
import TranscriptionBuilderFieldNameEnums from '~/types/shared/transcription-builder-field-name-enums';
import { Deletion } from '~/types/services/deletion';
import DeletionBuilderFieldNameEnums from '~/types/shared/deletion-builder-field-name-enums';
import ExpansionBuilderFieldNameEnums from '~/types/shared/expansion-builder-field-name-enums';
import { Expansion } from '~/types/services/expansion-service';
import ShowModuleLink from '~/pages/InProgressPage/components/ShowModuleLink';

export const claimColumns = (t): ColumnsType<never> => {
    return [
        {
            title: t('pages.content.fields.business_case_number'),
            key: 'business_case_number',
            render: (data: Claim): string =>
                get(data, 'business_case.case_number')
        },
        {
            title: t('pages.content.fields.establishment_name'),
            key: 'claim_establishment',
            render: (data: Claim): string => {
                const codeFieldName = findKey(
                    data.builderFieldNames,
                    item =>
                        item.indexOf(
                            ClaimBuilderFieldNameEnums.Establishment
                        ) !== -1
                );
                return get(data.builder, codeFieldName, '-');
            }
        },
        {
            title: t('pages.content.fields.registrator_name'),
            key: 'claim_registrator_name',
            render: (data: Claim): string => {
                const codeFieldName = findKey(
                    data.builderFieldNames,
                    item =>
                        item.indexOf(
                            ClaimBuilderFieldNameEnums.ClaimRegistratorName
                        ) !== -1
                );
                return get(data.builder, codeFieldName, '-');
            }
        },
        {
            title: t('basic.action'),
            key: 'action',
            render: (text, record: Claim): React.ReactNode =>
                ShowModuleLink({
                    url: 'igenybejelentesek',
                    recordId: record.id
                })
        }
    ];
};

export const permitColumns = (t): ColumnsType<never> => {
    return [
        {
            title: t('pages.content.fields.business_case_number'),
            key: 'business_case_number',
            render: (data: PrinciplePermit): string =>
                get(data, 'business_case.case_number')
        },
        {
            title: t('pages.content.fields.establishment_name'),
            key: 'permit_establishment',
            render: (data: PrinciplePermit): string => {
                const codeFieldName = findKey(
                    data.builderFieldNames,
                    item =>
                        item.indexOf(
                            PrinciplePermitBuilderFieldNameEnums.Establishment
                        ) !== -1
                );
                return get(data.builder, codeFieldName, '-');
            }
        },
        {
            title: t('pages.content.fields.registrator_name'),
            key: 'permit_registrator_name',
            render: (data: PrinciplePermit): string => {
                const codeFieldName = findKey(
                    data.builderFieldNames,
                    item =>
                        item.indexOf(
                            PrinciplePermitBuilderFieldNameEnums.RegistratorName
                        ) !== -1
                );
                return get(data.builder, codeFieldName, '-');
            }
        },
        {
            title: t('basic.action'),
            key: 'action',
            render: (text, record: PrinciplePermit): React.ReactNode =>
                ShowModuleLink({
                    url: 'elvi-engedelyek',
                    recordId: record.id
                })
        }
    ];
};

export const planningColumns = (t): ColumnsType<never> => {
    return [
        {
            title: t('pages.content.fields.business_case_number'),
            key: 'business_case_number',
            render: (data: Planning): string =>
                get(data, 'business_case.case_number')
        },
        {
            title: t('pages.content.fields.establishment_name'),
            key: 'planning_establishment',
            render: (data: Planning): string => {
                const codeFieldName = findKey(
                    data.builderFieldNames,
                    item =>
                        item.indexOf(
                            PlanningBuilderFieldNameEnums.Establishment
                        ) !== -1
                );
                return get(data.builder, codeFieldName, '-');
            }
        },
        {
            title: t('pages.content.fields.customer_name'),
            key: 'planning_registrator_name',
            render: (data: Planning): string => {
                const codeFieldName = findKey(
                    data.builderFieldNames,
                    item =>
                        item.indexOf(
                            PlanningBuilderFieldNameEnums.PlanningRegistratorName
                        ) !== -1
                );
                return get(data.builder, codeFieldName, '-');
            }
        },
        {
            title: t('basic.action'),
            key: 'action',
            render: (text, record: Planning): React.ReactNode =>
                ShowModuleLink({
                    url: 'tervezes',
                    recordId: record.id
                })
        }
    ];
};

export const transcriptionColumns = (t): ColumnsType<never> => {
    return [
        {
            title: t('pages.content.fields.business_case_number'),
            key: 'business_case_number',
            render: (data: Transcription): string =>
                get(data, 'business_case.case_number')
        },
        {
            title: t('pages.content.fields.establishment_name'),
            key: 'transcription_establishment',
            render: (data: Transcription): string => {
                const codeFieldName = findKey(
                    data.builderFieldNames,
                    item =>
                        item.indexOf(
                            TranscriptionBuilderFieldNameEnums.Establishment
                        ) !== -1
                );
                return get(data.builder, codeFieldName, '-');
            }
        },
        {
            title: t('pages.content.fields.recipient_name'),
            key: 'transcription_registrator_name',
            render: (data: Transcription): string => {
                const codeFieldName = findKey(
                    data.builderFieldNames,
                    item =>
                        item.indexOf(
                            TranscriptionBuilderFieldNameEnums.RecipientName
                        ) !== -1
                );
                return get(data.builder, codeFieldName, '-');
            }
        },
        {
            title: t('basic.action'),
            key: 'action',
            render: (text, record: Transcription): React.ReactNode =>
                ShowModuleLink({
                    url: 'atirasok',
                    recordId: record.id
                })
        }
    ];
};

export const deletionColumns = (t): ColumnsType<never> => {
    return [
        {
            title: t('pages.content.fields.business_case_number'),
            key: 'business_case_number',
            render: (data: Deletion): string =>
                get(data, 'business_case.case_number')
        },
        {
            title: t('pages.content.fields.establishment_name'),
            key: 'deletion_establishment',
            render: (data: Deletion): string => {
                const codeFieldName = findKey(
                    data.builderFieldNames,
                    item =>
                        item.indexOf(
                            DeletionBuilderFieldNameEnums.Establishment
                        ) !== -1
                );
                return get(data.builder, codeFieldName, '-');
            }
        },
        {
            title: t('pages.content.fields.customer_name'),
            key: 'deletion_registrator_name',
            render: (data: Deletion): string => {
                const codeFieldName = findKey(
                    data.builderFieldNames,
                    item =>
                        item.indexOf(
                            DeletionBuilderFieldNameEnums.CustomerName
                        ) !== -1
                );
                return get(data.builder, codeFieldName, '-');
            }
        },
        {
            title: t('basic.action'),
            key: 'action',
            render: (text, record: Deletion): React.ReactNode =>
                ShowModuleLink({
                    url: 'torlesek',
                    recordId: record.id
                })
        }
    ];
};

export const expansionColumns = (t): ColumnsType<never> => {
    return [
        {
            title: t('pages.content.fields.business_case_number'),
            key: 'business_case_number',
            render: (data: Expansion): string =>
                get(data, 'business_case.case_number')
        },
        {
            title: t('pages.content.fields.establishment_name'),
            key: 'expansion_establishment',
            render: (data: Expansion): string => {
                const codeFieldName = findKey(
                    data.builderFieldNames,
                    item =>
                        item.indexOf(
                            ExpansionBuilderFieldNameEnums.Establishment
                        ) !== -1
                );
                return get(data.builder, codeFieldName, '-');
            }
        },
        {
            title: t('pages.content.fields.customer_name'),
            key: 'expansion_registrator_name',
            render: (data: Expansion): string => {
                const codeFieldName = findKey(
                    data.builderFieldNames,
                    item =>
                        item.indexOf(
                            ExpansionBuilderFieldNameEnums.CustomerName
                        ) !== -1
                );
                return get(data.builder, codeFieldName, '-');
            }
        },
        {
            title: t('basic.action'),
            key: 'action',
            render: (text, record: Expansion): React.ReactNode =>
                ShowModuleLink({
                    url: 'bovitesek',
                    recordId: record.id
                })
        }
    ];
};
