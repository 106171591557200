import React from 'react';
import moment from 'moment';
import { AlignType } from 'rc-table/lib/interface';
import get from 'lodash/get';
import { ColumnsType } from 'antd/es/table';
import filterDateRange from '~/components/Table/components/filterDateRange';
import { SapClient } from '~/types/services/sap-client-service';
import ActionButtons from '~/pages/SapClientImport/List/ActionButtons';

const columns = (t): ColumnsType<never> => {
    const url = '/content/sap-vevok';

    return [
        {
            title: t('pages.content.fields.sap_client_import_name'),
            key: 'user_id',
            sorter: true,
            render: (data: SapClient): string =>
                `${get(data, 'user.first_name')} ${get(data, 'user.last_name')}`
        },
        {
            title: t('pages.content.fields.sap_client_import_created_at'),
            dataIndex: 'created_at',
            sorter: true,
            render: (text): string =>
                moment(text)
                    .format('YYYY-MM-DD')
                    .toString(),
            ...filterDateRange
        },

        {
            title: t('basic.action'),
            key: 'action',
            align: 'right' as AlignType,
            render: (text, record): React.ReactNode =>
                ActionButtons({
                    record,
                    url
                })
        }
    ];
};

export default columns;
