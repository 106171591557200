import React, { useContext, useEffect, useState } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { Form, Select as FormikSelect, SelectProps } from 'formik-antd';
import { Spin, Select } from 'antd';
import { LabeledValue } from 'antd/es/select';
import { FormikContext } from 'formik';
import MasterDataService from '~/services/masterdata-service';
import {
    MasterData,
    MasterDataSelectType
} from '~/types/services/masterdata-service';

const masterDataService = new MasterDataService();

interface MasterDataSelectProps {
    required?: boolean;
    value?: string;
    mode?: SelectProps['mode'];
    module: string;
    type: string | string[];
    isFormik: boolean;
    placeholder?: string;
    onSelect?: (value: string | number) => void;
    onChange?: (data: object) => void;
    style?: object;
    label?: string;
    name?: string;
    disabled?: boolean;
    allowClear?: boolean;
    defaultValue?: LabeledValue;
    initialKey?: string;
    isCreate?: boolean;
}

const MasterDataSelect: React.FC<MasterDataSelectProps> = ({
    required,
    mode,
    module,
    type,
    name,
    label,
    placeholder,
    isFormik,
    onSelect,
    onChange,
    style,
    disabled,
    allowClear,
    defaultValue,
    initialKey,
    isCreate
}) => {
    let types = [];
    if (!Array.isArray(type)) {
        types.push(type);
    } else {
        types = type;
    }
    const { promiseInProgress } = usePromiseTracker({
        area: module + types[0]
    });
    const formikContext = useContext(FormikContext);

    const [masterData, setMasterData] = useState<MasterDataSelectType[]>();

    const handleOpen = (): void => {
        if (!masterData) {
            const allRequest = types.map(t =>
                masterDataService.fetchMasterDatasByType(
                    module,
                    t,
                    module + types[0]
                )
            );
            Promise.all(allRequest).then(res => {
                const data: MasterDataSelectType[] = [];
                res.forEach(d =>
                    d.data.forEach((mData: MasterData) => {
                        data.push({
                            value: mData.id,
                            label: mData.label
                        });

                        if (
                            initialKey &&
                            isCreate &&
                            formikContext &&
                            mData.key === initialKey
                        ) {
                            formikContext.setFieldValue(name, {
                                value: mData.id,
                                label: mData.label
                            });
                        }
                    })
                );
                setMasterData(data);
            });
        }
    };

    useEffect(() => {
        if (initialKey && isCreate) {
            handleOpen();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const selectProps = {
        labelInValue: true,
        placeholder,
        options: masterData,
        loading: promiseInProgress,
        onClick: !disabled && handleOpen,
        notFoundContent: promiseInProgress ? <Spin size="small" /> : null,
        disabled: !!disabled,
        allowClear: !!allowClear
    };

    return (
        <>
            {isFormik && (
                <Form.Item name={name} label={label} required={required}>
                    <FormikSelect
                        data-testid={name}
                        name={name}
                        mode={mode}
                        {...selectProps}
                    />
                </Form.Item>
            )}
            {!isFormik && (
                <Select
                    labelInValue
                    data-testid="filter_master_data"
                    {...selectProps}
                    onSelect={(value: MasterDataSelectType): void => {
                        if (onSelect) {
                            onSelect(value.value);
                        }
                    }}
                    onChange={(data): void => {
                        if (onChange) {
                            onChange(data);
                        }
                    }}
                    style={style}
                    defaultValue={defaultValue}
                />
            )}
        </>
    );
};

export default MasterDataSelect;
