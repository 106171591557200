import { createElement, ReactElement } from 'react';
import Home from '~/pages/Home';
import Authentication from '~/pages/Authentication';
import FeaturePage from '~/pages/FeaturePage';
import { RouteInterface } from '~/types/shared/route-interface';
import RoleListPage from '~/pages/Permission/Roles/List';
import RoleEditPage from '~/pages/Permission/Roles/Edit';
import GroupListPage from '~/pages/Permission/Groups/List';
import GroupEditPage from '~/pages/Permission/Groups/Edit';
import SettingsPage from '~/pages/SettingsPage';
import ProfilePage from '~/pages/User/Profile';
import UserListPage from '~/pages/User/List';
import UserEditPage from '~/pages/User/Edit';
import MasterDataPage from '~/pages/MasterData/List';
import NoPermissionPage from '~/pages/NoPermission';
import ClaimEditPage from '~/pages/Claim/Edit';
import ClaimListPage from '~/pages/Claim/List';
import TranscriptionListPage from '~/pages/Transcription/List';
import TranscriptionEditPage from '~/pages/Transcription/Edit';
import PrinciplePermitListPage from '~/pages/PrinciplePermit/List';
import PrinciplePermitEditPage from '~/pages/PrinciplePermit/Edit';
import PlanningListPage from '~/pages/Planning/List';
import PlanningEditPage from '~/pages/Planning/Edit';
import LeaseListPage from '~/pages/Lease/List';
import LeaseEditPage from '~/pages/Lease/Edit';
import DeletionEditPage from '~/pages/Deletion/Edit';
import DeletionListPage from '~/pages/Deletion/List';
import ExpansionListPage from '~/pages/Expansion/List';
import ExpansionEditPage from '~/pages/Expansion/Edit';
import OtherDocumentListPage from '~/pages/OtherDocument/List';
import OtherDocumentEditPage from '~/pages/OtherDocument/Edit';
import InvoiceListPage from '~/pages/Invoice/List';
import InvoiceEditPage from '~/pages/Invoice/Edit';
import SapClientListPage from './pages/SapClient/List';
import SapClientEditPage from './pages/SapClient/Edit';
import SapClientImportListPage from '~/pages/SapClientImport/List';
import SapClientImportEditPage from '~/pages/SapClientImport/Edit';
import SapInvoiceImportListPage from '~/pages/SapInvoiceImport/List';
import SapInvoiceImportEditPage from '~/pages/SapInvoiceImport/Edit';
import AuthService from '~/services/auth-service';
import InProgressPage from '~/pages/InProgressPage';

const routes: RouteInterface[] = [
    {
        exact: true,
        path: '/',
        component: Home,
        label: 'menu.dashboard',
        area: 'left',
        isProtected: true
    },
    {
        exact: true,
        path: '/content',
        component: null,
        label: 'menu.content',
        area: 'left',
        subRoutes: [
            {
                exact: true,
                isProtected: true,
                isIndexPage: true,
                path: '/content/folyamatban',
                component: InProgressPage,
                label: 'menu.inProgress',
                area: 'left'
            },
            {
                exact: true,
                isProtected: true,
                isIndexPage: true,
                path: '/content/igenybejelentesek',
                component: ClaimListPage,
                label: 'menu.claims',
                area: 'left',
                routePermissionName: ['igenybejelentes.manage'],
                subRoutes: [
                    {
                        exact: true,
                        isProtected: true,
                        path: '/content/igenybejelentesek/:id',
                        component: ClaimEditPage,
                        label: 'menu.editClaim',
                        routePermissionName: ['igenybejelentes.manage']
                    },
                    {
                        exact: true,
                        isProtected: true,
                        path: '/content/igenybejelentesek/view/:id',
                        component: ClaimEditPage,
                        label: 'menu.viewClaim',
                        routePermissionName: ['igenybejelentes.manage']
                    }
                ]
            },
            {
                exact: true,
                isProtected: true,
                isIndexPage: true,
                path: '/content/elvi-engedelyek',
                component: PrinciplePermitListPage,
                label: 'menu.principlePermit',
                area: 'left',
                routePermissionName: ['elviengedely.manage'],
                subRoutes: [
                    {
                        exact: true,
                        isProtected: true,
                        path: '/content/elvi-engedelyek/:id',
                        component: PrinciplePermitEditPage,
                        label: 'menu.editPrinciplePermit',
                        routePermissionName: ['elviengedely.manage']
                    },
                    {
                        exact: true,
                        isProtected: true,
                        path: '/content/elvi-engedelyek/view/:id',
                        component: PrinciplePermitEditPage,
                        label: 'menu.viewPrinciplePermit',
                        routePermissionName: ['elviengedely.manage']
                    }
                ]
            },
            {
                exact: true,
                isProtected: true,
                isIndexPage: true,
                path: '/content/atirasok',
                component: TranscriptionListPage,
                label: 'menu.transcriptions',
                area: 'left',
                routePermissionName: ['atiras.manage'],
                subRoutes: [
                    {
                        exact: true,
                        isProtected: true,
                        path: '/content/atirasok/:id',
                        component: TranscriptionEditPage,
                        label: 'menu.editTanscription',
                        routePermissionName: ['atiras.manage']
                    },
                    {
                        exact: true,
                        isProtected: true,
                        path: '/content/atirasok/view/:id',
                        component: TranscriptionEditPage,
                        label: 'menu.viewTanscription',
                        routePermissionName: ['atiras.manage']
                    }
                ]
            },
            {
                exact: true,
                isProtected: true,
                isIndexPage: true,
                path: '/content/tervezes',
                component: PlanningListPage,
                label: 'menu.plannings',
                area: 'left',
                routePermissionName: ['tervezes.kivitelezes.manage'],
                subRoutes: [
                    {
                        exact: true,
                        isProtected: true,
                        path: '/content/tervezes/:id',
                        component: PlanningEditPage,
                        label: 'menu.editPlanning',
                        routePermissionName: ['tervezes.kivitelezes.manage']
                    },
                    {
                        exact: true,
                        isProtected: true,
                        path: '/content/tervezes/view/:id',
                        component: PlanningEditPage,
                        label: 'menu.viewPlanning',
                        routePermissionName: ['tervezes.kivitelezes.manage']
                    }
                ]
            },
            {
                exact: true,
                isProtected: true,
                isIndexPage: true,
                path: '/content/berleti-szerzodesek',
                component: LeaseListPage,
                label: 'menu.leases',
                area: 'left',
                routePermissionName: ['berleti.szerzodes.manage'],
                subRoutes: [
                    {
                        exact: true,
                        isProtected: true,
                        path: '/content/berleti-szerzodesek/:id',
                        component: LeaseEditPage,
                        label: 'menu.editLease',
                        routePermissionName: ['berleti.szerzodes.manage']
                    },
                    {
                        exact: true,
                        isProtected: true,
                        path: '/content/berleti-szerzodesek/view/:id',
                        component: LeaseEditPage,
                        label: 'menu.viewLease',
                        routePermissionName: ['berleti.szerzodes.manage']
                    }
                ]
            },
            {
                exact: true,
                isProtected: true,
                isIndexPage: true,
                path: '/content/torlesek',
                component: DeletionListPage,
                label: 'menu.deletions',
                area: 'left',
                routePermissionName: 'torles.manage',
                subRoutes: [
                    {
                        exact: true,
                        isProtected: true,
                        path: '/content/torlesek/:id',
                        component: DeletionEditPage,
                        label: 'menu.editDeletions',
                        routePermissionName: 'torles.manage'
                    },
                    {
                        exact: true,
                        isProtected: true,
                        path: '/content/torlesek/view/:id',
                        component: DeletionEditPage,
                        label: 'menu.viewDeletion',
                        routePermissionName: 'torles.manage'
                    }
                ]
            },
            {
                exact: true,
                isProtected: true,
                isIndexPage: true,
                path: '/content/bovitesek',
                component: ExpansionListPage,
                label: 'menu.expansions',
                area: 'left',
                routePermissionName: 'bovites.manage',
                subRoutes: [
                    {
                        exact: true,
                        isProtected: true,
                        path: '/content/bovitesek/:id',
                        component: ExpansionEditPage,
                        label: 'menu.editExpansion',
                        routePermissionName: 'bovites.manage'
                    },
                    {
                        exact: true,
                        isProtected: true,
                        path: '/content/bovitesek/view/:id',
                        component: ExpansionEditPage,
                        label: 'menu.viewExpansion',
                        routePermissionName: 'bovites.manage'
                    }
                ]
            },
            {
                exact: true,
                isProtected: true,
                isIndexPage: true,
                path: '/content/vevok',
                component: SapClientListPage,
                label: 'menu.buyers',
                area: 'left',
                routePermissionName: ['vevok.manage'],
                subRoutes: [
                    {
                        exact: true,
                        isProtected: true,
                        path: '/content/vevok/:id',
                        component: SapClientEditPage,
                        label: 'menu.editBuyer',
                        routePermissionName: ['vevok.manage']
                    },
                    {
                        exact: true,
                        isProtected: true,
                        path: '/content/vevok/view/:id',
                        component: SapClientEditPage,
                        label: 'menu.viewBuyer',
                        routePermissionName: ['vevok.manage']
                    }
                ]
            },
            {
                exact: true,
                isProtected: true,
                isIndexPage: true,
                path: '/content/egyeb-dokumentumok',
                component: OtherDocumentListPage,
                label: 'menu.otherDocuments',
                area: 'left',
                routePermissionName: 'egyeb.dokumentum.manage',
                subRoutes: [
                    {
                        exact: true,
                        isProtected: true,
                        path: '/content/egyeb-dokumentumok/:id',
                        component: OtherDocumentEditPage,
                        label: 'menu.editOtherDocument',
                        routePermissionName: 'egyeb.dokumentum.manage'
                    },
                    {
                        exact: true,
                        isProtected: true,
                        path: '/content/egyeb-dokumentumok/view/:id',
                        component: OtherDocumentEditPage,
                        label: 'menu.viewOtherDocument',
                        routePermissionName: 'egyeb.dokumentum.manage'
                    }
                ]
            },
            {
                exact: true,
                isProtected: true,
                isIndexPage: true,
                path: '/content/szamlak',
                component: InvoiceListPage,
                label: 'menu.invoices',
                area: 'left',
                routePermissionName: 'szamlazas.manage',
                subRoutes: [
                    {
                        exact: true,
                        isProtected: true,
                        path: '/content/szamlak/view/:id',
                        component: InvoiceEditPage,
                        label: 'menu.viewInvoice',
                        routePermissionName: 'szamlazas.manage'
                    }
                ]
            },
            {
                exact: true,
                isProtected: true,
                isIndexPage: true,
                path: '/content/sap-vevok',
                component: SapClientImportListPage,
                label: 'menu.sapClientImport',
                area: 'left',
                routePermissionName: 'sap.vevo.import.manage',
                subRoutes: [
                    {
                        exact: true,
                        isProtected: true,
                        path: '/content/sap-vevok/new',
                        component: SapClientImportEditPage,
                        label: 'menu.createSapClientImport',
                        routePermissionName: 'sap.vevo.import.manage'
                    },
                    {
                        exact: true,
                        isProtected: true,
                        path: '/content/sap-vevok/view/:id',
                        component: SapClientImportEditPage,
                        label: 'menu.viewSapClientImport',
                        routePermissionName: 'sap.vevo.import.manage'
                    }
                ]
            },
            {
                exact: true,
                isProtected: true,
                isIndexPage: true,
                path: '/content/sap-szamlak',
                component: SapInvoiceImportListPage,
                label: 'menu.sapInvoiceImport',
                area: 'left',
                routePermissionName: 'sap.szamla.import.manage',
                subRoutes: [
                    {
                        exact: true,
                        isProtected: true,
                        path: '/content/sap-szamlak/new',
                        component: SapInvoiceImportEditPage,
                        label: 'menu.createSapInvoiceImport',
                        routePermissionName: 'sap.szamla.import.manage'
                    },
                    {
                        exact: true,
                        isProtected: true,
                        path: '/content/sap-szamlak/view/:id',
                        component: SapInvoiceImportEditPage,
                        label: 'menu.viewSapInvoiceImport',
                        routePermissionName: 'sap.szamla.import.manage'
                    }
                ]
            }
        ]
    },
    {
        exact: true,
        path: '/admin',
        component: null,
        label: 'menu.administration',
        area: 'left',
        subRoutes: [
            {
                exact: true,
                isProtected: true,
                path: '/admin/master-data',
                component: MasterDataPage,
                label: 'menu.masterDatas',
                routePermissionName: 'masterdata.manage',
                area: 'left'
            },
            {
                exact: true,
                isProtected: true,
                isIndexPage: true,
                path: '/admin/groups',
                component: GroupListPage,
                label: 'menu.groups',
                routePermissionName: 'groups.manage',
                area: 'left',
                subRoutes: [
                    {
                        exact: true,
                        isProtected: true,
                        path: '/admin/groups/:id',
                        component: GroupEditPage,
                        label: 'menu.editGroup',
                        routePermissionName: 'groups.manage'
                    }
                ]
            },
            {
                exact: true,
                isProtected: true,
                isIndexPage: true,
                path: '/admin/roles',
                component: RoleListPage,
                label: 'menu.roles',
                routePermissionName: 'roles.manage',
                area: 'left',
                subRoutes: [
                    {
                        exact: true,
                        isProtected: true,
                        path: '/admin/roles/:id',
                        component: RoleEditPage,
                        label: 'menu.editRole',
                        routePermissionName: 'roles.manage'
                    }
                ]
            },
            {
                exact: true,
                isProtected: true,
                isIndexPage: true,
                path: '/admin/users',
                component: UserListPage,
                label: 'menu.users',
                routePermissionName: 'users.manage',
                area: 'left',
                subRoutes: [
                    {
                        exact: true,
                        isProtected: true,
                        path: '/admin/users/:id',
                        component: UserEditPage,
                        label: 'menu.editUser',
                        routePermissionName: 'users.manage'
                    }
                ]
            },
            {
                exact: true,
                isProtected: true,
                path: '/admin/features',
                component: FeaturePage,
                label: 'menu.features',
                routePermissionName: 'features.manage',
                area: 'left'
            },
            {
                exact: true,
                isProtected: true,
                path: '/admin/settings',
                component: SettingsPage,
                label: 'menu.settings',
                routePermissionName: 'settings.manage',
                area: 'left'
            }
        ]
    },
    {
        exact: true,
        path: '/user',
        component: Home,
        label: 'menu.profile',
        area: 'top',
        subRoutes: [
            {
                exact: true,
                path: '/my-profile',
                isProtected: true,
                component: ProfilePage,
                label: 'menu.myProfile',
                area: 'top'
            },
            {
                exact: true,
                path: '/logout',
                isProtected: true,
                component: ({ history }): null => {
                    // TODO temporary logout
                    const authService = new AuthService();
                    authService
                        .logout()
                        .then(data => {
                            localStorage.removeItem('jwt');
                            if (data.data.url) {
                                window.location.href = data.data.url;
                            } else {
                                history.push('/auth/login');
                            }
                        })
                        .catch(() => {
                            localStorage.removeItem('jwt');
                            history.push('/auth/login');
                        });
                    return null;
                },
                label: 'menu.logout',
                area: 'top'
            }
        ]
    },
    {
        exact: true,
        path: '/no-permission',
        isProtected: true,
        component: NoPermissionPage
    },
    {
        exact: true,
        path: '/auth/:action',
        isPublicOnly: true,
        component: Authentication
    },
    {
        exact: true,
        path: '/change-email/verify',
        component: ProfilePage
    },
    {
        exact: true,
        path: '/health',
        component: (): ReactElement => createElement('div', null, 'OK')
    }
];

export default routes;
