import React from 'react';
import moment from 'moment';
import { AlignType } from 'rc-table/lib/interface';
import get from 'lodash/get';
import findKey from 'lodash/findKey';
import { ColumnsType } from 'antd/es/table';
import { Document } from '~/types/services/content-service';
import filterMasterData from '~/components/Table/components/filterMasterData';
import ServiceEnums from '~/types/shared/service-enums';
import filterDateRange from '~/components/Table/components/filterDateRange';
import ActionButtons from '~/pages/Invoice/List/ActionButtons';
import { Invoice } from '~/types/services/invoice-service';
import InvoiceBuilderFieldNameEnums from '~/types/shared/invoice-builder-field-name-enums';

const columns = (
    documentArchiveConfig,
    userPermissionNames,
    showArchivateConfirm,
    showDeleteConfirm,
    t
): ColumnsType<never> => {
    const url = '/content/szamlak';
    const routePermissionName = 'szamlazas.manage';

    return [
        {
            title: t('pages.content.fields.business_case_number'),
            key: 'business_case_number',
            sorter: true,
            render: (data: Invoice): string =>
                get(data, 'business_case.case_number')
        },
        {
            title: t('pages.content.fields.invoice_company'),
            key: 'invoice_company',
            sorter: true,
            render: (data: Invoice): string => {
                const codeFieldName = findKey(
                    data.builderFieldNames,
                    item =>
                        item.indexOf(
                            InvoiceBuilderFieldNameEnums.InvoiceCompany
                        ) !== -1
                );
                return get(data.builder, codeFieldName, '-');
            }
        },
        {
            title: t('pages.content.fields.sap_client_number'),
            key: 'sap_client_number',
            sorter: true,
            render: (data: Invoice): string =>
                get(data, 'sap_client.sap_client_number')
        },
        {
            title: t('pages.content.fields.invoice_tenant_name'),
            key: 'invoice_tenant_name',
            sorter: true,
            render: (data: Invoice): string => {
                const codeFieldName = findKey(
                    data.builderFieldNames,
                    item =>
                        item.indexOf(
                            InvoiceBuilderFieldNameEnums.InvoiceTenantName
                        ) !== -1
                );
                return get(data.builder, codeFieldName, '-');
            }
        },
        {
            title: t('pages.content.fields.invoice_tenant_address'),
            key: 'invoice_tenant_address',
            sorter: true,
            render: (data: Invoice): string => {
                const codeFieldName = findKey(
                    data.builderFieldNames,
                    item =>
                        item.indexOf(
                            InvoiceBuilderFieldNameEnums.InvoiceTenantAddress
                        ) !== -1
                );
                return get(data.builder, codeFieldName, '-');
            }
        },
        {
            title: t('pages.content.fields.status'),
            key: 'status_id',
            sorter: true,
            render: (data: Document): string => get(data, 'status.label'),
            ...filterMasterData(ServiceEnums.Content, 'document_status')
        },
        {
            title: t('pages.content.fields.created_at'),
            dataIndex: 'created_at',
            sorter: true,
            render: (text): string =>
                moment(text)
                    .format('YYYY-MM-DD')
                    .toString(),
            ...filterDateRange
        },
        {
            title: t('pages.content.fields.updated_at'),
            dataIndex: 'updated_at',
            sorter: true,
            render: (text): string =>
                text
                    ? moment(text)
                          .format('YYYY-MM-DD')
                          .toString()
                    : '-',
            ...filterDateRange
        },
        {
            title: t('basic.action'),
            key: 'action',
            align: 'right' as AlignType,
            render: (text, record): React.ReactNode =>
                ActionButtons({
                    record,
                    documentArchiveConfig,
                    showArchivateConfirm,
                    showDeleteConfirm,
                    userPermissionNames,
                    url,
                    routePermissionName
                })
        }
    ];
};

export default columns;
