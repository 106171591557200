import BaseService from '~/services/base-service';
import ServiceEnums from '~/types/shared/service-enums';
import { ApiResponse, PaginatedResponse } from '~/types/services/common';
import { BusinessCase } from '~/types/services/case-service';

export default class BusinessCaseService extends BaseService {
    constructor() {
        super(ServiceEnums.BusinessCase);
    }

    searchNumber(number): Promise<PaginatedResponse<BusinessCase>> {
        return this.getRequest('search', {
            number
        });
    }

    generateCaseNumber(): Promise<ApiResponse<BusinessCase>> {
        return this.getRequest(`generate`);
    }
}
