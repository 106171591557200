import React from 'react';
import { Table } from 'antd';
import { Pylon } from '~/types/services/pylon-interface';
import columns from './columns';
import PylonColumnsEnums from '~/types/shared/pylon-columns-enums';

interface TableProps {
    pylons: Pylon[];
}

const PylonValidationTable: React.FC<TableProps> = ({ pylons }) => {
    const cols = columns();

    return (
        <Table
            columns={cols}
            dataSource={pylons}
            rowKey={PylonColumnsEnums.TartoAzonosito}
        />
    );
};

export default PylonValidationTable;
