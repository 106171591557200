import BaseService from '~/services/base-service';
import ServiceEnums from '~/types/shared/service-enums';
import { PrinciplePermit } from '~/types/services/principle-permit';
import { ApiResponse, PaginatedResponse } from '~/types/services/common';

export default class PrinciplePermitService extends BaseService {
    constructor() {
        super(ServiceEnums.PrinciplePermit);
    }

    fetchPrinciplePermits(
        filter: object,
        sort: object,
        page: number
    ): Promise<PaginatedResponse<PrinciplePermit>> {
        return this.getRequest(``, {
            filter,
            sort,
            page
        });
    }

    fetchPrinciplePermit(
        id: string | number
    ): Promise<ApiResponse<PrinciplePermit>> {
        return this.getRequest(`${id}`);
    }

    updatePrinciplePermit<T>(
        id: string | number,
        data: PrinciplePermit
    ): Promise<T> {
        return this.putRequest(`${id}`, data);
    }

    createPrinciplePermit<T>(data: PrinciplePermit): Promise<T> {
        return this.postRequest(``, data);
    }

    deletePrinciplePermits<T>(permitIds: string[] | number[]): Promise<T> {
        return this.deleteRequest(``, {
            principlePermits: permitIds
        });
    }

    exportPrinciplePermits(filter: object, sort: object): void {
        this.download('export', {
            filter,
            sort
        });
    }

    downloadDispositionFile(id: string | number): void {
        this.download(`downloadDispositionFile/${id}`);
    }
}
