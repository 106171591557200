import React, { useContext } from 'react';
import { FeatureContext } from '~/shared/context/feature';

interface FeatureGateProps {
    feature: string;
    inverse?: boolean;
}

const FeatureGate: React.FC<FeatureGateProps> = ({
    feature,
    inverse,
    children
}) => {
    const { isFeatureEnabled } = useContext(FeatureContext);

    if (!isFeatureEnabled(feature) && !inverse) {
        return null;
    }

    return <>{children}</>;
};

export default FeatureGate;
