enum PrinciplePermitBuilderFieldNameEnums {
    RegistrationNumber = 'principle_permit_registrator_number',
    RegistratorName = 'principle_permit_registrator_name',
    RegistratorAddress = 'principle_permit_registrator_address',
    CustomerAdministratorName = 'principle_permit_customer_administrator_name',
    CustomerAdministratorPhone = 'principle_permit_customer_administrator_phone',
    CustomerAdministratorEmail = 'principle_permit_customer_administrator_email',
    Establishment = 'principle_permit_of_establishment'
}

export default PrinciplePermitBuilderFieldNameEnums;
