import BaseService from '~/services/base-service';
import ServiceEnums from '~/types/shared/service-enums';
import { ApiResponse, PaginatedResponse } from '~/types/services/common';
import { Claim } from '~/types/services/claim-service';

export default class ClaimService extends BaseService {
    constructor() {
        super(ServiceEnums.Claim);
    }

    fetchClaims(
        filter: object,
        sort: object,
        page: number
    ): Promise<PaginatedResponse<Claim>> {
        return this.getRequest(``, {
            filter,
            sort,
            page
        });
    }

    fetchClaim(id: string | number): Promise<ApiResponse<Claim>> {
        return this.getRequest(`${id}`);
    }

    createClaim<T>(data: Claim): Promise<T> {
        return this.postRequest(``, data);
    }

    updateClaim<T>(id: string | number, data: Claim): Promise<T> {
        return this.putRequest(`${id}`, data);
    }

    exportClaims(filter: object, sort: object): void {
        this.download('export', {
            filter,
            sort
        });
    }

    deleteClaims<T>(clamIds: string[] | number[]): Promise<T> {
        return this.deleteRequest(``, {
            claims: clamIds
        });
    }

    fetchClaimByCaseNumber(caseNumber: number): Promise<ApiResponse<Claim>> {
        return this.getRequest(`case-number/${caseNumber}`);
    }
}
