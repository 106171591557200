enum ModuleEnums {
    Claim = 'claims',
    Permit = 'permits',
    Planning = 'plannings',
    Transcription = 'transcriptions',
    Deletion = 'deletions',
    Expansion = 'expansions'
}

export default ModuleEnums;
