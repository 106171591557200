import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PermissionGate from '~/containers/PermissionGate';
import ActionButtonWrapper from '~/components/Table/components/ActionButtonWrapper';
import MasterDataEnums from '~/types/shared/master-data-enums';
import ConfiguratorEnums from '~/types/shared/configurator-enums';
import { OtherDocument } from '~/types/services/other-document';

interface ActionButtonsProps {
    record: OtherDocument;
    documentArchiveConfig: string;
    userPermissionNames: string[];
    showDeleteConfirm: (selectedRows) => void;
    showArchivateConfirm: (selectedRows) => void;
    url: string;
    routePermissionName: string;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({
    record,
    documentArchiveConfig,
    userPermissionNames,
    showDeleteConfirm,
    showArchivateConfirm,
    url,
    routePermissionName
}) => {
    const { t } = useTranslation();

    const isSuperAdmin =
        userPermissionNames && userPermissionNames.indexOf('access.full') > -1;

    const isDeletable = (contentStatus): boolean =>
        (contentStatus === MasterDataEnums.ContentStatusArchived &&
            documentArchiveConfig ===
                ConfiguratorEnums.ContentArchiveAndDelete) ||
        documentArchiveConfig === ConfiguratorEnums.ContentOnlyDeletable;

    const isAchievable = (contentStatus): boolean =>
        (contentStatus === MasterDataEnums.ContentStatusInProgress ||
            (contentStatus === MasterDataEnums.ContentStatusClosed &&
                isSuperAdmin)) &&
        documentArchiveConfig !== ConfiguratorEnums.ContentOnlyDeletable;

    const isEditable = (contentStatus): boolean =>
        isSuperAdmin || contentStatus !== MasterDataEnums.ContentStatusClosed;

    return (
        <ActionButtonWrapper>
            <PermissionGate
                permission={userPermissionNames}
                permissionRoute={routePermissionName}
            >
                {isDeletable(record.status.key) && (
                    <Button
                        danger
                        onClick={(): void => showDeleteConfirm([record.id])}
                    >
                        {t('basic.deleteButton')}
                    </Button>
                )}
                {isAchievable(record.status.key) && (
                    <Button
                        danger
                        onClick={(): void => showArchivateConfirm([record.id])}
                    >
                        {t('pages.content.list.archivateButton')}
                    </Button>
                )}
                {isEditable(record.status.key) && (
                    <Link to={`${url}/${record.id}`}>
                        <Button>{t('basic.editButton')}</Button>
                    </Link>
                )}
            </PermissionGate>
            <Link to={`${url}/view/${record.id}`}>
                <Button>{t('basic.viewButton')}</Button>
            </Link>
        </ActionButtonWrapper>
    );
};

export default ActionButtons;
