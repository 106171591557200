import React, { useContext, useState } from 'react';
import { Typography, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { usePromiseTracker } from 'react-promise-tracker';
import { useHistory } from 'react-router-dom';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import qs from 'query-string';
import ServiceEnums from '~/types/shared/service-enums';
import Table from '~/components/Table';
import { ActionProps } from '~/components/Table/types/ActionProps';
import { ConfiguratorContext } from '~/shared/context/configurator';
import { PermissionContext } from '~/shared/context/permission';
import ConfiguratorEnums from '~/types/shared/configurator-enums';
import columns from '~/pages/Planning/List/columns';
import { Planning } from '~/types/services/planning-service';
import PlanningService from '~/services/planning-service';
import MasterDataEnums from '~/types/shared/master-data-enums';

const planningService = new PlanningService();

const PlanningListPage: React.FC = () => {
    const { t } = useTranslation();
    const { push, location } = useHistory();
    const sapClientQuery = get(
        qs.parse(location.search),
        'sapClientNumber',
        null
    );
    const { promiseInProgress: planningLoading } = usePromiseTracker({
        area: ServiceEnums.Planning
    });
    const { promiseInProgress: configLoading } = usePromiseTracker({
        area: ServiceEnums.Configurator
    });
    const [plannings, setPlannings] = useState<Planning[]>([]);
    const [total, setTotal] = useState(0);
    const configurations = useContext(ConfiguratorContext);
    const { permissions, userHasPermission } = useContext(PermissionContext);
    const userPermissionNames =
        permissions && permissions.map(permission => permission.name);

    const documentArchiveConfig = get(
        configurations.getConfig(ConfiguratorEnums.ContentArchiveConfigKey),
        'value[0].name'
    );

    const fetchPlannings = (
        filters: object = {},
        orders: object = {},
        page = 1
    ): void => {
        planningService.fetchPlannings(filters, orders, page).then(res => {
            setPlannings(res.data);
            setTotal(res.total);
        });
    };

    const exportPlannings = (
        selectedRows: number[],
        filters: object = {},
        orders: object = {}
    ): void => {
        planningService.exportPlannings(filters, orders);
    };

    const handleGroupDelete = (selectedRows): void => {
        planningService
            .deletePlannings(selectedRows)
            .then(() => fetchPlannings());
    };

    const showDeleteConfirm = (selectedRows): void => {
        Modal.confirm({
            title: t(
                documentArchiveConfig ===
                    ConfiguratorEnums.ContentOnlyArchivable
                    ? 'pages.content.list.confirmArchivesTitle'
                    : 'pages.content.list.confirmDeleteTitle'
            ),
            icon: <ExclamationCircleOutlined />,
            content: t('pages.content.list.confirmDeleteMessage'),
            okText: t('basic.okText'),
            okType: 'danger',
            cancelText: t('basic.cancelText'),
            onOk() {
                handleGroupDelete(selectedRows);
            }
        });
    };

    const showArchivateConfirm = (selectedRows): void => {
        Modal.confirm({
            title: t('pages.content.list.confirmArchivateTitle'),
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: t('basic.okText'),
            okType: 'danger',
            cancelText: t('basic.cancelText'),
            onOk() {
                handleGroupDelete(selectedRows);
            }
        });
    };

    const actions: ActionProps[] = [
        {
            multiple: true,
            label: t(
                documentArchiveConfig ===
                    ConfiguratorEnums.ContentOnlyArchivable
                    ? 'basic.archiveSelectedButton'
                    : 'basic.deleteSelectedButton'
            ),
            danger: true,
            onClick: showDeleteConfirm
        },
        {
            label: t('basic.exportButton'),
            type: 'ghost',
            onClick: exportPlannings
        },
        {
            label: t('basic.addNewButton'),
            onClick: (): void => push('/content/tervezes/new')
        }
    ];

    const cols = columns(
        documentArchiveConfig,
        userPermissionNames,
        showArchivateConfirm,
        showDeleteConfirm,
        t
    );

    return (
        <div>
            <Typography.Title level={1}>
                {t('pages.content.list.planningList')}
            </Typography.Title>
            <Table
                rowKey="id"
                loading={planningLoading || configLoading}
                columns={cols}
                dataSource={plannings}
                actions={
                    userHasPermission(
                        userPermissionNames,
                        'tervezes.kivitelezes.manage'
                    )
                        ? actions
                        : []
                }
                fetchSource={fetchPlannings}
                total={total}
                initialStatusFilter={{
                    status_key: MasterDataEnums.ContentStatusInProgress
                }}
                initialSearchInput={sapClientQuery}
            />
        </div>
    );
};

export default PlanningListPage;
