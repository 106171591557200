import BaseService from '~/services/base-service';
import ServiceEnums from '~/types/shared/service-enums';
import { ApiResponse, PaginatedResponse } from '~/types/services/common';
import { SapClientImport } from '~/types/services/sap-client-import-service';

export default class SapClientImportService extends BaseService {
    constructor() {
        super(ServiceEnums.SapClientImport);
    }

    fetchSapClientImports(
        filter: object,
        sort: object,
        page: number
    ): Promise<PaginatedResponse<SapClientImport>> {
        return this.getRequest(``, {
            filter,
            sort,
            page
        });
    }

    fetchSapClientImport(
        id: string | number
    ): Promise<ApiResponse<SapClientImport>> {
        return this.getRequest(`${id}`);
    }

    createSapClientImport<T>(data: SapClientImport): Promise<T> {
        return this.postRequest(``, data);
    }

    exportSapClientImports(filter: object, sort: object): void {
        this.download('export', {
            filter,
            sort
        });
    }
}
