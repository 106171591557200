import React from 'react';
import moment from 'moment';
import { AlignType } from 'rc-table/lib/interface';
import get from 'lodash/get';
import findKey from 'lodash/findKey';
import { ColumnsType } from 'antd/es/table';
import { Document } from '~/types/services/content-service';
import filterMasterData from '~/components/Table/components/filterMasterData';
import ServiceEnums from '~/types/shared/service-enums';
import filterDateRange from '~/components/Table/components/filterDateRange';
import ActionButtons from '~/pages/Transcription/List/ActionButtons';
import { Transcription } from '~/types/services/transcription-service';
import TranscriptionBuilderFieldNameEnums from '~/types/shared/transcription-builder-field-name-enums';

const columns = (
    documentArchiveConfig,
    userPermissionNames,
    showArchivateConfirm,
    showDeleteConfirm,
    t
): ColumnsType<never> => {
    const url = '/content/atirasok';
    const routePermissionName = 'atiras.manage';

    return [
        {
            title: t('pages.content.fields.business_case_number'),
            key: 'business_case_number',
            sorter: true,
            render: (data: Transcription): string =>
                get(data, 'business_case.case_number')
        },
        {
            title: t('pages.content.fields.recipient_sap_client_number'),
            key: 'recipient_sap_client_number',
            sorter: true,
            render: (data: Transcription): string =>
                get(data, 'recipient_sap_client.sap_client_number')
        },
        {
            title: t('pages.content.fields.recipient_name'),
            key: 'recipient_name',
            sorter: true,
            render: (data: Transcription): string => {
                const codeFieldName = findKey(
                    data.builderFieldNames,
                    item =>
                        item.indexOf(
                            TranscriptionBuilderFieldNameEnums.RecipientName
                        ) !== -1
                );
                return get(data.builder, codeFieldName, '-');
            }
        },
        {
            title: t('pages.content.fields.delivering_sap_client_number'),
            key: 'delivering_sap_client_number',
            sorter: true,
            render: (data: Transcription): string =>
                get(data, 'delivering_sap_client.sap_client_number')
        },
        {
            title: t('pages.content.fields.delivering_name'),
            key: 'delivering_name',
            sorter: true,
            render: (data: Transcription): string => {
                const codeFieldName = findKey(
                    data.builderFieldNames,
                    item =>
                        item.indexOf(
                            TranscriptionBuilderFieldNameEnums.DeliveringName
                        ) !== -1
                );
                return get(data.builder, codeFieldName, '-');
            }
        },
        {
            title: t('pages.content.fields.status'),
            key: 'status_id',
            sorter: true,
            render: (data: Document): string => get(data, 'status.label'),
            ...filterMasterData(ServiceEnums.Content, 'document_status')
        },
        {
            title: t('pages.content.fields.created_at'),
            dataIndex: 'created_at',
            sorter: true,
            render: (text): string =>
                moment(text)
                    .format('YYYY-MM-DD')
                    .toString(),
            ...filterDateRange
        },
        {
            title: t('pages.content.fields.updated_at'),
            dataIndex: 'updated_at',
            sorter: true,
            render: (text): string =>
                text
                    ? moment(text)
                          .format('YYYY-MM-DD')
                          .toString()
                    : '-',
            ...filterDateRange
        },
        {
            title: t('basic.action'),
            key: 'action',
            align: 'right' as AlignType,
            render: (text, record): React.ReactNode =>
                ActionButtons({
                    record,
                    documentArchiveConfig,
                    showArchivateConfirm,
                    showDeleteConfirm,
                    userPermissionNames,
                    url,
                    routePermissionName
                })
        }
    ];
};

export default columns;
