import React, { ReactElement, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { Button } from 'antd';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import Spinner from '~/components/Spinner';
import EditForm from '~/components/EditForm';
import ServiceEnums from '~/types/shared/service-enums';
import FormBuilder from '~/containers/FormBuilder';
import EditFormSchema from './EditForm.validation';
import { Lease } from '~/types/services/lease-service';
import SapClientNumber from '~/components/SapClientNumber';
import SapClientModulesBuilderFieldNames from '~/types/shared/sap-client-modules-field-names';
import { MasterData } from '~/types/services/masterdata-service';
import MasterDataSelect from '~/components/MasterDataSelect';
import ActionButtonWrapper from '../../../../components/Table/components/ActionButtonWrapper';

interface LeaseFormProps {
    onSubmit: (values, helpers: FormikHelpers<[]>) => void;
    lease: Lease;
    isEditing: boolean;
    loading: boolean;
    isViewing: boolean;
}

const LeaseEditForm: React.FC<LeaseFormProps> = ({
    onSubmit,
    lease,
    loading,
    isEditing,
    isViewing
}) => {
    const { t } = useTranslation();
    const [sapClientFields, setSapClientFields] = useState([]);
    const [sapClientValue, setSapClientValue] = useState(false);
    const buttonItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 }
    };

    if (!lease && isEditing) return <Spinner />;
    const getBuilderFields = (fields): void => {
        setSapClientFields(fields);
    };
    const sapClientHasValue = (hasValue): void => {
        setSapClientValue(hasValue);
    };

    const initialType = get(lease, 'company') as MasterData;

    return (
        <Formik
            validationSchema={EditFormSchema}
            validateOnBlur
            onSubmit={onSubmit}
            initialValues={{
                sap_client_number: get(lease, 'sap_client_number', null),
                company: initialType && {
                    key: initialType.id,
                    value: initialType.id,
                    label: initialType.label
                },
                builder: get(lease, 'builder', {})
            }}
        >
            {({
                handleChange,
                handleBlur,
                initialValues,
                handleSubmit,
                setFieldValue,
                isValid
            }): ReactElement => (
                <EditForm>
                    <SapClientNumber
                        name="sap_client_number"
                        label={t('pages.content.fields.sap_client_number')}
                        placeholder={t(
                            'pages.content.fields.sap_client_number'
                        )}
                        disabled={isViewing}
                        required
                        searchable
                        initialValue={initialValues.sap_client_number}
                        moduleSapClientFields={sapClientFields}
                        setFieldEnums={SapClientModulesBuilderFieldNames.lease}
                        getSapClientHasValue={sapClientHasValue}
                    />
                    <FormBuilder
                        formKey="lease_form_basics"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isViewing}
                        getBuilderFields={getBuilderFields}
                        sapClientHasValue={sapClientValue}
                    />
                    <MasterDataSelect
                        required
                        module={ServiceEnums.Content}
                        placeholder={t('pages.content.edit.company')}
                        type="document_company"
                        name="company"
                        label={t('pages.content.fields.company')}
                        isFormik
                        disabled={isViewing}
                    />
                    {!isViewing && (
                        <Form.Item name="submit" {...buttonItemLayout}>
                            <ActionButtonWrapper>
                                <SubmitButton type="primary" loading={loading}>
                                    {t('basic.saveButton')}
                                </SubmitButton>
                                <Button
                                    onClick={(): void => {
                                        setFieldValue('download', true);
                                        handleSubmit();
                                    }}
                                    type="primary"
                                    loading={loading}
                                    disabled={!isValid}
                                >
                                    {t(
                                        'pages.content.lease.downloadGeneratedFile'
                                    )}
                                </Button>
                            </ActionButtonWrapper>
                        </Form.Item>
                    )}
                </EditForm>
            )}
        </Formik>
    );
};

export default LeaseEditForm;
