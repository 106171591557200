import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { usePromiseTracker } from 'react-promise-tracker';
import { useTranslation } from 'react-i18next';
import ServiceEnums from '~/types/shared/service-enums';
import ToastrService from '~/services/toastr-service';
import setFieldBackendError from '~/shared/helpers/backend-responses';
import { ApiResponse } from '~/types/services/common';
import SapClientService from '~/services/sap-client-service';
import { SapClient } from '~/types/services/sap-client-service';
import SapClientEditForm from '~/pages/SapClient/Edit/components/SapClientEditForm';
import Table from '~/components/Table';
import PylonColumnsEnums from '~/types/shared/pylon-columns-enums';
import { Pylon } from '~/types/services/pylon-interface';
import PylonService from '~/services/pylon-service';
import columns from '~/pages/SapClient/Edit/components/columns';
import { ActionProps } from '~/components/Table/types/ActionProps';
import SapClientListPageFilterButtons from './components/SapClientListPageFilterButtons';
import Title from '~/components/Title';

const sapClientService = new SapClientService();
const pylonService = new PylonService();

const SapClientEditPage: React.FC = () => {
    const { t } = useTranslation();
    const { id: sapClientId } = useParams();
    const [sapClient, setSapClient] = useState<SapClient>();
    const [pylons, setPylons] = useState<Pylon[]>([]);
    const [total, setTotal] = useState(0);
    const history = useHistory();
    const { promiseInProgress } = usePromiseTracker({
        area: ServiceEnums.SapClient
    });
    const { promiseInProgress: pylonLoading } = usePromiseTracker({
        area: ServiceEnums.Pylon
    });

    const isEditing = sapClientId !== 'new';
    const isViewing = history.location.pathname.indexOf('view') !== -1;

    useEffect(() => {
        if (!isEditing) return;
        const promise = (): Promise<ApiResponse<SapClient>> =>
            sapClientService.fetchSapClient(sapClientId);

        promise()
            .then(res => setSapClient(res.data))
            .catch(() => {
                ToastrService.error(t('pages.content.edit.buyerNotFound'));
                history.push('/content/vevok');
            });
    }, [history, sapClientId, isEditing]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = (values, helpers): void => {
        if (!isViewing) {
            const promise = (): Promise<unknown> =>
                isEditing
                    ? sapClientService.updateSapClient(sapClientId, values)
                    : sapClientService.createSapClient(values);

            promise()
                .then(() => {
                    ToastrService.success(
                        t('pages.content.edit.buyerSuccessSave')
                    );
                })
                .catch(e => {
                    if (e.response.status === 422) {
                        setFieldBackendError(e.response.data, helpers);
                    } else {
                        ToastrService.error(
                            t('pages.content.edit.buyerFailedSave')
                        );
                    }
                });
        }
    };

    const fetchClientPylons = (
        filters: object = {},
        orders: object = {},
        page = 1
    ): void => {
        if (sapClientId !== 'new') {
            pylonService
                .fetchClientPylons(
                    { ...filters, sap_client_id: sapClientId },
                    orders,
                    page
                )
                .then(res => {
                    setPylons(res.data);
                    setTotal(res.total);
                });
        }
    };

    const exportPylons = (
        selectedRows: number[],
        filters: object = {},
        orders: object = {}
    ): void => {
        pylonService.exportPylons(
            { ...filters, sap_client_id: sapClientId },
            orders
        );
    };

    const cols = columns(sapClientId, pylonService);
    const actions: ActionProps[] = [
        {
            label: t('basic.exportButton'),
            type: 'ghost',
            onClick: exportPylons
        }
    ];

    const action = isViewing
        ? t('pages.content.edit.buyerViewTitle')
        : t(
              isEditing
                  ? 'pages.content.edit.buyerEditTitle'
                  : 'pages.content.edit.buyerCreateTitle'
          );

    return (
        <>
            <h1>{action}</h1>
            <SapClientEditForm
                isEditing={isEditing}
                isViewing={isViewing}
                onSubmit={handleSubmit}
                sapClient={sapClient}
                loading={promiseInProgress}
            />
            {sapClient && (
                <>
                    <SapClientListPageFilterButtons
                        sapClientNumber={sapClient.sap_client_number}
                    />
                    <Title type="secondary">
                        {t('pages.content.buyer.buyerPylons')}
                    </Title>
                    <Table
                        rowKey={PylonColumnsEnums.TartoAzonosito}
                        loading={pylonLoading}
                        total={total}
                        columns={cols}
                        dataSource={pylons}
                        actions={actions}
                        fetchSource={fetchClientPylons}
                        isRowsSelectable={false}
                        isGlobalSearchable={false}
                        pageSize={20}
                    />
                </>
            )}
        </>
    );
};

export default SapClientEditPage;
