import React, { useContext, useState } from 'react';
import { Typography } from 'antd';
import { usePromiseTracker } from 'react-promise-tracker';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ServiceEnums from '~/types/shared/service-enums';
import Table from '~/components/Table';
import { ActionProps } from '~/components/Table/types/ActionProps';
import { PermissionContext } from '~/shared/context/permission';
import columns from '~/pages/SapClientImport/List/columns';
import SapClientImportService from '~/services/sap-client-import-service';
import { SapClientImport } from '~/types/services/sap-client-import-service';

const sapClientImportService = new SapClientImportService();

const SapClientImportListPage: React.FC = () => {
    const { t } = useTranslation();
    const { push } = useHistory();
    const { promiseInProgress: sapClientImportLoading } = usePromiseTracker({
        area: ServiceEnums.SapClientImport
    });
    const [sapClientImports, setSapClientImports] = useState<SapClientImport[]>(
        []
    );
    const [total, setTotal] = useState(0);

    const { permissions, userHasPermission } = useContext(PermissionContext);
    const userPermissionNames =
        permissions && permissions.map(permission => permission.name);

    const fetchSapClientImports = (
        filters: object = {},
        orders: object = {},
        page = 1
    ): void => {
        sapClientImportService
            .fetchSapClientImports(filters, orders, page)
            .then(res => {
                setSapClientImports(res.data);
                setTotal(res.total);
            });
    };

    const exportSapClientImports = (
        selectedRows: number[],
        filters: object = {},
        orders: object = {}
    ): void => {
        sapClientImportService.exportSapClientImports(filters, orders);
    };

    const actions: ActionProps[] = [
        {
            label: t('basic.exportButton'),
            type: 'ghost',
            onClick: exportSapClientImports
        },
        {
            label: t('basic.addNewButton'),
            onClick: (): void => push('/content/sap-vevok/new')
        }
    ];

    const cols = columns(t);

    return (
        <div>
            <Typography.Title level={1}>
                {t('pages.content.list.sapClientImport')}
            </Typography.Title>
            <Table
                rowKey="id"
                loading={sapClientImportLoading}
                columns={cols}
                dataSource={sapClientImports}
                actions={
                    userHasPermission(
                        userPermissionNames,
                        'sap.vevo.import.manage'
                    )
                        ? actions
                        : []
                }
                fetchSource={fetchSapClientImports}
                total={total}
            />
        </div>
    );
};

export default SapClientImportListPage;
