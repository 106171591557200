import get from 'lodash/get';
import { Switch } from 'formik-antd';
import { ColumnsType } from 'antd/es/table';
import MasterDataSelect from '~/components/MasterDataSelect';
import { Pylon } from '~/types/services/pylon-interface';
import PylonColumnsEnums from '~/types/shared/pylon-columns-enums';

const pylonColumns = (
    disabled,
    changeMsp,
    changePaymentType,
    paymentTypeNeed
): ColumnsType<never> => {
    const columns = [
        {
            title: 'Tartó azonosító',
            dataIndex: PylonColumnsEnums.TartoAzonosito,
            key: PylonColumnsEnums.TartoAzonosito
        },
        {
            title: 'Irányítószám',
            dataIndex: PylonColumnsEnums.IRSzam,
            key: PylonColumnsEnums.IRSzam
        },
        {
            title: 'Település',
            dataIndex: PylonColumnsEnums.Telepules,
            key: PylonColumnsEnums.Telepules
        },
        {
            title: 'Közterület neve',
            dataIndex: PylonColumnsEnums.Kozterulet,
            key: PylonColumnsEnums.Kozterulet
        },
        {
            title: 'Házszám',
            dataIndex: PylonColumnsEnums.Hazszam,
            key: PylonColumnsEnums.Hazszam
        },
        {
            title: 'Helyrajzi szám',
            dataIndex: PylonColumnsEnums.Hrsz,
            key: PylonColumnsEnums.Hrsz
        },
        {
            title: 'Koordináták',
            key: 'koordinatak',
            render: (data: Pylon): string => {
                return `${get(data, 'x1')} - ${get(data, 'y1')}`;
            }
        },
        {
            title: 'Típus',
            dataIndex: PylonColumnsEnums.Halozat,
            key: PylonColumnsEnums.Halozat
        },
        {
            title: 'Fizetős',
            key: 'payment_type',
            render: (data: Pylon): JSX.Element =>
                MasterDataSelect({
                    module: 'Pylon',
                    type: 'pylon_payment_type',
                    isFormik: false,
                    defaultValue: data.payment_type,
                    onChange: (masterData): void =>
                        changePaymentType(masterData, data),
                    disabled
                })
        },
        {
            title: 'MSP',
            key: 'MSP',
            render: (data: Pylon): JSX.Element =>
                Switch({
                    name: 'MSP',
                    checked: data.MSP || false,
                    onChange: (): void => changeMsp(data),
                    disabled
                })
        }
    ];

    return paymentTypeNeed
        ? columns
        : columns.filter(column => column.key !== 'payment_type');
};

export default pylonColumns;
