import get from 'lodash/get';
import { ColumnsType } from 'antd/es/table';
import { LabeledValue } from 'antd/es/select';
import { Pylon } from '~/types/services/pylon-interface';
import PylonColumnsEnums from '~/types/shared/pylon-columns-enums';
import filterInput from '~/components/Table/components/filterInput';
import filterMasterData from '~/components/Table/components/filterMasterData';
import filterAjaxSelect from '~/components/Table/components/filterAjaxSelect';
import {
    FilterDataInterface,
    filterDropdown
} from '~/components/Table/components/filterDropdown';
import PylonTypesEnums from '~/types/shared/pylon-types-enums';

const columns = (sapClientId, pylonService): ColumnsType<never> => {
    const pylonTypes: FilterDataInterface[] = [
        {
            text: PylonTypesEnums.KIF,
            value: PylonTypesEnums.KIF
        },
        {
            text: PylonTypesEnums.KOF,
            value: PylonTypesEnums.KOF
        },
        {
            text: PylonTypesEnums.KIF_KOF,
            value: PylonTypesEnums.KIF_KOF
        }
    ];

    return [
        {
            title: 'Tartó azonosító',
            dataIndex: PylonColumnsEnums.TartoAzonosito,
            key: PylonColumnsEnums.TartoAzonosito,
            sorter: true,
            ...filterInput
        },
        {
            title: 'Irányítószám',
            dataIndex: PylonColumnsEnums.IRSzam,
            key: PylonColumnsEnums.IRSzam,
            ...filterAjaxSelect(
                (value): Promise<LabeledValue[]> =>
                    pylonService
                        .fetchClientPylonsZipCodes(sapClientId, value)
                        .then(res => res.data)
            )
        },
        {
            title: 'Település',
            dataIndex: PylonColumnsEnums.Telepules,
            key: PylonColumnsEnums.Telepules,
            sorter: true,
            ...filterInput
        },
        {
            title: 'Közterület neve',
            dataIndex: PylonColumnsEnums.Kozterulet,
            key: PylonColumnsEnums.Kozterulet,
            sorter: true,
            ...filterInput
        },
        {
            title: 'Házszám',
            dataIndex: PylonColumnsEnums.Hazszam,
            key: PylonColumnsEnums.Hazszam,
            sorter: true,
            ...filterInput
        },
        {
            title: 'Helyrajzi szám',
            dataIndex: PylonColumnsEnums.Hrsz,
            key: PylonColumnsEnums.Hrsz,
            sorter: true,
            ...filterInput
        },
        {
            title: 'Koordináták',
            key: 'koordinatak',
            sorter: true,
            ...filterInput,
            render: (data: Pylon): string => {
                return `${get(data, 'x1')} - ${get(data, 'y1')}`;
            }
        },
        {
            title: 'Oszlop típusa',
            dataIndex: PylonColumnsEnums.Halozat,
            key: PylonColumnsEnums.Halozat,
            sorter: true,
            ...filterDropdown(pylonTypes)
        },
        {
            title: 'Fizetős',
            key: 'payment_type_id',
            render: (data: Pylon): string =>
                get(data, 'pylon_payment_type.label'),
            ...filterMasterData('Pylon', 'pylon_payment_type')
        },
        {
            title: 'Bérlet kezdetének időpontja',
            dataIndex: 'lease_date',
            sorter: true
        }
    ];
};

export default columns;
