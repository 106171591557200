import React, { ReactElement, useEffect, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import { Button } from 'antd';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { DownloadOutlined } from '@ant-design/icons';
import Spinner from '~/components/Spinner';
import EditForm from '~/components/EditForm';
import MasterDataSelect from '~/components/MasterDataSelect';
import ServiceEnums from '~/types/shared/service-enums';
import { MasterData } from '~/types/services/masterdata-service';
import FormBuilder from '~/containers/FormBuilder';
import EditFormSchema from './EditForm.validation';
import { Planning } from '~/types/services/planning-service';
import FormBuilderArray from '~/containers/FormBuilder/FormBuilderArray';
import SapClientNumber from '~/components/SapClientNumber';
import CaseNumberField from '~/components/CaseNumberField';
import SapClientModulesBuilderFieldNames from '~/types/shared/sap-client-modules-field-names';
import MasterDataEnums from '~/types/shared/master-data-enums';
import PylonTable from '~/components/PylonTable';
import ActionButtonWrapper from '../../../../components/Table/components/ActionButtonWrapper';
import { setPlanningFields } from '~/shared/utils/form-builder';
import GroupSelector from '~/pages/Planning/Edit/components/GroupSelector';

const GenerateButton = styled(Button)`
    margin-left: 15px;
`;

interface EditFormProps {
    onSubmit: (values, helpers: FormikHelpers<[]>) => void;
    onGenerateAnnex: () => void;
    onGeneratePlanApprove: () => void;
    onSendBillingEmail: () => void;
    onWorkPlaceEmail: (arrayIndex) => void;
    onSuitableEmail: () => void;
    planning: Planning;
    isEditing: boolean;
    loading: boolean;
    isViewing: boolean;
    isNew: boolean;
}

const PlanningEditForm: React.FC<EditFormProps> = ({
    onSubmit,
    onGenerateAnnex,
    onGeneratePlanApprove,
    onSendBillingEmail,
    onWorkPlaceEmail,
    onSuitableEmail,
    planning,
    loading,
    isEditing,
    isViewing,
    isNew
}) => {
    const { t } = useTranslation();
    const [builderFields, setBuilderFields] = useState([]);
    const [sapClientValue, setSapClientValue] = useState(false);
    const initialPylons = get(planning, 'pylons', []);
    const [pylons, setPylonsData] = useState(initialPylons);
    const buttonItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 }
    };
    useEffect(() => {
        if (initialPylons.length) {
            setPylonsData(initialPylons);
        }
    }, [initialPylons]);

    if (!planning && isEditing) return <Spinner />;

    const initialType = get(planning, 'company') as MasterData;
    const initialStatus = get(planning, 'status') as MasterData;
    const initialCaseNumber = get(planning, 'business_case_number');
    const initialGroups = get(planning, 'groups', []).map(group => ({
        value: group.id,
        label: group.name
    }));
    const initialGroupIds = initialGroups
        ? initialGroups.map(group => group.value)
        : [];

    const getBuilderFields = (fields): void => {
        setBuilderFields(fields);
    };
    const sapClientHasValue = (hasValue): void => {
        setSapClientValue(hasValue);
    };
    const setPylons = (key, data): void => {
        setPylonsData(data);
    };

    return (
        <Formik
            validationSchema={EditFormSchema}
            validateOnBlur
            onSubmit={onSubmit}
            initialValues={{
                company: initialType && {
                    key: initialType.id,
                    value: initialType.id,
                    label: initialType.label
                },
                status: initialStatus && {
                    key: initialStatus.id,
                    value: initialStatus.id,
                    label: initialStatus.label
                },
                business_case_number: initialCaseNumber && [
                    {
                        value: initialCaseNumber.id,
                        label: initialCaseNumber.label
                    }
                ],
                sap_client_number: get(planning, 'sap_client_number', null),
                builder: get(planning, 'builder', {}),
                builderArrayFieldNames: get(
                    planning,
                    'builderArrayFieldNames',
                    {}
                ),
                pylons: initialPylons || pylons,
                groups: initialGroupIds
            }}
        >
            {({
                values,
                handleChange,
                handleBlur,
                initialValues,
                isValid,
                setFieldValue
            }): ReactElement => (
                <EditForm>
                    <CaseNumberField
                        disabled={isViewing || isEditing}
                        builderFields={builderFields}
                        setFieldsByCaseNumber={setPlanningFields}
                    />
                    <SapClientNumber
                        name="sap_client_number"
                        label={t('pages.content.fields.sap_client_number')}
                        placeholder={t(
                            'pages.content.fields.sap_client_number'
                        )}
                        disabled={isViewing}
                        searchable
                        initialValue={initialValues.sap_client_number}
                        moduleSapClientFields={builderFields}
                        setFieldEnums={
                            SapClientModulesBuilderFieldNames.planning
                        }
                        getSapClientHasValue={sapClientHasValue}
                    />
                    <FormBuilder
                        formKey="planning_form_basics"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isViewing}
                        getBuilderFields={getBuilderFields}
                        sapClientHasValue={sapClientValue}
                    />
                    <MasterDataSelect
                        required
                        module={ServiceEnums.Content}
                        placeholder={t('pages.content.edit.selectStatus')}
                        type={MasterDataEnums.DocumentEditableStatus}
                        name="status"
                        label={t('pages.content.fields.status')}
                        isFormik
                        disabled={isViewing}
                        initialKey={MasterDataEnums.ContentStatusInProgress}
                        isCreate={!isViewing && !isEditing}
                    />
                    <MasterDataSelect
                        required
                        module={ServiceEnums.Content}
                        placeholder={t('pages.content.edit.company')}
                        type="document_company"
                        name="company"
                        label={t('pages.content.fields.company')}
                        isFormik
                        disabled={isViewing}
                    />
                    <GroupSelector
                        name="groups"
                        label={t('pages.content.planning.groupSelector')}
                        setFieldValue={setFieldValue}
                        data={values.groups}
                        initialValues={initialGroups}
                        disabled={isViewing}
                    />
                    <FormBuilder
                        formKey="planning_form_document_files"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isViewing}
                    />
                    <FormBuilderArray
                        formKey="planning_form_work_places"
                        area={ServiceEnums.Planning}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isViewing}
                        addButtonTitle={t(
                            'pages.content.planning.arrayButtonLabel'
                        )}
                        removeConfirmLabel={t(
                            'pages.content.planning.arrayRemoveConfirmLabel'
                        )}
                        isEditing={isEditing}
                        notificationHandler={onWorkPlaceEmail}
                    />
                    <FormBuilder
                        formKey="planning_form_annexes"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isViewing}
                        getFileResponse={setPylons}
                    />
                    <PylonTable
                        pylons={values.pylons}
                        setPylonsData={setPylons}
                        disabled={isViewing}
                    />
                    <Form.Item name="submit" {...buttonItemLayout}>
                        <ActionButtonWrapper>
                            {!isViewing && (
                                <SubmitButton type="primary" loading={loading}>
                                    {t('basic.saveButton')}
                                </SubmitButton>
                            )}
                            {!isNew && (
                                <>
                                    <GenerateButton
                                        loading={loading}
                                        icon={<DownloadOutlined />}
                                        onClick={onGenerateAnnex}
                                    >
                                        {t(
                                            'pages.content.planning.annexButton'
                                        )}
                                    </GenerateButton>
                                    <GenerateButton
                                        loading={loading}
                                        icon={<DownloadOutlined />}
                                        onClick={onGeneratePlanApprove}
                                    >
                                        {t(
                                            'pages.content.planning.planApproveButton'
                                        )}
                                    </GenerateButton>
                                    <Button
                                        disabled={!isValid}
                                        loading={loading}
                                        onClick={onSendBillingEmail}
                                    >
                                        {t('basic.billingButton')}
                                    </Button>
                                    <Button
                                        disabled={!isValid}
                                        loading={loading}
                                        onClick={onSuitableEmail}
                                    >
                                        {t(
                                            'pages.content.planning.suitablePlanningDone'
                                        )}
                                    </Button>
                                </>
                            )}
                        </ActionButtonWrapper>
                    </Form.Item>
                </EditForm>
            )}
        </Formik>
    );
};

export default PlanningEditForm;
