import React, { useState, useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import concat from 'lodash/concat';
import { Input, Table as AntTable } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { ActionProps } from '~/components/Table/types/ActionProps';
import MassActions from '~/components/Table/components/MassActions';

interface TableProps {
    rowKey: string;
    loading: boolean;
    total: number;
    columns: ColumnsType<never>;
    dataSource: object[];
    actions: ActionProps[];
    fetchSource: (filters: object, sort: object, page: number) => void;
    isRowsSelectable?: boolean;
    isGlobalSearchable?: boolean;
    pageSize?: number;
    initialStatusFilter?: object | undefined;
    initialSearchInput?: string | undefined;
}

const Table: React.FC<TableProps> = ({
    rowKey,
    loading,
    total,
    columns,
    dataSource,
    actions,
    fetchSource,
    isRowsSelectable = true,
    isGlobalSearchable = true,
    pageSize = 10,
    initialStatusFilter,
    initialSearchInput
}) => {
    const { t } = useTranslation();
    const [selectedRows, setSelectedRows] = useState([]);
    const [page, setPage] = useState(1);
    const [sort, setSort] = useState({});
    const [filters, setFilters] = useState(initialStatusFilter || {});
    const [search, setSearch] = useState<string>(initialSearchInput);
    const [keyCounter, setKeyCounter] = useState(1);

    useEffect(() => {
        setSelectedRows([]);
    }, [dataSource]);

    useEffect(() => {
        fetchSource(
            search
                ? {
                      ...filters,
                      search
                  }
                : filters,
            sort,
            page
        );
    }, [filters, sort, page, search]); // eslint-disable-line react-hooks/exhaustive-deps

    const onSelectChange = (selectedRowKeys: []): void => {
        setSelectedRows(selectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys: selectedRows,
        onChange: onSelectChange
    };

    const onChange = (pagination, filter, sorter): void => {
        const newSort = {
            0: {
                field:
                    (sorter.order && (sorter.field || sorter.column.key)) ||
                    null,
                order:
                    (sorter.order &&
                        (sorter.order === 'ascend' ? 'ASC' : 'DESC')) ||
                    null
            }
        };
        const isRemoveFiltering =
            !Object.keys(filter).length &&
            !Object.keys(sorter).length &&
            pagination.current === 1;

        if (isRemoveFiltering) {
            setFilters({});
            setSort({});
            setPage(1);
            return;
        }
        if (pagination.current !== page) {
            setPage(pagination.current);
            return;
        }
        if (!isEqual(sort, newSort)) {
            setSort(newSort);
            setPage(1);
        }
        if (initialStatusFilter && !filter.status_id) {
            setFilters({ ...filters, ...filter });
            setPage(1);
            return;
        }
        if (!isEqual(filters, filter)) {
            setFilters(filter);
            setPage(1);
        }
    };

    const tableActions = concat(
        [
            {
                label: t('basic.removeFilterButton'),
                type: 'dashed',
                onClick: (): void => {
                    onChange({ current: 1 }, [], {});
                    setKeyCounter(keyCounter + 1);
                },
                disabled:
                    Object.keys(filters).length === 0 &&
                    Object.keys(sort).length === 0
            }
        ],
        actions
    );

    const searching = (value): void => {
        setSearch(value);
        setPage(1);
    };

    return (
        <>
            <MassActions
                selectedRows={selectedRows}
                filters={filters}
                sort={sort}
                loading={loading}
                actions={tableActions}
            />
            {isGlobalSearchable && (
                <Input.Search
                    placeholder={t('basic.search')}
                    style={{ marginBottom: 16 }}
                    onBlur={(value): void => searching(value.target.value)}
                    enterButton
                    onSearch={(value): void => searching(value)}
                    defaultValue={initialSearchInput || null}
                />
            )}
            <AntTable
                key={`table${keyCounter}`}
                scroll={{ x: 400 }}
                rowSelection={isRowsSelectable && rowSelection}
                rowKey={rowKey}
                dataSource={dataSource}
                columns={columns}
                loading={loading}
                onChange={onChange}
                pagination={{
                    pageSize,
                    total,
                    current: page
                }}
            />
        </>
    );
};

export default Table;
