import React, { useContext, useState } from 'react';
import { Typography, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { usePromiseTracker } from 'react-promise-tracker';
import { useHistory } from 'react-router-dom';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import qs from 'query-string';
import ServiceEnums from '~/types/shared/service-enums';
import Table from '~/components/Table';
import { ActionProps } from '~/components/Table/types/ActionProps';
import { ConfiguratorContext } from '~/shared/context/configurator';
import { PermissionContext } from '~/shared/context/permission';
import ConfiguratorEnums from '~/types/shared/configurator-enums';
import PrinciplePermitService from '~/services/principle-permit-service';
import columns from '~/pages/PrinciplePermit/List/columns';
import MasterDataEnums from '~/types/shared/master-data-enums';

const principlePermitService = new PrinciplePermitService();

const PrinciplePermitListPage: React.FC = () => {
    const { t } = useTranslation();
    const { push, location } = useHistory();
    const sapClientQuery = get(
        qs.parse(location.search),
        'sapClientNumber',
        null
    );
    const { promiseInProgress: principlePermitLoading } = usePromiseTracker({
        area: ServiceEnums.PrinciplePermit
    });
    const { promiseInProgress: configLoading } = usePromiseTracker({
        area: ServiceEnums.Configurator
    });
    const [principlePermits, setPrinciplePermits] = useState([]);
    const [total, setTotal] = useState(0);
    const configurations = useContext(ConfiguratorContext);
    const { permissions, userHasPermission } = useContext(PermissionContext);
    const userPermissionNames =
        permissions && permissions.map(permission => permission.name);

    const documentArchiveConfig = get(
        configurations.getConfig(ConfiguratorEnums.ContentArchiveConfigKey),
        'value[0].name'
    );

    const fetchPrinciplePermits = (
        filters: object = {},
        orders: object = {},
        page = 1
    ): void => {
        principlePermitService
            .fetchPrinciplePermits(filters, orders, page)
            .then(res => {
                setPrinciplePermits(res.data);
                setTotal(res.total);
            });
    };

    const exportClaims = (
        selectedRows: number[],
        filters: object = {},
        orders: object = {}
    ): void => {
        principlePermitService.exportPrinciplePermits(filters, orders);
    };

    const handleGroupDelete = (selectedRows): void => {
        principlePermitService
            .deletePrinciplePermits(selectedRows)
            .then(() => fetchPrinciplePermits());
    };

    const showDeleteConfirm = (selectedRows): void => {
        Modal.confirm({
            title: t(
                documentArchiveConfig ===
                    ConfiguratorEnums.ContentOnlyArchivable
                    ? 'pages.content.list.confirmArchivesTitle'
                    : 'pages.content.list.confirmDeleteTitle'
            ),
            icon: <ExclamationCircleOutlined />,
            content: t('pages.content.list.confirmDeleteMessage'),
            okText: t('basic.okText'),
            okType: 'danger',
            cancelText: t('basic.cancelText'),
            onOk() {
                handleGroupDelete(selectedRows);
            }
        });
    };

    const showArchivateConfirm = (selectedRows): void => {
        Modal.confirm({
            title: t('pages.content.list.confirmArchivateTitle'),
            icon: <ExclamationCircleOutlined />,
            content: '',
            okText: t('basic.okText'),
            okType: 'danger',
            cancelText: t('basic.cancelText'),
            onOk() {
                handleGroupDelete(selectedRows);
            }
        });
    };

    const actions: ActionProps[] = [
        {
            multiple: true,
            label: t(
                documentArchiveConfig ===
                    ConfiguratorEnums.ContentOnlyArchivable
                    ? 'basic.archiveSelectedButton'
                    : 'basic.deleteSelectedButton'
            ),
            danger: true,
            onClick: showDeleteConfirm
        },
        {
            label: t('basic.exportButton'),
            type: 'ghost',
            onClick: exportClaims
        },
        {
            label: t('basic.addNewButton'),
            onClick: (): void => push('/content/elvi-engedelyek/new')
        }
    ];

    const cols = columns(
        documentArchiveConfig,
        userPermissionNames,
        showArchivateConfirm,
        showDeleteConfirm,
        t
    );

    return (
        <div>
            <Typography.Title level={1}>
                {t('pages.content.list.principlePermitList')}
            </Typography.Title>
            <Table
                rowKey="id"
                loading={principlePermitLoading || configLoading}
                columns={cols}
                dataSource={principlePermits}
                actions={
                    userHasPermission(
                        userPermissionNames,
                        'elviengedely.manage'
                    )
                        ? actions
                        : []
                }
                fetchSource={fetchPrinciplePermits}
                total={total}
                initialStatusFilter={{
                    status_key: MasterDataEnums.ContentStatusInProgress
                }}
                initialSearchInput={sapClientQuery}
            />
        </div>
    );
};

export default PrinciplePermitListPage;
