import React from 'react';
import { Table } from 'antd';
import { Pylon } from '~/types/services/pylon-interface';
import pylonColumns from '~/components/PylonTable/pylon-columns';
import PylonColumnsEnums from '~/types/shared/pylon-columns-enums';
import PylonFilesEnums from '~/types/shared/pylon-files-enums';

interface TableProps {
    pylons: Pylon[];
    setPylonsData: (key: string, data: Pylon[]) => void;
    disabled: boolean;
    paymentTypeNeed?: boolean;
}

const PylonTable: React.FC<TableProps> = ({
    pylons,
    setPylonsData,
    disabled,
    paymentTypeNeed = true
}) => {
    const changeMsp = (data): void => {
        const editedPylons = pylons.map(pylon => {
            if (pylon.tarto_azonosito === data.tarto_azonosito) {
                // eslint-disable-next-line no-param-reassign
                pylon.MSP = !pylon.MSP;
                return pylon;
            }
            return pylon;
        });
        setPylonsData(PylonFilesEnums.AcceptedPylonFiles, editedPylons);
    };

    const changePaymentType = (masterData, selectedPylon): void => {
        const editedPylons = pylons.map(pylon => {
            if (pylon.tarto_azonosito === selectedPylon.tarto_azonosito) {
                // eslint-disable-next-line no-param-reassign
                pylon.payment_type = {
                    id: masterData.value,
                    label: masterData.label,
                    value: masterData.value
                };
                return pylon;
            }
            return pylon;
        });
        setPylonsData(PylonFilesEnums.AcceptedPylonFiles, editedPylons);
    };

    const columns = pylonColumns(
        disabled,
        changeMsp,
        changePaymentType,
        paymentTypeNeed
    );

    return (
        <Table
            columns={columns}
            dataSource={pylons}
            rowKey={PylonColumnsEnums.TartoAzonosito}
        />
    );
};

export default PylonTable;
