enum ExpansionBuilderFieldNameEnums {
    CustomerName = 'expansion_customer_name',
    CustomerAddress = 'expansion_customer_address',
    CustomerIsProblematic = 'expansion_complicated_customer_checkbox',
    CustomerProblematicComment = 'expansion_complicated_customer_comment',
    ExpansionPoles = 'expansion_modified_poles',
    Establishment = 'expansion_establishment'
}

export default ExpansionBuilderFieldNameEnums;
