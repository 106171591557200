import React from 'react';
import { Form, Select } from 'formik-antd';
import { FormikHandlers, useFormikContext } from 'formik';
import get from 'lodash/get';
import { FormBuilderField, SelectMeta } from '~/types/services/form-service';
import { getFieldName } from '~/shared/utils/form-builder';

interface BuilderSelectProps {
    field: FormBuilderField;
    onBlur: FormikHandlers['handleBlur'];
    disabled?: boolean;
    isArray?: boolean;
    arrayIndex?: number;
}

const BuilderSelect: React.FC<BuilderSelectProps> = ({
    field,
    onBlur,
    disabled,
    isArray,
    arrayIndex
}) => {
    const { getFieldMeta, setFieldValue } = useFormikContext();
    const meta = field.meta as SelectMeta;
    const fieldName = isArray
        ? `${getFieldName(field.id)}.${arrayIndex}`
        : getFieldName(field.id);
    const fieldIsRequired = field.required ? '*' : '';
    const fieldLabel = `${fieldIsRequired} ${field.label}`;

    const value = get(getFieldMeta(fieldName), 'value') || undefined;

    return (
        <Form.Item
            name={getFieldName(field.id)}
            label={fieldLabel}
            key={field.id}
        >
            <Select
                mode={meta.multiSelect ? 'multiple' : null}
                labelInValue
                data-testid={fieldName}
                name={fieldName}
                placeholder={field.label}
                value={value}
                onChange={(option): void => setFieldValue(fieldName, option)}
                onBlur={onBlur}
                options={meta.options}
                disabled={disabled}
            />
        </Form.Item>
    );
};

export default BuilderSelect;
