import React from 'react';
import moment from 'moment';
import { AlignType } from 'rc-table/lib/interface';
import get from 'lodash/get';
import findKey from 'lodash/findKey';
import { ColumnsType } from 'antd/es/table';
import filterDateRange from '~/components/Table/components/filterDateRange';
import {
    FilterDataInterface,
    filterDropdown
} from '~/components/Table/components/filterDropdown';
import { SapClient } from '~/types/services/sap-client-service';
import ActionButtons from '~/pages/SapClient/List/ActionButtons';
import SapClientBuilderFieldNameEnums from '~/types/shared/sap-client-builder-field-name-enums';

const columns = (
    documentArchiveConfig,
    userPermissionNames,
    showDeleteConfirm,
    t
): ColumnsType<never> => {
    const url = '/content/vevok';
    const routePermissionName = 'vevok.manage';

    const statusFilters: FilterDataInterface[] = [
        {
            text: '',
            value: ''
        },
        {
            text: t('basic.yes'),
            value: 1
        },
        {
            text: t('basic.no'),
            value: 2
        }
    ];

    return [
        {
            title: t('pages.content.fields.sap_client_number'),
            key: 'sap_client_number',
            sorter: true,
            render: (data: SapClient): string => get(data, 'sap_client_number')
        },
        {
            title: t('pages.content.fields.buyer_name'),
            key: 'sap_client_name',
            sorter: true,
            render: (data: SapClient): string => {
                const codeFieldName = findKey(
                    data.builderFieldNames,
                    item =>
                        item.indexOf(
                            SapClientBuilderFieldNameEnums.SapClientName
                        ) !== -1
                );
                return get(data.builder, codeFieldName, '-');
            }
        },
        {
            title: t('pages.content.fields.buyer_address'),
            key: 'sap_client_address',
            sorter: true,
            render: (data: SapClient): string => {
                const codeFieldName = findKey(
                    data.builderFieldNames,
                    item =>
                        item.indexOf(
                            SapClientBuilderFieldNameEnums.SapClientAddress
                        ) !== -1
                );
                return get(data.builder, codeFieldName, '-');
            }
        },
        {
            title: t('pages.content.fields.buyer_tax_number'),
            key: 'sap_client_tax_number',
            sorter: true,
            render: (data: SapClient): string => {
                const codeFieldName = findKey(
                    data.builderFieldNames,
                    item =>
                        item.indexOf(
                            SapClientBuilderFieldNameEnums.SapClientTaxNumber
                        ) !== -1
                );
                return get(data.builder, codeFieldName, '-');
            }
        },
        {
            title: t('pages.content.fields.buyer_problematic'),
            key: 'sap_client_problematic',
            render: (data: SapClient): string => {
                const codeFieldName = findKey(
                    data.builderFieldNames,
                    item =>
                        item.indexOf(
                            SapClientBuilderFieldNameEnums.SapClientProblematic
                        ) !== -1
                );
                return get(data.builder, codeFieldName, {
                    label: '-'
                }).label;
            },
            ...filterDropdown(statusFilters)
        },
        {
            title: t('pages.content.fields.created_at'),
            dataIndex: 'created_at',
            sorter: true,
            render: (text): string =>
                moment(text)
                    .format('YYYY-MM-DD')
                    .toString(),
            ...filterDateRange
        },
        {
            title: t('pages.content.fields.updated_at'),
            dataIndex: 'updated_at',
            sorter: true,
            render: (text): string =>
                text
                    ? moment(text)
                          .format('YYYY-MM-DD')
                          .toString()
                    : '-',
            ...filterDateRange
        },
        {
            title: t('basic.action'),
            key: 'action',
            align: 'right' as AlignType,
            render: (text, record): React.ReactNode =>
                ActionButtons({
                    record,
                    documentArchiveConfig,
                    showDeleteConfirm,
                    userPermissionNames,
                    url,
                    routePermissionName
                })
        }
    ];
};

export default columns;
