import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Form, Input, SubmitButton } from 'formik-antd';
import { useTranslation } from 'react-i18next';
import EditFormSchema from './InviteUserEditForm.validation';
import EditFormWrapper from '~/components/EditForm';

interface EditFormProps {
    onSubmit: (values, helpers: FormikHelpers<[]>) => void;
    loading: boolean;
    isInvite?: boolean;
}

const InviteUserEditForm: React.FC<EditFormProps> = ({ onSubmit, loading }) => {
    const { t } = useTranslation();

    const buttonItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 }
    };

    const initialValue = {
        /* eslint-disable-next-line @typescript-eslint/camelcase */
        first_name: '',
        /* eslint-disable-next-line @typescript-eslint/camelcase */
        last_name: '',
        userName: '',
        email: ''
    };

    return (
        <Formik
            validationSchema={EditFormSchema}
            validateOnBlur
            onSubmit={onSubmit}
            initialValues={initialValue}
        >
            <EditFormWrapper disableformItemLayout>
                <Form.Item
                    name="name"
                    label={t('pages.users.fields.firstName')}
                    noStyle
                >
                    <Form.Item
                        name="first_name"
                        label={t('pages.users.fields.firstName')}
                        required
                    >
                        <Input
                            name="first_name"
                            placeholder={t('pages.users.fields.firstName')}
                        />
                    </Form.Item>
                    <Form.Item
                        name="last_name"
                        label={t('pages.users.fields.lastName')}
                        required
                    >
                        <Input
                            name="last_name"
                            placeholder={t('pages.users.fields.lastName')}
                        />
                    </Form.Item>
                    <Form.Item
                        name="userName"
                        label={t('basic.fields.username')}
                        required
                    >
                        <Input
                            name="userName"
                            placeholder={t('basic.fields.username')}
                        />
                    </Form.Item>
                </Form.Item>
                <Form.Item
                    name="email"
                    label={t('basic.fields.email')}
                    required
                >
                    <Input
                        type="email"
                        name="email"
                        placeholder={t('basic.fields.email')}
                    />
                </Form.Item>
                <Form.Item name="submit" {...buttonItemLayout}>
                    <SubmitButton type="primary" loading={loading}>
                        {t('pages.users.edit.inviteBtn')}
                    </SubmitButton>
                </Form.Item>
            </EditFormWrapper>
        </Formik>
    );
};

export default InviteUserEditForm;
