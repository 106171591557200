import React, { ReactElement } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import Spinner from '~/components/Spinner';
import EditForm from '~/components/EditForm';
import FormBuilder from '~/containers/FormBuilder';
import EditFormSchema from './EditForm.validation';
import { SapClientImport } from '~/types/services/sap-client-import-service';

interface SapClientFormProps {
    onSubmit: (values, helpers: FormikHelpers<[]>) => void;
    sapClientImport: SapClientImport;
    loading: boolean;
    isViewing: boolean;
}

const SapClientImportEditForm: React.FC<SapClientFormProps> = ({
    onSubmit,
    sapClientImport,
    loading,
    isViewing
}) => {
    const { t } = useTranslation();
    const buttonItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 }
    };

    if (!sapClientImport && isViewing) return <Spinner />;

    return (
        <Formik
            validationSchema={EditFormSchema}
            validateOnBlur
            onSubmit={onSubmit}
            initialValues={{
                builder: get(sapClientImport, 'builder', {})
            }}
        >
            {({ handleChange, handleBlur }): ReactElement => (
                <EditForm>
                    <FormBuilder
                        formKey="sapclientimport_form_basics"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isViewing}
                    />
                    {!isViewing && (
                        <Form.Item name="submit" {...buttonItemLayout}>
                            <SubmitButton type="primary" loading={loading}>
                                {t('basic.saveButton')}
                            </SubmitButton>
                        </Form.Item>
                    )}
                </EditForm>
            )}
        </Formik>
    );
};

export default SapClientImportEditForm;
