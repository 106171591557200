enum DeletionBuilderFieldNameEnums {
    CustomerName = 'deletion_customer_name',
    CustomerAddress = 'deletion_customer_address',
    CustomerIsProblematic = 'deletion_complicated_customer_checkbox',
    CustomerProblematicComment = 'deletion_complicated_customer_comment',
    DeletionPoles = 'deletion_modified_poles',
    Establishment = 'deletion_establishment'
}

export default DeletionBuilderFieldNameEnums;
