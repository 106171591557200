import BaseService from '~/services/base-service';
import ServiceEnums from '~/types/shared/service-enums';
import { ApiResponse } from '~/types/services/common';
import { Modules } from '~/types/services/content-service';
import ModuleEnums from '~/types/shared/module-enums';

export default class ContentService extends BaseService {
    constructor() {
        super(ServiceEnums.Content);
    }

    fetchInProgress(
        modulePaginate: object,
        search,
        types: ModuleEnums[]
    ): Promise<ApiResponse<Modules>> {
        return this.getRequest(`in-progress-cases`, {
            ...modulePaginate,
            search,
            types
        });
    }
}
