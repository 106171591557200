import React from 'react';
import { Table as AntTable } from 'antd';
import { ColumnsType } from 'antd/es/table';
import styled from 'styled-components';
import Title from '~/components/Title';

const ModuleTableWrapper = styled.div`
    width: 100%;
    padding: 1em;
`;

interface ModuleTableProps {
    modulePaginateName: string;
    title: string;
    modulePaginate: ({ name: string, page: number }) => void;
    modulePage: number;
    columns: ColumnsType<never>;
    dataSource: object[];
    loading: boolean;
    total: number;
    pageSize?: number;
}

const ModuleTable: React.FC<ModuleTableProps> = ({
    modulePaginateName,
    title,
    modulePaginate,
    modulePage,
    columns,
    dataSource,
    loading,
    total,
    pageSize = 5
}) => {
    const onChange = (pagination): void => {
        if (pagination.current !== modulePage) {
            modulePaginate({
                name: modulePaginateName,
                page: pagination.current
            });
        }
    };

    return (
        <ModuleTableWrapper>
            <Title type="primary">{title}</Title>
            <AntTable
                key={`table${modulePaginateName}`}
                rowKey="id"
                dataSource={dataSource}
                columns={columns}
                loading={loading}
                onChange={onChange}
                pagination={{
                    pageSize,
                    total,
                    current: modulePage
                }}
            />
        </ModuleTableWrapper>
    );
};

export default ModuleTable;
