import { Form } from 'formik-antd';
import React from 'react';
import { LabeledValue, SelectValue } from 'antd/es/select';
import AjaxSelect from '~/components/AjaxSelect';
import PermissionService from '~/services/permission-service';
import i18n from '~/shared/utils/i18n';

const permissionService = new PermissionService();

const onSearch = (value): Promise<LabeledValue[]> =>
    permissionService.fetchGroups({ name: value }, {}, 0).then(res =>
        res.data.map(group => ({
            value: group.id,
            label: group.name
        }))
    );

interface GroupSelectorProps {
    name: string;
    label: string;
    setFieldValue: Function;
    data: LabeledValue[];
    initialValues: SelectValue;
    disabled?: boolean;
}

const GroupSelector: React.FC<GroupSelectorProps> = ({
    name,
    label,
    setFieldValue,
    data,
    initialValues,
    disabled
}) => (
    <Form.Item name={name} label={label}>
        <AjaxSelect
            id={name}
            disabled={disabled}
            placeholder={i18n.t('basic.selectWithLabel', { label })}
            initialValues={initialValues}
            onSearch={onSearch}
            onSelect={(res): void => {
                setFieldValue(name, [...data, res.value]);
            }}
            onDeselect={(res): void => {
                setFieldValue(
                    name,
                    data.filter(selected => selected !== res.value)
                );
            }}
        />
    </Form.Item>
);

export default GroupSelector;
