import React, { ReactElement, useEffect, useState } from 'react';
import { FormikHandlers, useFormikContext } from 'formik';
import { Typography } from 'antd';
import { usePromiseTracker } from 'react-promise-tracker';
import get from 'lodash/get';
import BuilderSelect from './components/BuilderSelect';
import FormService from '~/services/form-service';
import Spinner from '~/components/Spinner';
import {
    FormBuilderField,
    FormBuilderForm
} from '~/types/services/form-service';
import BuilderText from './components/BuilderText';
import BuilderMasterDataSelect from './components/BuilderMasterDataSelect';
import BuilderNumber from './components/BuilderNumber';
import BuilderDatePicker from './components/BuilderDatePicker';
import BuilderFile from './components/BuilderFile';
import ServiceEnums from '~/types/shared/service-enums';
import BuilderDBSelect from '~/containers/FormBuilder/components/BuilderDBSelect';
import BuilderSwitch from '~/containers/FormBuilder/components/BuilderSwitch';
import { getFieldName } from '~/shared/utils/form-builder';
import BuilderTag from '~/containers/FormBuilder/components/BuilderTag';
import BuilderTextArea from '~/containers/FormBuilder/components/BuilderTextArea';
import BuilderPylonFile from '~/containers/FormBuilder/components/BuilderPylonFile';

interface FormBuilderProps {
    formKey: string;
    area?: ServiceEnums;
    onChange: FormikHandlers['handleChange'];
    onBlur: FormikHandlers['handleBlur'];
    disabled?: boolean;
    isArray?: boolean;
    arrayIndex?: number;
    getFileResponse?: (key: string, data: []) => void;
    getBuilderFields?: (fields: FormBuilderField[]) => void;
    sapClientHasValue?: boolean;
}

const formService = new FormService();

const FormBuilder: React.FC<FormBuilderProps> = ({
    area,
    formKey,
    onChange,
    onBlur,
    disabled,
    isArray,
    arrayIndex,
    getFileResponse,
    getBuilderFields,
    sapClientHasValue
}) => {
    const { getFieldMeta, setFieldValue } = useFormikContext();
    const { promiseInProgress } = usePromiseTracker({
        area: area || ServiceEnums.FormBuilder
    });
    const [form, setForm] = useState<FormBuilderForm>();
    useEffect(() => {
        formService.fetchForm(formKey, area).then(res => {
            setForm(res);
            res.fields.map(field =>
                setFieldValue(
                    getFieldName(field.id),
                    get(getFieldMeta(getFieldName(field.id)), 'value') || null
                )
            );
            if (getBuilderFields) {
                getBuilderFields(res.fields);
            }
        });
    }, [area, formKey, setFieldValue]); // eslint-disable-line react-hooks/exhaustive-deps

    const getInputField = (field: FormBuilderField): ReactElement => {
        switch (field.type) {
            case 'file':
                return (
                    <BuilderFile
                        key={field.id}
                        field={field}
                        disabled={disabled}
                        isArray={isArray}
                        arrayIndex={arrayIndex}
                    />
                );
            case 'pylonFile':
                return (
                    <BuilderPylonFile
                        key={field.id}
                        field={field}
                        disabled={disabled}
                        isArray={isArray}
                        arrayIndex={arrayIndex}
                        getFileResponse={getFileResponse}
                    />
                );
            case 'select':
                return (
                    <BuilderSelect
                        key={field.id}
                        field={field}
                        onBlur={onBlur}
                        disabled={disabled}
                        isArray={isArray}
                        arrayIndex={arrayIndex}
                    />
                );
            case 'text':
                return (
                    <BuilderText
                        key={field.id}
                        field={field}
                        onBlur={onBlur}
                        onChange={onChange}
                        disabled={disabled}
                        isArray={isArray}
                        arrayIndex={arrayIndex}
                        sapClientHasValue={sapClientHasValue}
                    />
                );
            case 'text_area':
                return (
                    <BuilderTextArea
                        key={field.id}
                        field={field}
                        onBlur={onBlur}
                        onChange={onChange}
                        disabled={disabled}
                        isArray={isArray}
                        arrayIndex={arrayIndex}
                    />
                );
            case 'tag':
                return (
                    <BuilderTag
                        key={field.id}
                        field={field}
                        onBlur={onBlur}
                        disabled={disabled}
                        isArray={isArray}
                        arrayIndex={arrayIndex}
                    />
                );
            case 'masterData':
                return (
                    <BuilderMasterDataSelect
                        key={field.id}
                        field={field}
                        disabled={disabled}
                        isArray={isArray}
                        arrayIndex={arrayIndex}
                    />
                );
            case 'number':
                return (
                    <BuilderNumber
                        key={field.id}
                        field={field}
                        onBlur={onBlur}
                        disabled={disabled}
                        isArray={isArray}
                        arrayIndex={arrayIndex}
                    />
                );
            case 'datePicker':
                return (
                    <BuilderDatePicker
                        key={field.id}
                        field={field}
                        disabled={disabled}
                        isArray={isArray}
                        arrayIndex={arrayIndex}
                    />
                );
            case 'dbSelect':
                return (
                    <BuilderDBSelect
                        key={field.id}
                        field={field}
                        onBlur={onBlur}
                        disabled={disabled}
                        isArray={isArray}
                        arrayIndex={arrayIndex}
                    />
                );
            case 'switch':
                return (
                    <BuilderSwitch
                        key={field.id}
                        field={field}
                        disabled={disabled}
                        isArray={isArray}
                        arrayIndex={arrayIndex}
                        sapClientHasValue={sapClientHasValue}
                    />
                );
            default:
                return (
                    <Typography.Text type="danger">{`Missing field - ${field.type}`}</Typography.Text>
                );
        }
    };

    if (!form && promiseInProgress) return <Spinner />;

    return <>{form && form.fields.map(field => getInputField(field))}</>;
};

export default FormBuilder;
