import { AxiosInstance } from 'axios';
import { saveAs } from 'file-saver';
import { trackPromise } from 'react-promise-tracker';
import qs from 'qs';
import ServiceEnums from '~/types/shared/service-enums';
import axiosInstance from '~/shared/utils/axios';

export default class BaseService {
    xhr: AxiosInstance;
    service: ServiceEnums;

    constructor(service) {
        this.service = service;
        this.xhr = axiosInstance;
    }

    private getUrl(url: string): string {
        return `${this.service}${url ? `/${url}` : ''}`;
    }

    getRequest<T>(
        url = '',
        queryParams = {},
        area: string = this.service
    ): Promise<T> {
        return trackPromise(
            this.xhr
                .get(this.getUrl(url), {
                    params: queryParams,
                    paramsSerializer(params) {
                        return qs.stringify(params, {
                            arrayFormat: 'brackets',
                            skipNulls: true
                        });
                    }
                })
                .then(res => res.data),
            area
        );
    }

    postRequest<T>(url = '', data, area: string = this.service): Promise<T> {
        return trackPromise(this.xhr.post(this.getUrl(url), data), area);
    }

    putRequest<T>(url = '', data, area: string = this.service): Promise<T> {
        return trackPromise(
            this.xhr.post(this.getUrl(url), {
                _method: 'PUT',
                ...data
            }),
            area
        );
    }

    deleteRequest<T>(url = '', data, area: string = this.service): Promise<T> {
        return trackPromise(
            this.xhr.post(this.getUrl(url), {
                _method: 'DELETE',
                ...data
            }),
            area
        );
    }

    download(
        url = '',
        queryParams = {},
        area: string = this.service
    ): Promise<void> {
        return trackPromise(
            this.xhr.get(this.getUrl(url), {
                params: queryParams,
                paramsSerializer(params) {
                    return qs.stringify(params, {
                        arrayFormat: 'brackets',
                        skipNulls: true
                    });
                },
                responseType: 'blob'
            }),
            area
        ).then(response => {
            saveAs(
                new Blob([response.data]),
                decodeURIComponent(
                    response.headers['content-disposition'].split(
                        'filename='
                    )[1]
                )
            );
        });
    }
}
