import React, { useCallback, useEffect, useState } from 'react';
import { Form, Input } from 'formik-antd';
import { useFormikContext } from 'formik';
import { AutoComplete, Spin } from 'antd';
import { usePromiseTracker } from 'react-promise-tracker';
import debounce from 'lodash/debounce';
import SapClientService from '~/services/sap-client-service';
import ServiceEnums from '~/types/shared/service-enums';
import { FormBuilderField } from '~/types/services/form-service';
import { setSapClientBuilderFields } from '~/shared/utils/form-builder';

interface SapClientNumberProps {
    name: string;
    label: string;
    placeholder: string;
    required?: boolean;
    disabled?: boolean;
    searchable?: boolean;
    initialValue?: number;
    moduleSapClientFields?: FormBuilderField[];
    setFieldEnums?: { name; address };
    getSapClientHasValue?: (hasValue) => void;
}

const sapClientService = new SapClientService();

const SapClientNumber: React.FC<SapClientNumberProps> = ({
    name,
    label,
    placeholder,
    required,
    disabled,
    searchable = false,
    initialValue,
    moduleSapClientFields,
    setFieldEnums,
    getSapClientHasValue
}) => {
    const { setFieldValue, values } = useFormikContext();
    const [options, setOptions] = useState([]);
    const [sapClients, setSapClients] = useState([]);
    const { promiseInProgress } = usePromiseTracker({
        area: ServiceEnums.SapClient
    });

    const changeHasSapClient = (value): void => {
        if (!getSapClientHasValue) return;
        if (!value) {
            getSapClientHasValue(false);
            return;
        }
        getSapClientHasValue(true);
    };

    const setSapClientFields = (sapClient): void => {
        changeHasSapClient(true);
        setSapClientBuilderFields(
            sapClient,
            moduleSapClientFields,
            setFieldEnums,
            setFieldValue
        );
    };

    const onSearch = (value): void => {
        if (!value || !searchable) {
            changeHasSapClient(false);
            return;
        }

        sapClientService.fetchSapClientByNumber(value).then(res => {
            setSapClients(res.data);
            const sapClientNumbers =
                res.data &&
                res.data.map(client => ({
                    value: `${client.sap_client_number}`,
                    label: client.sap_client_number
                }));
            setOptions(sapClientNumbers);
        });
    };
    const delayedOnSearch = useCallback(
        debounce(value => onSearch(value), 1000),
        []
    );

    useEffect(() => {
        if (getSapClientHasValue) {
            getSapClientHasValue(!!initialValue);
        }

        return (): void => {
            delayedOnSearch.cancel();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onSelect = (value): void => {
        const sapClient = sapClients.find(
            client => client.sap_client_number === +value
        );
        setFieldValue(name, +value);
        setSapClientFields(sapClient);
    };

    const onChange = (value): void => {
        const sapClient = sapClients.find(
            client => client.sap_client_number === +value
        );

        if (!value) {
            changeHasSapClient(false);
        }

        if (sapClient) {
            setFieldValue(name, +value);
            setSapClientFields(sapClient);
        }
    };

    const handleBlur = (element): void => {
        const sapClient = sapClients.find(
            client => client.sap_client_number === +element.target.value
        );
        if (sapClient) {
            setFieldValue(name, +element.target.value || null);
            setSapClientFields(sapClient);
        }
    };

    const getInput = (): React.ReactNode => (
        <Input name={name} placeholder={placeholder} type="number" />
    );

    return (
        <Form.Item name={name} label={label} required={required}>
            <AutoComplete
                disabled={disabled}
                onSearch={(value): void => delayedOnSearch(value)}
                options={options}
                onSelect={(value): void => onSelect(value)}
                defaultValue={`${initialValue || ''}`}
                showSearch
                notFoundContent={
                    promiseInProgress ? <Spin size="small" /> : null
                }
                value={`${values[name]}`}
                onBlur={(element): void => handleBlur(element)}
                onChange={(value): void => onChange(value)}
            >
                {getInput()}
            </AutoComplete>
        </Form.Item>
    );
};

export default SapClientNumber;
