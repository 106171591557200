import get from 'lodash/get';
import React from 'react';
import { Link } from 'react-router-dom';
import { ColumnsType } from 'antd/es/table';
import { Pylon } from '~/types/services/pylon-interface';
import PylonColumnsEnums from '~/types/shared/pylon-columns-enums';

const columns = (): ColumnsType<never> => {
    return [
        {
            title: 'Tartó azonosító',
            dataIndex: PylonColumnsEnums.TartoAzonosito,
            key: PylonColumnsEnums.TartoAzonosito
        },
        {
            title: 'Irányítószám',
            dataIndex: PylonColumnsEnums.IRSzam,
            key: PylonColumnsEnums.IRSzam
        },
        {
            title: 'Település',
            dataIndex: PylonColumnsEnums.Telepules,
            key: PylonColumnsEnums.Telepules
        },
        {
            title: 'Közterület neve',
            dataIndex: PylonColumnsEnums.Kozterulet,
            key: PylonColumnsEnums.Kozterulet
        },
        {
            title: 'Házszám',
            dataIndex: PylonColumnsEnums.Hazszam,
            key: PylonColumnsEnums.Hazszam
        },
        {
            title: 'Helyrajzi szám',
            dataIndex: PylonColumnsEnums.Hrsz,
            key: PylonColumnsEnums.Hrsz
        },
        {
            title: 'Koordináták',
            key: 'koordinatak',
            render: (data: Pylon): string => {
                return `${get(data, 'x1')} - ${get(data, 'y1')}`;
            }
        },
        {
            title: 'Típus',
            dataIndex: PylonColumnsEnums.Halozat,
            key: PylonColumnsEnums.Halozat
        },
        {
            title: 'Ütközés',
            key: 'conflict',
            render: (data: Pylon): React.ReactNode => {
                return data
                    ? data.conflicts.map(conflict =>
                          React.createElement(
                              Link,
                              {
                                  to: `/content/igenybejelentesek/${conflict.claimId}`,
                                  target: '_blank',
                                  style: { padding: '10px' }
                              },
                              `${conflict.businessCaseNumber}`
                          )
                      )
                    : null;
            }
        }
    ];
};

export default columns;
