const resources = {
    menu: {
        dashboard: 'Főoldal',
        content: 'Tartalom kezelés',
        documents: 'Dokumentumok',
        editDocument: 'Dokumentum szerkesztése',
        viewDocument: 'Dokumentum megtekintése',
        viewClaim: 'Igénybejelentés megtekintése',
        administration: 'Adminisztráció',
        taxonomies: 'Kategóriák',
        masterDatas: 'Törzsadatok',
        groups: 'Csoportok',
        editGroup: 'Csoport szerkesztése',
        roles: 'Szerepkörök',
        editRole: 'Szerepkör szerkesztése',
        users: 'Felhasználók',
        editUser: 'Felhasználó szerkesztése',
        features: 'Funkciók',
        settings: 'Beállitások',
        profile: 'Profil',
        myProfile: 'Saját profil',
        preferences: 'Preferenciák',
        logout: 'Kijelentkezés',
        claims: 'Igénybejelentések',
        editClaim: 'Igénybejelentés szerkesztése',
        transcriptions: 'Átírások',
        editTanscription: 'Átírás szerkesztése',
        viewTanscription: 'Átírás megtekintése',
        principlePermit: 'Elvi engedélyek',
        editPrinciplePermit: 'Elvi engedély szerkesztése',
        viewPrinciplePermit: 'Elvi engedély megtekintése',
        plannings: 'Tervezés/Kivitelezés',
        editPlanning: 'Tervezés/Kivitelezés szerkesztése',
        viewPlanning: 'Tervezés/Kivitelezés megtekintése',
        leases: 'Bérleti szerződések',
        editLease: 'Bérleti szerződés szerkesztése',
        viewLease: 'Bérleti szerződés megtekintése',
        buyers: 'Vevők',
        editBuyer: 'Vevő szerkesztése',
        viewBuyer: 'Vevő megtekintése',
        deletions: 'Törlések',
        editDeletions: 'Törlés szerkesztése',
        viewDeletion: 'Törlés megtekintése',
        expansions: 'Bővítések',
        editExpansion: 'Bővítés szerkesztése',
        viewExpansion: 'Bővítés megtekintése',
        otherDocuments: 'Egyéb dokumentumok',
        editOtherDocument: 'Egyéb dokumentum szerkesztése',
        viewOtherDocument: 'Egybé dokumentum megtekintése',
        invoices: 'Számlák',
        editInvoice: 'Számla szerkesztése',
        viewInvoice: 'Számla megtekintése',
        sapClientImport: 'SAP vevő import',
        createSapClientImport: 'SAP vevő import létrehozása',
        viewSapClientImport: 'SAP vevő import megtekintése',
        sapInvoiceImport: 'SAP számla import',
        createSapInvoiceImport: 'SAP számla import létrehozása',
        viewSapInvoiceImport: 'SAP számla import megtekintése',
        inProgress: 'Folyamatban lévő ügyek'
    },
    pages: {
        noPermissions: {
            content:
                'Jelenleg nincsen hozzáférése az oldal megtekintéséhez. Kérjük vegye fel a kapcsolatot kollégánkkal.'
        },
        authentication: {
            cardTitle: 'Eszközbérlet nyilvántartó',
            login: {
                loginFailed: 'Hibás bejelentkezés',
                inactiveAccount: 'A hozzáférés inaktiválásra került',
                incorrectLogin: 'Hibás bejelentkezési adatok',
                login: 'Bejelentkezés',
                backToLogin: 'Vissza a bejelentkezéshez',
                adfsLogin: 'ADFS bejelentkezés'
            },
            registration: {
                successTitle: 'Sikeres regisztráció',
                successMessage:
                    'A hozzáféréssel most már be lehet jelentkezni.',
                successSendEmailMessage:
                    'A regisztráció jóváhagyásához emailt küldtünk.',
                registrationFailed: 'Hibás regisztráció',
                verificationSuccess: 'Az email cím jóváhagyásra került',
                verificationError: 'Hibás vagy lejárt aktiválás',
                register: 'Regisztráció',
                firstName: 'Keresztnév',
                lastName: 'Vezetéknév',
                phoneNumber: 'Telefonszám',
                gdpr: 'Elolvastam és elfogadom a GDPR szabályzatot',
                termsAndCondition:
                    'Elolvastam és elfogadom a Felhasználási nyilatkozatot',
                validations: {
                    requiredGdpr: 'A GDPR elfogadása kötelező',
                    requiredTermsAndCondition:
                        'A felhasználási nyilatkozat elfogadása kötelező'
                }
            },
            lostPassword: {
                successTitle: 'Elfelejtett jelszó',
                successMessage:
                    'Az elfelejtett jelszó beállitááshoz emailt küldtünk',
                successSetTitle: 'A jelszó beállitásra került',
                successSetMessage:
                    'A hozzáféréssel most már be lehet jelentkezni.',
                forgotPassword: 'Elfelejtett jelszó',
                resetMyPassword: 'Jelszó beállitása'
            },
            revert: {
                revertSuccess: 'A hozzáférés visszaállitásra került',
                revertExpired: 'Hibás vagy lejárt visszaállitás hivatkozás'
            },
            lostUsername: {
                forgotUsername: 'Elfelejtett felhasználónév',
                sendMyUsername: 'Felhasználónév küldése',
                successTitle: 'Elfelejtett felhasználónév',
                successMessage:
                    'Az email címedre elküldtük a felhasználónevedet'
            }
        },
        content: {
            fields: {
                type: 'Típus',
                title: 'Cím',
                category: 'Kategória',
                status: 'Státusz',
                owners: 'Tulajdonosok',
                viewers: 'Láthatóság',
                endOfValidity: 'Érvényesség vége',
                startOfValidity: 'Érvényesség kezdete',
                company: 'Szolgáltató',
                sap_client_number: 'SAP Vevőazonosító',
                recipient_sap_client_number: 'Átadó SAP Vevőazonosító',
                recipient_name: 'Átadó neve',
                delivering_sap_client_number: 'Átvevő SAP Vevőazonosító',
                delivering_name: 'Átvevő neve',
                business_case_number: 'Ügyszám',
                claim_registrator_name: 'Igénybejelentő neve',
                planning_registrator_name: 'Vevő neve',
                lease_name: 'Vevő neve',
                lease_address: 'Vevő címe',
                lease_send_date: 'Kiküldés dátuma',
                lease_came_back_date: 'Aláírás dátuma',
                created_at: 'Létrehozás dátuma',
                updated_at: 'Utolsó módosítás dátuma',
                generate: 'Generálás',
                decision: 'Döntés',
                buyer_name: 'Vevő neve',
                buyer_address: 'Vevő címe',
                buyer_tax_number: 'Vevő adószáma',
                buyer_problematic: 'Problémás vevő',
                deletion_customer_name: 'Vevő neve',
                expansion_customer_name: 'Vevő neve',
                otherDocument_customer_name: 'Vevő neve',
                otherDocument_name: 'Dokumentum megnevezése',
                invoice_company: 'DÉMÁSZ/ÉMÁSZ',
                invoice_tenant_name: 'Bérlő neve',
                invoice_tenant_address: 'Bérlő címe',
                sap_client_import_name: 'Feltöltő neve',
                sap_client_import_created_at: 'Feltöltés ideje',
                sap_invoice_import_name: 'Feltöltő neve',
                sap_invoice_import_created_at: 'Feltöltés ideje',
                establishment_name: 'Létesítés megnevezése',
                registrator_name: 'Igénybejelentő neve',
                customer_name: 'Vevő neve'
            },
            planning: {
                annexButton: 'Egyes számú melléklet generálása',
                planApproveButton: 'Terv jóváhagyó dokumentum generálása',
                arrayButtonLabel: 'Új munkaterület átadás hozzáadása',
                arrayRemoveConfirmLabel:
                    'Biztosan törli a munkaterület átadást?',
                groupSelector: 'Értesítést kap',
                suitablePlanningDone: 'Alkalmassá tétel megtörtént'
            },
            buyer: {
                columnsDownload: 'Aktuális oszlopok letöltése',
                leaseDownload: 'Egyes számú melléklet letöltése',
                buyerPylons: 'Vevő oszlopai'
            },
            principlePermit: {
                dispositionFile: 'Rendelkezési fájl letöltése'
            },
            lease: {
                downloadGeneratedFile: 'Mentés és Bérleti szerződés generálása'
            },
            list: {
                title: 'Dokumentumok',
                claimList: 'Igénybejelentések',
                principlePermitList: 'Elvi engedélyek',
                transcriptionList: 'Átírások',
                planningList: 'Tervezés/Kivitelezés',
                leaseList: 'Bérleti szerződések',
                buyerList: 'Vevők',
                deletionList: 'Törlések',
                expansionList: 'Bővítések',
                invoiceList: 'Számlák',
                sapClientImport: 'SAP vevő importálások',
                confirmDeleteTitle: 'Biztosan törli a dokumentumokat?',
                confirmArchivesTitle: 'Biztosan archiválja a dokumentumokat?',
                confirmDeleteMessage: 'A müvelet végleges',
                confirmArchivateTitle:
                    'Biztosan archiválja a kijelölt dokumentumot?',
                archivateButton: 'Archiválás',
                otherDocumentList: 'Egyéb dokumentumok',
                sapInvoiceImport: 'SAP számla importálások'
            },
            edit: {
                notFound: 'A dokumentum nem található',
                successSave: 'A dokumentum mentésre került',
                failedSave: 'Hiba történt a dokumentum mentésekor',
                claimSuccessSave: 'Az igénybejelentés mentésre került',
                claimFailedSave: 'Hiba történt az igénybejelentés mentésekor',
                claimNotFound: 'Az igénybejelentés nem található',
                claimEditTitle: 'Igénybejelentés szerkesztése',
                claimCreateTitle: 'Igénybejelentés létrehozása',
                permitEditTitle: 'Elvi engedély szerkesztése',
                permitCreateTitle: 'Elvi engedély létrehozása',
                permitViewTitle: 'Elvi engedély megtekintése',
                permitNotFound: 'Az elvi engedély nem található',
                permitSuccessSave: 'Az elvi engedély mentésre került',
                permitFailedSave: 'Hiba történt az elvi engedély mentésekor',
                editTitle: 'Dokumentum szerkesztése',
                viewTitle: 'Dokumentum megtekintése',
                claimViewTitle: 'Igénybejelentés megtekintése',
                createTitle: 'Dokumentum létrehozása',
                selectType: 'Tipus kiválasztása',
                selectStatus: 'Státusz kiválasztása',
                company: 'Szolgáltató',
                transcriptionNotFound: 'Az átírás nem található',
                transcriptionSuccessSave: 'Az átírás mentésre került',
                transcriptionFailedSave: 'Hiba történt az átírás mentésekor',
                transcriptionViewTitle: 'Átírás megtekintése',
                transcriptionEditTitle: 'Átírás szerkesztése',
                transcriptionCreateTitle: 'Átírás létrehozása',
                decision: 'Elfogad/Elutasít',
                planningNotFound: 'A tervezés/kivitelezés nem található',
                planningSuccessSave: 'A tervezés/kivitelezés mentésre került',
                planningFailedSave:
                    'Hiba történt a tervezés/kivitelezés mentésekor',
                planningViewTitle: 'Tervezés/kivitelezés megtekintése',
                planningEditTitle: 'Tervezés/kivitelezés szerkesztése',
                planningCreateTitle: 'Tervezés/kivitelezés létrehozása',
                planningNotificationWorkPlace: 'Területfelelős e-mail küldése',
                leaseNotFound: 'A bérleti szerződés nem található',
                leaseSuccessSave: 'A bérleti szerződés mentésre került',
                leaseFailedSave: 'Hiba történt a bérleti szerződés mentésekor',
                leaseViewTitle: 'Bérleti szerződés megtekintése',
                leaseEditTitle: 'Bérleti szerződés szerkesztése',
                leaseCreateTitle: 'Bérleti szerződés létrehozása',
                buyerNotFound: 'A vevő nem található',
                buyerSuccessSave: 'A vevő mentésre került',
                buyerFailedSave: 'Hiba történt a vevő mentésekor',
                buyerViewTitle: 'Vevő megtekintése',
                buyerEditTitle: 'Vevő szerkesztése',
                buyerCreateTitle: 'Vevő létrehozása',
                deletionNotFound: 'A törlés nem található',
                deletionSuccessSave: 'A törlés mentésre került',
                deletionFailedSave: 'Hiba történt a törlés mentésekor',
                deletionViewTitle: 'Törlés megtekintése',
                deletionEditTitle: 'Törlés szerkesztése',
                deletionCreateTitle: 'Törlés létrehozása',
                expansionNotFound: 'A bővítés nem található',
                expansionSuccessSave: 'A bővítés mentésre került',
                expansionFailedSave: 'Hiba történt a bővítés mentésekor',
                expansionViewTitle: 'Bővítés megtekintése',
                expansionEditTitle: 'Bővítés szerkesztése',
                expansionCreateTitle: 'Bővítés létrehozása',
                otherDocumentNotFound: 'Az egyéb dokumentum nem található',
                otherDocumentSuccessSave: 'Az egyéb dokumentum mentésre került',
                otherDocumentFailedSave:
                    'Hiba történt az egyéb dokumentum mentésekor',
                otherDocumentViewTitle: 'Egyéb dokumentum megtekintése',
                otherDocumentEditTitle: 'Egyéb dokumentum szerkesztése',
                otherDocumentCreateTitle: 'Egyéb dokumentum létrehozása',
                invoiceNotFound: 'A számla nem található',
                invoiceSuccessSave: 'A számla mentésre került',
                invoiceFailedSave: 'Hiba történt a számla mentésekor',
                invoiceViewTitle: 'Számla megtekintése',
                invoiceEditTitle: 'Számla szerkesztése',
                invoiceCreateTitle: 'Számla létrehozása',
                sapClientImportNotFound: 'A Sap vevő import nem található',
                sapClientImportSuccessSave: 'Az importálás mentésre került',
                sapClientImportFailedSave:
                    'Hiba történt az importálás mentésekor',
                sapClientImportViewTitle: 'Sap vevő import megtekintése',
                sapClientImportCreateTitle: 'Sap vevő import létrehozása',
                sapInvoiceImportNotFound: 'Az SAP számla import nem található',
                sapInvoiceImportSuccessSave:
                    'Az SAP számla importálás mentésre került',
                sapInvoiceImportFailedSave:
                    'Hiba történt az importálás mentésekor',
                sapInvoiceImportViewTitle: 'SAP számla import megtekintése',
                sapInvoiceImportCreateTitle: 'SAP számla import létrehozása',
                annexButton: 'Egyes számú melléklet generálása',
                recipientAnnexButton: 'Átadó egyes számú melléklet generálása',
                deliveringAnnexButton: 'Átvevő egyes számú melléklet generálása'
            },
            validations: {
                requiredTitle: 'A cím megadása kötelező',
                requiredTaxonomy: 'A kategória megadása kötekező',
                requiredType: 'A típus megadása kötekező',
                requiredStatus: 'A státusz megadása kötekező',
                requiredValidFrom:
                    'Az érvényesség kezdete mező megadása kötekező',
                requiredCaseNumber: 'Az ügyszám megadása kötelező',
                requiredDecision: 'A döntés megadása kötelező'
            }
        },
        features: {
            title: 'Funkciók'
        },
        masterData: {
            fields: {
                label: 'Név',
                key: 'Kulcs',
                value: 'Érték',
                description: 'Leírás',
                status: 'Megjelenik a listában'
            },
            list: {
                title: 'Törzsadatok'
            },
            edit: {
                title: 'Törzsadat szerkesztése',
                notFound: 'A törzsadat nem található',
                failedSave: 'A törzsadat mentése nem sikerült',
                successSave: 'A törzsadat mentése sikerült'
            },
            validations: {
                requiredLabel: 'A név megadása kötelező',
                requiredKey: 'A kulcs megadása kötelező',
                requiredValue: 'Az érték megadása kötelező'
            }
        },
        permissions: {
            groups: {
                fields: {
                    name: 'Csoport neve',
                    description: 'Leírás',
                    owners: 'Tulajdonosok',
                    members: 'Tagok',
                    roles: 'Szerepkörök',
                    invoiceNotification: 'E-mailt kap a számlázás indításáról',
                    eegisNotification: 'E-mailt kap az EÉGIS módosításokról',
                    boNotification: 'E-mailt kap a BO-s értesítésekről'
                },
                edit: {
                    notFound: 'A csoport nem található',
                    successSave: 'A csoport mentésre került',
                    failedSave: 'A csoportot nem sikerült menteni',
                    editTitle: 'Csoport szerkesztése',
                    createTitle: 'Csoport létrehozása'
                },
                list: {
                    title: 'Csoportok',
                    confirmDeleteTitle: 'Biztosan törli a csoportot?',
                    confirmDeleteMessage: 'A müvelet végleges'
                },
                validations: {
                    requiredName: 'A csoport nevét kötelező megadni'
                }
            },
            roles: {
                fields: {
                    name: 'Szerepkör neve',
                    description: 'Leírás',
                    permissions: 'Jogosultság'
                },
                edit: {
                    notFound: 'A szerepkör nem található',
                    successSave: 'A szerepkör mentésre került',
                    failedSave: 'A szerepkört nem sikerült menteni',
                    editTitle: 'Szerepkör szerkesztése',
                    createTitle: 'Szerepkör létrehozása'
                },
                list: {
                    title: 'Szerepkörök',
                    confirmDeleteTitle: 'Biztosan törli a szerepkört?',
                    confirmDeleteMessage: 'A müvelet végleges'
                },
                validations: {
                    requiredName: 'A szerepkör nevének kitöltése kötelező'
                }
            }
        },
        settings: {
            successSave: 'A beállitások mentésre került',
            failedSave: 'A beállításokat nem sikerült menteni',
            title: 'Beállitások',
            Preferences: 'Preferenciák'
        },
        taxonomy: {
            fields: {
                parent: 'Szűlő kategória',
                name: 'Név',
                identifier: 'Azonosító',
                description: 'Leírás'
            },
            edit: {
                notFound: 'A kategória nem található',
                successSave: 'A kategória mentésre került',
                failedSave: 'A kategóriát nem sikerült menteni'
            },
            list: {
                addNewBtn: 'Új kategória hozzáadása',
                editBtn: 'Kategória szerkesztése',
                deleteBtn: 'Kategória törlése',
                filterBtn: 'Dokumentum szűrés',
                confirmDeleteTitle: 'Biztosan törli a kategóriát?',
                confirmDeleteMessage: 'A müvelet végleges',
                createTitle: 'Kategória létrehozása',
                editTitle: 'Kategória szerkesztése',
                title: 'Kategóriák'
            },
            validations: {
                requiredName: 'A név megadása kötelező'
            }
        },
        users: {
            fields: {
                status: 'Státusz',
                createdAt: 'Regisztárció ideje',
                name: 'Név',
                firstName: 'Keresztnév',
                lastName: 'Vezetéknév',
                phone: 'Telefonszám',
                group: 'Csoport',
                newEmail: 'Új email cím',
                currentPassword: 'Aktuális jelszó',
                newPassword: 'Új jelszó',
                newPasswordConfirm: 'Új jelszó megerősítése',
                adfsUser: 'ADFS Felhasználó'
            },
            edit: {
                notFound: 'Felhasználó nem található',
                successSave: 'A felhasználó mentésre került',
                failedSave: 'A felhasználót nem sikerült menteni',
                title: 'Felhasználó szerkesztése',
                inviteBtn: 'Meghívó küldése'
            },
            list: {
                title: 'Felhasználók',
                revokeBnt: 'Visszavonás',
                statusActive: 'Aktív',
                statusInactive: 'Inaktív',
                statusWaiting: 'Várakozás az email jóváhagyásra',
                statusPending: 'Függőben',
                statusNew: 'Új',
                inviteBtn: 'Meghívás',
                confirmDeleteTitle: 'Biztosan törli a felhasználót?',
                confirmDeleteMessage: 'A müvelet végleges',
                mySelfDeleteTitle: 'Saját magadat nem törölheted',
                mySelfDeleteMessage: 'Töröld a kiválasztást a listából'
            },
            invite: {
                title: 'Felhasználó meghívása',
                successSave: 'A meghívás sikeresen megtörtént',
                failedSaveTitle: 'Hibás meghívás',
                failedSaveMessage: 'Hiba történt a meghíváskor'
            },
            profile: {
                successSave: 'Az adatok mentésre kerültek',
                failedSave: 'Hiba törént a mentés során',
                emailSendSuccess: 'Az új emailed aktiválásához emailt küldtünk',
                emailSendFailed: 'Hiba törént a mentés során',
                emailChangeSuccess: 'Az új email cím aktiválásra került',
                emailChangeFailed: 'Hibás vagy lejárt aktiváló kulcs',
                passwordChangeSuccess: 'A jelszó frissitésre került',
                passwordChangeFailed: 'Hiba törént a mentés során',
                profileDeleteSuccess: 'A profilod törlésre került',
                profileDeleteFailed: 'Hiba történt a törlés során',
                profileDeleteTitle: 'Profil törlése',
                pageTitle: 'Saját profil',
                emailChangeTitle: 'Email megváltoztatása',
                passwordChangeTitle: 'Jelszó megváltoztatása',
                changeEmailBtn: 'Új email beállitása',
                changePasswordBtn: 'Új jelszó beállítása',
                deleteConfirmPlaceholder:
                    'Jelszó megadása a törlés jóváhagyásához'
            },
            validations: {
                firstNameRequired: 'A keresztnév kitöltése kötelező',
                lastNameRequired: 'A vezetéknév kitöltése kötelező',
                currentPasswordRequired: 'Az aktuális jelszó megadása kötelező'
            }
        }
    },
    taxonomy: {
        selectTaxonomy: 'Kategória kiválasztása'
    },
    upload: {
        successUpload: 'Sikeres fájl feltöltés',
        failedUpload: 'Hiba a fájl feltöltése közben',
        tooLargeFile: 'A fájl mérete túl nagy (Maximum: {{size}} MB)',
        uploadButton: 'Feltöltés'
    },
    basic: {
        title: 'Eszközbérlet nyilvántartó',
        xhrError: 'Hiba történt',
        search: 'Keresés',
        pleaseSelect: 'Válassz',
        selectedXCount: '{{count}} elem kiválasztva',
        okText: 'Igen',
        cancelText: 'Mégsem',
        confirmText: 'Jóváhagyás',
        deleteSelectedButton: 'Kijelöltek törlése',
        archiveSelectedButton: 'Kijelöltek archiválása',
        exportButton: 'Exportálás',
        addNewButton: 'Új létrehozása',
        removeFilterButton: 'Szűrések eltávolítása',
        deleteButton: 'Törlés',
        editButton: 'Szerkesztés',
        viewButton: 'Megtekintés',
        creationDate: 'Létrehozás ideje',
        action: 'Eszközök',
        selectWithLabel: '{{label}} kiválasztása',
        saveButton: 'Mentés',
        view: 'Megtekintés',
        yes: 'Igen',
        no: 'Nem',
        selectAll: 'Összes kijelölése',
        billingButton: 'Email küldése számlázáshoz',
        billingEmailSent: 'Számlázási email elküldve',
        billingEmailFailed: 'Számlázási email küldése sikertelen',
        workPlaceEmailSent: 'Területfelelős email elküldve',
        workPlaceEmailFailed: 'Területfelelősi email küldése sikertelen',
        suitableEmailSent: 'Alkalmassá tétel email elküldve',
        suitableEmailFailed: 'Alkalmassá tétel email küldése sikertelen',
        fields: {
            username: 'Felhasználónév',
            email: 'Email',
            password: 'Jelszó',
            passwordConfirmation: 'Jelszó megerősítése'
        },
        validations: {
            requiredEmail: 'Az email kitöltése kötelező',
            requiredUsername: 'Az felhasználónév kitöltése kötelező',
            requiredPassword: 'A jelszó kitöltése kötelező',
            passwordFormat:
                'A jelszónak minimum 8 karakternek kell lenie, és tartalmaznia kell legalább egy kis és nagybetűt és egy számot',
            passwordMustMatch: 'A jelszavaknak egyezniük kell'
        }
    },
    validations: {
        mixed: {
            default: 'A mező érvénytelen',
            required: 'A mező kitöltése kötelező',
            oneOf:
                'A mezőnek a következő értékek egyikének kell lennie: {{param}}',
            notOneOf:
                'A mezőnek nem szabad a következő értékek egyikének lennie: {{param}}',
            defined: 'A mezőt meg kell határozni'
        },
        string: {
            length: 'A mezőnek pontosan {{param}} karakternek kell lennie',
            min: 'A mezőnek legalább {{param}} karakternek kell lennie',
            max: 'A mező legfeljebb {{param}} karakter lehet',
            matches: 'A mezőnek meg kell egyeznie a következővel: {{param}}',
            email: 'A mezőnek érvényes e-mail címnek kell lennie',
            url: 'A mezőnek érvényes URL-nek kell lennie',
            uuid: 'A mezőnek érvényes UUID-nek kell lennie',
            trim: 'Field must be a trimmed string',
            lowercase: 'A mezőnek kisbetűsnek kell lennie',
            uppercase: 'A mezőnek nagybetűs karakterláncnak kell lennie'
        },
        number: {
            min: 'A mező nem lehet kisebb, mint {{param}}',
            max: 'A mező nem lehet nagyobb, mint {{param}}',
            lessThan: 'A mezőnek kevesebbnek kell lennie: {{param}}',
            moreThan: 'A mezőnek nagyobbnak kell lennie, mint {{param}}',
            notEqual: 'A mezőnek nem szabad egyenlőnek lennie: {{param}}',
            positive: 'A mezőnek pozitív számnak kell lennie',
            negative: 'A mezőnek negatív számnak kell lennie',
            integer: 'A mezőnek egész számnak kell lennie',
            number: 'A mezőnek számnak kell lennie'
        },
        date: {
            min: 'A mezőnek későbbinek kell lennie, mint {{param}}',
            max: 'A mezőnek korábbinak kell lennie, mint {{param}}'
        },
        object: {
            noUnknown: 'A mezőnek nincs meghatározva kulcsai: {{param}}'
        },
        array: {
            min: 'A mezőnek legalább {{param}} elemmel kell rendelkeznie',
            max: 'A mezőnek legfeljebb {{param}} elemnek kell lennie'
        }
    }
};

export default resources;
