import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { usePromiseTracker } from 'react-promise-tracker';
import { useTranslation } from 'react-i18next';
import EditForm from '~/pages/User/Edit/components/EditForm';
import UserService from '~/services/user-service';
import { User } from '~/types/services/user-service';
import ServiceEnums from '~/types/shared/service-enums';
import ToastrService from '~/services/toastr-service';
import setFieldBackendError from '~/shared/helpers/backend-responses';
import { Group } from '~/types/services/permission-service';

const UserServiceInstance = new UserService();

const UserEditPage: React.FC = () => {
    const { t } = useTranslation();
    const { id: userId } = useParams();
    const [user, setUser] = useState<User>();
    const [groups, setGroups] = useState<Group[]>([]);
    const history = useHistory();
    const { promiseInProgress } = usePromiseTracker({
        area: ServiceEnums.Auth
    });

    useEffect(() => {
        UserServiceInstance.fetchUser(userId)
            .then(userRes => setUser(userRes.data))
            .then(() => UserServiceInstance.fetchUserGroups(userId))
            .then(groupsRes => setGroups(groupsRes))
            .catch(() => {
                ToastrService.error(t('pages.users.edit.notFound'));
                history.push('/admin/users');
            });
    }, [history, userId]); // eslint-disable-line react-hooks/exhaustive-deps

    const updateUser = (values, helpers): void => {
        UserServiceInstance.updateUser(userId, values)
            .then(() => {
                ToastrService.success(t('pages.users.edit.successSave'));
                history.push('/admin/users');
            })
            .catch(e => {
                if (e.response.status === 422) {
                    setFieldBackendError(e.response.data, helpers);
                } else {
                    ToastrService.error(t('pages.users.edit.failedSave'));
                }
            });
    };

    const requestPasswordReset = (email): void => {
        UserServiceInstance.requestPasswordReset(email)
            .then(() => {
                ToastrService.success(
                    t('pages.authentication.lostPassword.successTitle'),
                    t('pages.authentication.lostPassword.successMessage')
                );
            })
            .catch(() => {
                ToastrService.error(t('basic.xhrError'));
            });
    };

    return (
        <>
            <h1>{t('pages.users.edit.title')}</h1>
            <EditForm
                onSubmit={updateUser}
                requestPasswordReset={requestPasswordReset}
                user={user}
                groups={groups}
                loading={promiseInProgress}
            />
        </>
    );
};

export default UserEditPage;
