import React, { ReactElement, useEffect, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import Spinner from '~/components/Spinner';
import EditForm from '~/components/EditForm';
import MasterDataSelect from '~/components/MasterDataSelect';
import ServiceEnums from '~/types/shared/service-enums';
import { MasterData } from '~/types/services/masterdata-service';
import FormBuilder from '~/containers/FormBuilder';
import { Claim } from '~/types/services/claim-service';
import EditFormSchema from './EditForm.validation';
import PylonTable from '~/components/PylonTable';
import SapClientNumber from '~/components/SapClientNumber';
import CaseNumberField from '~/components/CaseNumberField';
import SapClientModulesBuilderFieldNames from '~/types/shared/sap-client-modules-field-names';
import PylonValidationTable from '~/pages/Claim/Edit/components/PylonValidationTable';
import PylonFilesEnums from '~/types/shared/pylon-files-enums';
import MasterDataEnums from '~/types/shared/master-data-enums';

interface EditFormProps {
    onSubmit: (values, helpers: FormikHelpers<[]>) => void;
    claim: Claim;
    isEditing: boolean;
    loading: boolean;
    isViewing: boolean;
}

const ClaimEditForm: React.FC<EditFormProps> = ({
    onSubmit,
    claim,
    loading,
    isEditing,
    isViewing
}) => {
    const { t } = useTranslation();
    const initialAcceptedPylons = get(claim, 'claim_accepted_pylons', []);
    const initialValidatedPylons = get(claim, 'claim_validated_pylons', []);
    const [acceptedPylons, setAcceptedPylonsData] = useState(
        initialAcceptedPylons
    );
    const [validatedPylons, setValidatedPylonsData] = useState(
        initialValidatedPylons
    );
    const [sapClientFields, setSapClientFields] = useState([]);
    const [sapClientValue, setSapClientValue] = useState(false);
    useEffect(() => {
        if (initialAcceptedPylons.length) {
            setAcceptedPylonsData(initialAcceptedPylons);
        }
    }, [initialAcceptedPylons]);
    const buttonItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 }
    };

    if (!claim && isEditing) return <Spinner />;

    const initialStatus = get(claim, 'status') as MasterData;
    const initialType = get(claim, 'company') as MasterData;
    const initialCaseNumber = get(claim, 'business_case_number');
    const setPylons = (key, data): void => {
        if (key === PylonFilesEnums.AcceptedPylonFiles) {
            setAcceptedPylonsData(data);
        }
        if (key === PylonFilesEnums.ValidatedPylonFiles) {
            setValidatedPylonsData(data);
        }
    };
    const getBuilderFields = (fields): void => {
        setSapClientFields(fields);
    };
    const sapClientHasValue = (hasValue): void => {
        setSapClientValue(hasValue);
    };

    return (
        <Formik
            validationSchema={EditFormSchema}
            validateOnBlur
            onSubmit={onSubmit}
            initialValues={{
                status: initialStatus && {
                    key: initialStatus.id,
                    value: initialStatus.id,
                    label: initialStatus.label
                },
                company: initialType && {
                    key: initialType.id,
                    value: initialType.id,
                    label: initialType.label
                },
                business_case_number: initialCaseNumber && [
                    {
                        value: initialCaseNumber.id,
                        label: initialCaseNumber.label
                    }
                ],
                sap_client_number: get(claim, 'sap_client_number', null),
                builder: get(claim, 'builder', {}),
                claim_accepted_pylons: initialAcceptedPylons || acceptedPylons,
                claim_validated_pylons:
                    initialValidatedPylons || validatedPylons
            }}
        >
            {({
                values,
                initialValues,
                handleChange,
                handleBlur
            }): ReactElement => (
                <EditForm>
                    <CaseNumberField
                        disabled={isViewing || isEditing}
                        disableLiveSearch
                    />
                    <FormBuilder
                        formKey="claim_form_registration_number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isViewing}
                    />
                    <SapClientNumber
                        name="sap_client_number"
                        label={t('pages.content.fields.sap_client_number')}
                        placeholder={t(
                            'pages.content.fields.sap_client_number'
                        )}
                        disabled={isViewing}
                        searchable
                        initialValue={initialValues.sap_client_number}
                        moduleSapClientFields={sapClientFields}
                        setFieldEnums={SapClientModulesBuilderFieldNames.claim}
                        getSapClientHasValue={sapClientHasValue}
                    />
                    <FormBuilder
                        formKey="claim_form_basics"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isViewing}
                        getBuilderFields={getBuilderFields}
                        sapClientHasValue={sapClientValue}
                    />
                    <MasterDataSelect
                        required
                        module={ServiceEnums.Content}
                        placeholder={t('pages.content.edit.selectStatus')}
                        type={MasterDataEnums.DocumentEditableStatus}
                        name="status"
                        label={t('pages.content.fields.status')}
                        isFormik
                        disabled={isViewing}
                        initialKey={MasterDataEnums.ContentStatusInProgress}
                        isCreate={!isViewing && !isEditing}
                    />
                    <FormBuilder
                        formKey="claim_form_document_file"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isViewing}
                    />
                    <MasterDataSelect
                        required
                        module={ServiceEnums.Content}
                        placeholder={t('pages.content.edit.company')}
                        type="document_company"
                        name="company"
                        label={t('pages.content.fields.company')}
                        isFormik
                        disabled={isViewing}
                    />
                    <FormBuilder
                        formKey="claim_form_pole_files"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isViewing}
                        getFileResponse={setPylons}
                    />
                    <PylonValidationTable
                        pylons={values.claim_validated_pylons}
                    />
                    <PylonTable
                        pylons={values.claim_accepted_pylons}
                        setPylonsData={setPylons}
                        disabled={isViewing}
                    />
                    {!isViewing && (
                        <Form.Item name="submit" {...buttonItemLayout}>
                            <SubmitButton type="primary" loading={loading}>
                                {t('basic.saveButton')}
                            </SubmitButton>
                        </Form.Item>
                    )}
                </EditForm>
            )}
        </Formik>
    );
};

export default ClaimEditForm;
