import BaseService from '~/services/base-service';
import ServiceEnums from '~/types/shared/service-enums';
import { ApiResponse, PaginatedResponse } from '~/types/services/common';
import { Invoice } from '~/types/services/invoice-service';

export default class InvoiceService extends BaseService {
    constructor() {
        super(ServiceEnums.Invoice);
    }

    fetchInvoices(
        filter: object,
        sort: object,
        page: number
    ): Promise<PaginatedResponse<Invoice>> {
        return this.getRequest(``, {
            filter,
            sort,
            page
        });
    }

    fetchInvoice(id: string | number): Promise<ApiResponse<Invoice>> {
        return this.getRequest(`${id}`);
    }

    createInvoice<T>(data: Invoice): Promise<T> {
        return this.postRequest(``, data);
    }

    updateInvoice<T>(id: string | number, data: Invoice): Promise<T> {
        return this.putRequest(`${id}`, data);
    }

    exportInvoices(filter: object, sort: object): void {
        this.download('export', {
            filter,
            sort
        });
    }

    deleteInvoices<T>(invoiceIds: string[] | number[]): Promise<T> {
        return this.deleteRequest(``, {
            invoices: invoiceIds
        });
    }
}
