enum PylonFieldNamesEnums {
    ClaimAcceptedPylons = 'claim_accepted_pylons',
    ClaimValidatedPylons = 'claim_validated_pylons',
    PlanningPylonsField = 'pylons',
    DeletionPylonsField = 'pylons',
    TranscriptionPylonsField = 'pylons',
    ExpansionPylonsField = 'pylons'
}

export default PylonFieldNamesEnums;
