import BaseService from '~/services/base-service';
import ServiceEnums from '~/types/shared/service-enums';
import { ApiResponse, PaginatedResponse } from '~/types/services/common';
import { Expansion } from '~/types/services/expansion-service';

export default class ExpansionService extends BaseService {
    constructor() {
        super(ServiceEnums.Expansion);
    }

    fetchExpansions(
        filter: object,
        sort: object,
        page: number
    ): Promise<PaginatedResponse<Expansion>> {
        return this.getRequest(``, {
            filter,
            sort,
            page
        });
    }

    fetchExpansion(id: string | number): Promise<ApiResponse<Expansion>> {
        return this.getRequest(`${id}`);
    }

    updateExpansion<T>(id: string | number, data: Expansion): Promise<T> {
        return this.putRequest(`${id}`, data);
    }

    createExpansion<T>(data: Expansion): Promise<T> {
        return this.postRequest(``, data);
    }

    deleteExpansions<T>(expansionIds: string[] | number[]): Promise<T> {
        return this.deleteRequest(``, {
            expansions: expansionIds
        });
    }

    exportExpansions(filter: object, sort: object): void {
        this.download('export', {
            filter,
            sort
        });
    }

    async downloadAnnex(id: string | number): Promise<void> {
        return this.download(`annex/${id}`).catch(error => {
            return error.response.data.text().then(text => {
                throw JSON.parse(text);
            });
        });
    }

    sendBillingEmail<T>(id: string): Promise<T> {
        return this.getRequest(`billing-email/${id}`);
    }
}
