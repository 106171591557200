import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface ModuleLinkProps {
    url: string;
    recordId: number;
}

const ShowModuleLink: React.FC<ModuleLinkProps> = ({ url, recordId }) => {
    const { t } = useTranslation();

    return (
        <Link to={`${url}/view/${recordId}`}>
            <Button>{t('basic.viewButton')}</Button>
        </Link>
    );
};

export default ShowModuleLink;
