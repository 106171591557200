import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { usePromiseTracker } from 'react-promise-tracker';
import ServiceEnums from '~/types/shared/service-enums';
import { ApiResponse } from '~/types/services/common';
import ToastrService from '~/services/toastr-service';
import setFieldBackendError from '~/shared/helpers/backend-responses';
import OtherDocumentService from '~/services/other-document-service';
import { OtherDocument } from '~/types/services/other-document';
import OtherDocumentEditForm from '~/pages/OtherDocument/Edit/components/OtherDocumentEditForm';

const otherDocumentService = new OtherDocumentService();

const OtherDocumentEditPage: React.FC = () => {
    const { t } = useTranslation();
    const { id: otherDocumentId } = useParams();
    const [otherDocument, setOtherDocument] = useState<OtherDocument>();
    const history = useHistory();
    const { promiseInProgress } = usePromiseTracker({
        area: ServiceEnums.OtherDocument
    });

    const isEditing = otherDocumentId !== 'new';
    const isViewing = history.location.pathname.indexOf('view') !== -1;

    useEffect(() => {
        if (!isEditing) return;
        const promise = (): Promise<ApiResponse<OtherDocument>> =>
            otherDocumentService.fetchOtherDocument(otherDocumentId);

        promise()
            .then(res => setOtherDocument(res.data))
            .catch(() => {
                ToastrService.error(
                    t('pages.content.edit.otherDocumentNotFound')
                );
                history.push('/content/egyeb-dokumentumok');
            });
    }, [history, otherDocumentId, isEditing]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = (values, helpers): void => {
        if (!isViewing) {
            const promise = (): Promise<unknown> =>
                isEditing
                    ? otherDocumentService.updateOtherDocument(
                          otherDocumentId,
                          values
                      )
                    : otherDocumentService.createOtherDocument(values);

            promise()
                .then(() => {
                    ToastrService.success(
                        t('pages.content.edit.otherDocumentSuccessSave')
                    );
                })
                .catch(e => {
                    if (e.response.status === 422) {
                        setFieldBackendError(e.response.data, helpers);
                    } else {
                        ToastrService.error(
                            t('pages.content.edit.otherDocumentFailedSave')
                        );
                    }
                });
        }
    };

    const action = isViewing
        ? t('pages.content.edit.otherDocumentViewTitle')
        : t(
              isEditing
                  ? 'pages.content.edit.otherDocumentEditTitle'
                  : 'pages.content.edit.otherDocumentCreateTitle'
          );

    return (
        <>
            <h1>{action}</h1>
            <OtherDocumentEditForm
                onSubmit={handleSubmit}
                loading={promiseInProgress}
                otherDocument={otherDocument}
                isEditing={isEditing}
                isViewing={isViewing}
            />
        </>
    );
};

export default OtherDocumentEditPage;
