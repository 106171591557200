import React from 'react';
import styled from 'styled-components';
import Title from '~/components/Title';

const HomeDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
`;

const Home: React.FC = () => {
    return (
        <HomeDiv>
            <Title type="header">
                Üdvözöljük az Eszközbérlet nyilvántartóban.
            </Title>
            <p>
                Amennyiben nem látja a megfelelő menüpontot, keresse fel
                adminisztrátor kollégáját.
            </p>
        </HomeDiv>
    );
};

export default Home;
