import React from 'react';
import { Form, Input } from 'formik-antd';
import { FormikHandlers, useFormikContext } from 'formik';
import get from 'lodash/get';
import { FormBuilderField, TextMeta } from '~/types/services/form-service';
import { getFieldName } from '~/shared/utils/form-builder';
import sapClientFieldIsDisable from '~/shared/helpers/sap-client-field-is-disabled';

interface BuilderTextProps {
    field: FormBuilderField;
    onChange: FormikHandlers['handleChange'];
    onBlur: FormikHandlers['handleBlur'];
    disabled?: boolean;
    isArray?: boolean;
    arrayIndex?: number;
    sapClientHasValue?: boolean;
}

const BuilderText: React.FC<BuilderTextProps> = ({
    field,
    onChange,
    onBlur,
    disabled,
    isArray,
    arrayIndex,
    sapClientHasValue
}) => {
    const { getFieldMeta } = useFormikContext();

    const fieldName = isArray
        ? `${getFieldName(field.id)}.${arrayIndex}`
        : getFieldName(field.id);
    const fieldIsRequired = field.required ? '*' : '';
    const fieldLabel = `${fieldIsRequired} ${field.label}`;
    const meta = field.meta as TextMeta;
    const InputElement = meta.isPassword ? Input.Password : Input;
    const isSetSapClient = sapClientFieldIsDisable(
        sapClientHasValue,
        field.name
    );

    return (
        <Form.Item name={fieldName} label={fieldLabel} key={field.id}>
            <InputElement
                data-testid={fieldName}
                name={fieldName}
                placeholder={field.label}
                value={get(getFieldMeta(fieldName), 'value')}
                onChange={onChange}
                onBlur={onBlur}
                disabled={disabled || isSetSapClient}
            />
        </Form.Item>
    );
};

export default BuilderText;
