import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PermissionGate from '~/containers/PermissionGate';
import ActionButtonWrapper from '~/components/Table/components/ActionButtonWrapper';
import MasterDataEnums from '~/types/shared/master-data-enums';
import ConfiguratorEnums from '~/types/shared/configurator-enums';
import { Transcription } from '~/types/services/transcription-service';

interface ActionButtonsProps {
    record: Transcription;
    documentArchiveConfig: string;
    userPermissionNames: string[];
    showDeleteConfirm: (selectedRows) => void;
    url: string;
    routePermissionName: string;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({
    record,
    documentArchiveConfig,
    userPermissionNames,
    showDeleteConfirm,
    url,
    routePermissionName
}) => {
    const { t } = useTranslation();

    const isDeletable = (contentStatus): boolean =>
        (contentStatus === MasterDataEnums.ContentStatusArchived &&
            documentArchiveConfig ===
                ConfiguratorEnums.ContentArchiveAndDelete) ||
        documentArchiveConfig === ConfiguratorEnums.ContentOnlyDeletable;

    return (
        <ActionButtonWrapper>
            <PermissionGate
                permission={userPermissionNames}
                permissionRoute={routePermissionName}
            >
                {record.status && isDeletable(record.status.key) && (
                    <Button
                        danger
                        onClick={(): void => showDeleteConfirm([record.id])}
                    >
                        {t('basic.deleteButton')}
                    </Button>
                )}
                <Link to={`${url}/${record.id}`}>
                    <Button>{t('basic.editButton')}</Button>
                </Link>
            </PermissionGate>
            <Link to={`${url}/view/${record.id}`}>
                <Button>{t('basic.viewButton')}</Button>
            </Link>
        </ActionButtonWrapper>
    );
};

export default ActionButtons;
