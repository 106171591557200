import BaseService from '~/services/base-service';
import ServiceEnums from '~/types/shared/service-enums';
import { ApiResponse, PaginatedResponse } from '~/types/services/common';
import { Planning } from '~/types/services/planning-service';

export default class PlanningService extends BaseService {
    constructor() {
        super(ServiceEnums.Planning);
    }

    fetchPlannings(
        filter: object,
        sort: object,
        page: number
    ): Promise<PaginatedResponse<Planning>> {
        return this.getRequest(``, {
            filter,
            sort,
            page
        });
    }

    fetchPlanning(id: string | number): Promise<ApiResponse<Planning>> {
        return this.getRequest(`${id}`);
    }

    createPlanning<T>(data: Planning): Promise<T> {
        return this.postRequest(``, data);
    }

    updatePlanning<T>(id: string | number, data: Planning): Promise<T> {
        return this.putRequest(`${id}`, data);
    }

    exportPlannings(filter: object, sort: object): void {
        this.download('export', {
            filter,
            sort
        });
    }

    deletePlannings<T>(planningIds: string[] | number[]): Promise<T> {
        return this.deleteRequest(``, {
            plannings: planningIds
        });
    }

    downloadAnnex(id: string | number): Promise<void> {
        return this.download(`annex/${id}`).catch(error => {
            return error.response.data.text().then(text => {
                throw JSON.parse(text);
            });
        });
    }

    downloadPlanApproveDoc(id: number): void {
        this.download(`plan-approve/${id}`);
    }

    sendBillingEmail<T>(id: string): Promise<T> {
        return this.getRequest(`billing-email/${id}`);
    }

    sendWorkPlaceEmail<T>(id, arrayIndex: number): Promise<T> {
        return this.getRequest(`work-place-email/${id}`, { arrayIndex });
    }

    sendSuitableDoneEmail<T>(id: number): Promise<T> {
        return this.getRequest(`suitable-done/${id}`);
    }
}
