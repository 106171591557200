declare const process: {
    env: {
        SAP_CLIENT_NUMBER_DIGITS: number;
    };
};

export const getClientNumberMinValue = (): number => {
    return 10 ** (process.env.SAP_CLIENT_NUMBER_DIGITS - 1);
};

export const getClientNumberMaxValue = (): number => {
    return 10 ** process.env.SAP_CLIENT_NUMBER_DIGITS - 1;
};
