import React from 'react';
import { Form, Switch } from 'formik-antd';
import { useFormikContext } from 'formik';
import get from 'lodash/get';
import { FormBuilderField } from '~/types/services/form-service';
import { getFieldName } from '~/shared/utils/form-builder';
import sapClientFieldIsDisable from '~/shared/helpers/sap-client-field-is-disabled';

interface BuilderSwitchProps {
    field: FormBuilderField;
    disabled?: boolean;
    isArray?: boolean;
    arrayIndex?: number;
    sapClientHasValue?: boolean;
}

const BuilderSwitch: React.FC<BuilderSwitchProps> = ({
    field,
    disabled,
    isArray,
    arrayIndex,
    sapClientHasValue
}) => {
    const { getFieldMeta } = useFormikContext();
    const fieldName = isArray
        ? `${getFieldName(field.id)}.${arrayIndex}`
        : getFieldName(field.id);
    const fieldIsRequired = field.required ? '*' : '';
    const fieldLabel = `${fieldIsRequired} ${field.label}`;
    const isSetSapClient = sapClientFieldIsDisable(
        sapClientHasValue,
        field.name
    );

    return (
        <Form.Item name={fieldName} label={fieldLabel} key={field.id}>
            <Switch
                name={fieldName}
                data-testid={fieldName}
                checked={Boolean(get(getFieldMeta(fieldName), 'value', false))}
                disabled={disabled || isSetSapClient}
            />
        </Form.Item>
    );
};

export default BuilderSwitch;
