import get from 'lodash/get';
import SapClientBuilderFieldNameEnums from '~/types/shared/sap-client-builder-field-name-enums';
import PrinciplePermitBuilderFieldNameEnums from '~/types/shared/principle-permit-builder-field-name-enums';
import ClaimBuilderFieldNameEnums from '~/types/shared/claim-builder-field-name-enums';
import PlanningBuilderFieldNameEnums from '~/types/shared/planning-builder-field-name-enums';

export const getFieldName = (id): string => `builder.field-${id}`;

const getBuilderFieldByKey = (key): string => `builder.${key}`;

export const getFieldByName = (builderFieldNames, name): string => {
    return getBuilderFieldByKey(
        Object.keys(builderFieldNames).find(
            key => builderFieldNames[key] === name
        )
    );
};

export const setSapClientBuilderFields = (
    sapClient,
    moduleSapClientFields,
    setFieldEnums,
    setFieldValue
): void => {
    const sapClientNameField = getFieldByName(
        sapClient.builderFieldNames,
        SapClientBuilderFieldNameEnums.SapClientName
    );
    const sapClientAddressField = getFieldByName(
        sapClient.builderFieldNames,
        SapClientBuilderFieldNameEnums.SapClientAddress
    );
    const sapClientIsProblematic = getFieldByName(
        sapClient.builderFieldNames,
        SapClientBuilderFieldNameEnums.SapClientProblematic
    );
    const sapClientProblematicComment = getFieldByName(
        sapClient.builderFieldNames,
        SapClientBuilderFieldNameEnums.SapClientProblematicComment
    );
    const sapClientTaxNumber = getFieldByName(
        sapClient.builderFieldNames,
        SapClientBuilderFieldNameEnums.SapClientTaxNumber
    );

    moduleSapClientFields.forEach(clientField => {
        if (clientField.name === setFieldEnums.name) {
            setFieldValue(
                getFieldName(clientField.id),
                get(sapClient, sapClientNameField)
            );
        }

        if (clientField.name === setFieldEnums.address) {
            setFieldValue(
                getFieldName(clientField.id),
                get(sapClient, sapClientAddressField)
            );
        }

        if (clientField.name === setFieldEnums.isProblematic) {
            setFieldValue(
                getFieldName(clientField.id),
                get(sapClient, `${sapClientIsProblematic}.value`) === 1
                    ? get(sapClient, `${sapClientIsProblematic}.value`)
                    : 0
            );
        }

        if (clientField.name === setFieldEnums.problematicComment) {
            setFieldValue(
                getFieldName(clientField.id),
                get(sapClient, sapClientProblematicComment)
            );
        }

        if (clientField.name === setFieldEnums.taxNumber) {
            setFieldValue(
                getFieldName(clientField.id),
                get(sapClient, sapClientTaxNumber)
            );
        }
    });
};

export const setPermitFields = (setFieldValue, builderFields, claim): void => {
    const claimIktatoszamFieldValue = get(
        claim,
        getFieldByName(
            claim.builderFieldNames,
            ClaimBuilderFieldNameEnums.RegistrationNumber
        )
    );
    const permitIktatoszamField = getFieldName(
        builderFields.find(
            field =>
                field.name ===
                PrinciplePermitBuilderFieldNameEnums.RegistrationNumber
        ).id
    );
    if (claimIktatoszamFieldValue) {
        setFieldValue(permitIktatoszamField, claimIktatoszamFieldValue);
    }

    const claimIgenybejelentoNeveValue = get(
        claim,
        getFieldByName(
            claim.builderFieldNames,
            ClaimBuilderFieldNameEnums.ClaimRegistratorName
        )
    );
    const permitIgenybejelentoNeveField = getFieldName(
        builderFields.find(
            field =>
                field.name ===
                PrinciplePermitBuilderFieldNameEnums.RegistratorName
        ).id
    );
    if (claimIgenybejelentoNeveValue) {
        setFieldValue(
            permitIgenybejelentoNeveField,
            claimIgenybejelentoNeveValue
        );
    }

    const claimIgenybejelentoCimeValue = get(
        claim,
        getFieldByName(
            claim.builderFieldNames,
            ClaimBuilderFieldNameEnums.ClaimRegistratorAddress
        )
    );
    const permitIgenybejelentoCimeField = getFieldName(
        builderFields.find(
            field =>
                field.name ===
                PrinciplePermitBuilderFieldNameEnums.RegistratorAddress
        ).id
    );
    if (claimIgenybejelentoCimeValue) {
        setFieldValue(
            permitIgenybejelentoCimeField,
            claimIgenybejelentoCimeValue
        );
    }

    const claimUgyintezoNeveValue = get(
        claim,
        getFieldByName(
            claim.builderFieldNames,
            ClaimBuilderFieldNameEnums.CustomerAdministratorName
        )
    );
    const permitUgyintezoNeveField = getFieldName(
        builderFields.find(
            field =>
                field.name ===
                PrinciplePermitBuilderFieldNameEnums.CustomerAdministratorName
        ).id
    );
    if (claimUgyintezoNeveValue) {
        setFieldValue(permitUgyintezoNeveField, claimUgyintezoNeveValue);
    }

    const claimUgyintezoTelefonValue = get(
        claim,
        getFieldByName(
            claim.builderFieldNames,
            ClaimBuilderFieldNameEnums.CustomerAdministratorPhone
        )
    );
    const permitUgyintezoTelefonField = getFieldName(
        builderFields.find(
            field =>
                field.name ===
                PrinciplePermitBuilderFieldNameEnums.CustomerAdministratorPhone
        ).id
    );
    if (claimUgyintezoTelefonValue) {
        setFieldValue(permitUgyintezoTelefonField, claimUgyintezoTelefonValue);
    }

    const claimUgyintezoEmailValue = get(
        claim,
        getFieldByName(
            claim.builderFieldNames,
            ClaimBuilderFieldNameEnums.CustomerAdministratorEmail
        )
    );
    const permitUgyintezoEmailField = getFieldName(
        builderFields.find(
            field =>
                field.name ===
                PrinciplePermitBuilderFieldNameEnums.CustomerAdministratorEmail
        ).id
    );
    if (claimUgyintezoEmailValue) {
        setFieldValue(permitUgyintezoEmailField, claimUgyintezoEmailValue);
    }

    const claimLetesitesValue = get(
        claim,
        getFieldByName(
            claim.builderFieldNames,
            ClaimBuilderFieldNameEnums.Establishment
        )
    );
    const permitLetesitesField = getFieldName(
        builderFields.find(
            field =>
                field.name ===
                PrinciplePermitBuilderFieldNameEnums.Establishment
        ).id
    );
    if (claimLetesitesValue) {
        setFieldValue(permitLetesitesField, claimLetesitesValue);
    }

    setFieldValue('company', {
        value: claim.company.id,
        label: claim.company.label
    });

    setFieldValue('sap_client_number', claim.sap_client_number);
};

export const setPlanningFields = (
    setFieldValue,
    builderFields,
    claim
): void => {
    setFieldValue('sap_client_number', claim.sap_client_number);

    const claimIktatoszamFieldValue = get(
        claim,
        getFieldByName(
            claim.builderFieldNames,
            ClaimBuilderFieldNameEnums.RegistrationNumber
        )
    );
    const planningIktatoszamField = getFieldName(
        builderFields.find(
            field =>
                field.name === PlanningBuilderFieldNameEnums.RegistrationNumber
        ).id
    );
    if (claimIktatoszamFieldValue) {
        setFieldValue(planningIktatoszamField, claimIktatoszamFieldValue);
    }

    const claimIgenybejelentoNeveValue = get(
        claim,
        getFieldByName(
            claim.builderFieldNames,
            ClaimBuilderFieldNameEnums.ClaimRegistratorName
        )
    );
    const planningIgenybejelentoNeveField = getFieldName(
        builderFields.find(
            field =>
                field.name ===
                PlanningBuilderFieldNameEnums.PlanningRegistratorName
        ).id
    );
    if (claimIgenybejelentoNeveValue) {
        setFieldValue(
            planningIgenybejelentoNeveField,
            claimIgenybejelentoNeveValue
        );
    }

    const claimIgenybejelentoCimeValue = get(
        claim,
        getFieldByName(
            claim.builderFieldNames,
            ClaimBuilderFieldNameEnums.ClaimRegistratorAddress
        )
    );
    const planningIgenybejelentoCimeField = getFieldName(
        builderFields.find(
            field =>
                field.name ===
                PlanningBuilderFieldNameEnums.PlanningRegistratorAddress
        ).id
    );
    if (claimIgenybejelentoCimeValue) {
        setFieldValue(
            planningIgenybejelentoCimeField,
            claimIgenybejelentoCimeValue
        );
    }

    const claimLetesitesValue = get(
        claim,
        getFieldByName(
            claim.builderFieldNames,
            ClaimBuilderFieldNameEnums.Establishment
        )
    );
    const planningLetesitesField = getFieldName(
        builderFields.find(
            field => field.name === PlanningBuilderFieldNameEnums.Establishment
        ).id
    );
    if (claimLetesitesValue) {
        setFieldValue(planningLetesitesField, claimLetesitesValue);
    }

    setFieldValue('company', {
        value: claim.company.id,
        label: claim.company.label
    });
};
