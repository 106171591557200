/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { Form, Input, SubmitButton, Checkbox } from 'formik-antd';
import { Formik, FormikHelpers } from 'formik';
import qs from 'query-string';
import { useLocation, Redirect, Link } from 'react-router-dom';
import get from 'lodash/get';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ServiceEnums from '~/types/shared/service-enums';
import RegisterSchema from './RegisterForm.validation';
import EditForm from '~/components/EditForm';

const Text = styled.div`
    width: 100%;
    text-align: right;
    padding: 5px 5px;
`;

interface RegisterFormProps {
    onSubmit: (values, helpers: FormikHelpers<[]>) => void;
    inviteOnly: boolean;
}

const RegisterForm: React.FC<RegisterFormProps> = ({
    onSubmit,
    inviteOnly
}) => {
    const { t } = useTranslation();
    const location = useLocation();
    const query = qs.parse(location.search);
    const { promiseInProgress } = usePromiseTracker({
        area: ServiceEnums.Auth
    });

    if (inviteOnly && !get(query, 'token')) {
        return <Redirect to="/404" />;
    }

    return (
        <Formik
            validationSchema={RegisterSchema}
            onSubmit={onSubmit}
            initialValues={{
                firstName: '',
                lastName: '',
                userName: '',
                phone: '',
                email: get(query, 'email'),
                password: '',
                password_confirmation: '',
                gdpr: false,
                terms: false,
                inviteToken: get(query, 'token')
            }}
        >
            <EditForm disableformItemLayout>
                <Form.Item name="firstName" required>
                    <Input
                        name="firstName"
                        placeholder={t(
                            'pages.authentication.registration.firstName'
                        )}
                    />
                </Form.Item>
                <Form.Item name="lastName" required>
                    <Input
                        name="lastName"
                        placeholder={t(
                            'pages.authentication.registration.lastName'
                        )}
                    />
                </Form.Item>
                <Form.Item name="userName" required>
                    <Input
                        name="userName"
                        placeholder={t('basic.fields.username')}
                    />
                </Form.Item>
                <Form.Item name="phone" required>
                    <Input
                        name="phone"
                        placeholder={t(
                            'pages.authentication.registration.phoneNumber'
                        )}
                    />
                </Form.Item>
                <Form.Item name="email" required>
                    <Input
                        type="email"
                        name="email"
                        placeholder={t('basic.fields.email')}
                        disabled={inviteOnly}
                    />
                </Form.Item>
                <Form.Item name="password" required>
                    <Input.Password
                        name="password"
                        placeholder={t('basic.fields.password')}
                    />
                </Form.Item>
                <Form.Item name="password_confirmation" required>
                    <Input.Password
                        name="password_confirmation"
                        placeholder={t('basic.fields.passwordConfirmation')}
                    />
                </Form.Item>
                <Form.Item name="gdpr">
                    <Checkbox name="gdpr">
                        {t('pages.authentication.registration.gdpr')}
                    </Checkbox>
                </Form.Item>
                <Form.Item name="terms">
                    <Checkbox name="terms">
                        {t(
                            'pages.authentication.registration.termsAndCondition'
                        )}
                    </Checkbox>
                </Form.Item>
                <SubmitButton block loading={promiseInProgress}>
                    {t('pages.authentication.registration.register')}
                </SubmitButton>
                <Text>
                    <Link to="/auth/login">
                        {t('pages.authentication.login.backToLogin')}
                    </Link>
                </Text>
            </EditForm>
        </Formik>
    );
};

export default RegisterForm;
