/* eslint-disable @typescript-eslint/camelcase */
import Yup from '~/shared/utils/yup';

export default Yup.object().shape({
    first_name: Yup.string()
        .required('pages.users.validations.firstNameRequired')
        .min(2)
        .max(50),
    last_name: Yup.string()
        .required('pages.users.validations.lastNameRequired')
        .min(2)
        .max(50),
    phone: Yup.string()
        .min(2)
        .max(50),
    email: Yup.string()
        .required('basic.validations.requiredEmail')
        .email(),
    username: Yup.string().required('basic.validations.requiredUsername')
});
