enum PylonColumnsEnums {
    TartoAzonosito = 'tarto_azonosito',
    Halozat = 'halozat',
    IRSzam = 'irszam',
    Telepules = 'korzet_nev',
    Kozterulet = 'kozterulet',
    Hazszam = 'hazszamnev',
    Hrsz = 'hrsznev'
}

export default PylonColumnsEnums;
