import { AxiosPromise } from 'axios';
import BaseService from '~/services/base-service';
import ServiceEnums from '~/types/shared/service-enums';

export default class FileUploadService extends BaseService {
    constructor() {
        super(ServiceEnums.FileUpload);
    }

    fileUpload(
        file: File,
        groupKey: string,
        fieldId: string,
        name: string
    ): AxiosPromise {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('groupKey', groupKey);
        formData.append('fieldId', fieldId);
        formData.append(`builder[field-${fieldId}]`, name);
        return this.postRequest(``, formData);
    }

    pylonFileUpload(
        file: File,
        groupKey: string,
        fieldId: string,
        name: string
    ): AxiosPromise {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('groupKey', groupKey);
        formData.append('fieldId', fieldId);
        formData.append(`builder[field-${fieldId}]`, name);
        return this.postRequest(`pylons`, formData);
    }

    fileDownload(id: number): void {
        this.download(`${id}`);
    }
}
