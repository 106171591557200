const getDbSelectLink = (entity: string, id: string | number): string => {
    switch (entity) {
        case 'document':
            return `/content/documents/${id}`;
        default:
            return '#';
    }
};

export default getDbSelectLink;
