import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { usePromiseTracker } from 'react-promise-tracker';
import { useTranslation } from 'react-i18next';
import ServiceEnums from '~/types/shared/service-enums';
import ToastrService from '~/services/toastr-service';
import setFieldBackendError from '~/shared/helpers/backend-responses';
import SapInvoiceImportService from '~/services/sap-invoice-import-service';
import SapInvoiceImportEditForm from '~/pages/SapInvoiceImport/Edit/components/SapInvoiceImportEditForm';
import { SapInvoiceImport } from '~/types/services/sap-invoice-import-service';

const sapImportService = new SapInvoiceImportService();

const SapInvoiceImportEditPage: React.FC = () => {
    const { t } = useTranslation();
    const { id: sapInvoiceImportId } = useParams();
    const [sapInvoiceImport, setSapInvoiceImport] = useState<
        SapInvoiceImport
    >();
    const history = useHistory();
    const { promiseInProgress } = usePromiseTracker({
        area: ServiceEnums.SapInvoiceImport
    });

    const isViewing = history.location.pathname.indexOf('view') !== -1;

    useEffect(() => {
        if (!isViewing) return;

        sapImportService
            .fetchSapInvoiceImport(sapInvoiceImportId)
            .then(res => setSapInvoiceImport(res.data))
            .catch(() => {
                ToastrService.error(
                    t('pages.content.edit.sapInvoiceImportNotFound')
                );
                history.push('/content/sap-szamlak');
            });
    }, [history, sapInvoiceImportId, isViewing]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = (values, helpers): void => {
        if (!isViewing) {
            sapImportService
                .createSapInvoiceImport(values)
                .then(() => {
                    ToastrService.success(
                        t('pages.content.edit.sapInvoiceImportSuccessSave')
                    );
                })
                .catch(e => {
                    if (e.response.status === 422) {
                        setFieldBackendError(e.response.data, helpers);
                    } else {
                        ToastrService.error(
                            t('pages.content.edit.sapInvoiceImportFailedSave')
                        );
                    }
                });
        }
    };

    const action = isViewing
        ? t('pages.content.edit.sapInvoiceImportViewTitle')
        : t('pages.content.edit.sapInvoiceImportCreateTitle');

    return (
        <>
            <h1>{action}</h1>
            <SapInvoiceImportEditForm
                isViewing={isViewing}
                onSubmit={handleSubmit}
                sapInvoiceImport={sapInvoiceImport}
                loading={promiseInProgress}
            />
        </>
    );
};

export default SapInvoiceImportEditPage;
