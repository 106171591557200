enum ModulePaginateEnums {
    Claim = 'claim_page',
    Permit = 'permit_page',
    Planning = 'planning_page',
    Transcription = 'transcription_page',
    Deletion = 'deletion_page',
    Expansion = 'expansion_page'
}

export default ModulePaginateEnums;
