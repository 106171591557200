import ClaimBuilderFieldNameEnums from '~/types/shared/claim-builder-field-name-enums';
import PrinciplePermitBuilderFieldNameEnums from '~/types/shared/principle-permit-builder-field-name-enums';
import PlanningBuilderFieldNameEnums from '~/types/shared/planning-builder-field-name-enums';
import DeletionBuilderFieldNameEnums from '~/types/shared/deletion-builder-field-name-enums';
import ExpansionBuilderFieldNameEnums from '~/types/shared/expansion-builder-field-name-enums';
import OtherDocumentBuilderFieldNameEnums from '~/types/shared/other-document-builder-field-name-enums';
import LeaseBuilderFieldNameEnums from '~/types/shared/lease-builder-field-name-enums';
import InvoiceBuilderFieldNameEnums from '~/types/shared/invoice-builder-field-name-enums';
import TranscriptionBuilderFieldNameEnums from '~/types/shared/transcription-builder-field-name-enums';

const SapClientModulesBuilderFieldNames = {
    claim: {
        name: ClaimBuilderFieldNameEnums.ClaimRegistratorName,
        address: ClaimBuilderFieldNameEnums.ClaimRegistratorAddress
    },
    principlePermit: {
        name: PrinciplePermitBuilderFieldNameEnums.RegistratorName,
        address: PrinciplePermitBuilderFieldNameEnums.RegistratorAddress
    },
    planning: {
        name: PlanningBuilderFieldNameEnums.PlanningRegistratorName,
        address: PlanningBuilderFieldNameEnums.PlanningRegistratorAddress
    },
    deletion: {
        name: DeletionBuilderFieldNameEnums.CustomerName,
        address: DeletionBuilderFieldNameEnums.CustomerAddress,
        isProblematic: DeletionBuilderFieldNameEnums.CustomerIsProblematic,
        problematicComment:
            DeletionBuilderFieldNameEnums.CustomerProblematicComment
    },
    expansion: {
        name: ExpansionBuilderFieldNameEnums.CustomerName,
        address: ExpansionBuilderFieldNameEnums.CustomerAddress,
        isProblematic: ExpansionBuilderFieldNameEnums.CustomerIsProblematic,
        problematicComment:
            ExpansionBuilderFieldNameEnums.CustomerProblematicComment
    },
    otherDocument: {
        name: OtherDocumentBuilderFieldNameEnums.CustomerName,
        address: OtherDocumentBuilderFieldNameEnums.CustomerAddress
    },
    lease: {
        name: LeaseBuilderFieldNameEnums.LeaseName,
        address: LeaseBuilderFieldNameEnums.LeaseAddress,
        taxNumber: LeaseBuilderFieldNameEnums.LeaseTaxNumber
    },
    invoice: {
        name: InvoiceBuilderFieldNameEnums.InvoiceTenantName,
        address: InvoiceBuilderFieldNameEnums.InvoiceTenantAddress,
        isProblematic: InvoiceBuilderFieldNameEnums.InvoiceTenantIsProblematic,
        problematicComment:
            InvoiceBuilderFieldNameEnums.InvoiceTenantProblematicComment
    },
    transcription: {
        recipient: {
            name: TranscriptionBuilderFieldNameEnums.RecipientName,
            address: TranscriptionBuilderFieldNameEnums.RecipientAddress,
            isProblematic:
                TranscriptionBuilderFieldNameEnums.RecipientIsProblematic,
            problematicComment:
                TranscriptionBuilderFieldNameEnums.RecipientProblematicComment
        },
        delivering: {
            name: TranscriptionBuilderFieldNameEnums.DeliveringName,
            address: TranscriptionBuilderFieldNameEnums.DeliveringAddress,
            isProblematic:
                TranscriptionBuilderFieldNameEnums.DeliveringIsProblematic,
            problematicComment:
                TranscriptionBuilderFieldNameEnums.DeliveringProblematicComment
        }
    }
};

export default SapClientModulesBuilderFieldNames;
