import React, { useEffect, useState } from 'react';
import { Form } from 'formik-antd';
import { Button } from 'antd';
import { useFormikContext } from 'formik';
import { LabeledValue } from 'antd/es/select';
import { useTranslation } from 'react-i18next';
import get from 'lodash/get';
import BusinessCaseService from '~/services/business-case-service';
import AjaxSelect from '~/components/AjaxSelect';
import ClaimService from '~/services/claim-service';
import { FormBuilderField } from '~/types/services/form-service';

interface CaseNumberFieldProps {
    disabled?: boolean;
    disableGenerate?: boolean;
    disableLiveSearch?: boolean;
    builderFields?: FormBuilderField[];
    setFieldsByCaseNumber?: (setFieldValue, builderFields, data) => void;
}

const businessCaseService = new BusinessCaseService();
const claimService = new ClaimService();

const onSearch = (value): Promise<LabeledValue[]> =>
    businessCaseService.searchNumber(value).then(res =>
        res.data.map(caseNumber => ({
            value: caseNumber.id,
            label: caseNumber.case_number
        }))
    );

const CaseNumberField: React.FC<CaseNumberFieldProps> = ({
    disabled,
    disableGenerate,
    disableLiveSearch,
    builderFields,
    setFieldsByCaseNumber
}) => {
    const { setFieldValue, getFieldMeta } = useFormikContext();
    const { t } = useTranslation();
    const AjaxInputStyle = !disableGenerate
        ? { width: '50%', marginRight: '10px' }
        : { width: '50%' };
    const formItemStyle = !disableGenerate ? { height: '62px' } : {};
    const [value, setValue] = useState([]);
    const name = 'business_case_number';
    const label = t('pages.content.fields.business_case_number');
    const buttonName = t('pages.content.fields.generate');

    const generate = (): void => {
        businessCaseService.generateCaseNumber().then(res => {
            setFieldValue(name, [
                {
                    value: res.data.id,
                    label: res.data.case_number
                }
            ]);
        });
    };

    useEffect(() => {
        const values = get(getFieldMeta(name), 'value', []);

        setValue(
            values
                ? values.map(caseNumber => ({
                      key: caseNumber.value,
                      value: caseNumber.value,
                      label: caseNumber.label
                  }))
                : []
        );
    }, [get(getFieldMeta(name), 'value')]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleCaseNumberSelect = (caseNumber): void => {
        claimService.fetchClaimByCaseNumber(caseNumber).then(res => {
            if (res.data && setFieldsByCaseNumber) {
                setFieldsByCaseNumber(setFieldValue, builderFields, res.data);
            }
        });
    };

    return (
        <Form.Item name={name} label={label} style={formItemStyle} required>
            <AjaxSelect
                data-testid={name}
                placeholder={label}
                disabled={disabled || disableLiveSearch}
                value={value}
                id={name}
                onSearch={onSearch}
                onSelect={(res): void => {
                    setFieldValue(name, [res]);
                    if (builderFields) {
                        handleCaseNumberSelect(res.label);
                    }
                }}
                onDeselect={(): void => {
                    setFieldValue(name, undefined);
                }}
                style={AjaxInputStyle}
                multiple={false}
                key={get(value, 'key', 0)}
            />
            {!disableGenerate && (
                <Button
                    onClick={generate}
                    disabled={disabled}
                    data-testid="generate-business-case"
                >
                    {buttonName}
                </Button>
            )}
        </Form.Item>
    );
};

export default CaseNumberField;
