import React, { useEffect, useState } from 'react';
import CheckboxGroup, { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Checkbox, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import ModuleEnums from '~/types/shared/module-enums';

interface TableSelectorProps {
    onChange: (list: CheckboxValueType[]) => void;
    defaultCheckedList?: CheckboxValueType[];
}

const TableSelector: React.FC<TableSelectorProps> = ({
    onChange,
    defaultCheckedList
}) => {
    const { t } = useTranslation();

    const tableOptions = [
        {
            label: t('pages.content.list.claimList'),
            value: ModuleEnums.Claim
        },
        {
            label: t('pages.content.list.principlePermitList'),
            value: ModuleEnums.Permit
        },
        {
            label: t('pages.content.list.planningList'),
            value: ModuleEnums.Planning
        },
        {
            label: t('pages.content.list.transcriptionList'),
            value: ModuleEnums.Transcription
        },
        {
            label: t('pages.content.list.deletionList'),
            value: ModuleEnums.Deletion
        },
        {
            label: t('pages.content.list.expansionList'),
            value: ModuleEnums.Expansion
        }
    ];

    const [checkedList, setCheckedList] = useState<CheckboxValueType[]>(
        defaultCheckedList
    );

    useEffect(() => {
        onChange(checkedList);
    }, [checkedList, onChange]);

    const checkAll = tableOptions.length === checkedList.length;
    const indeterminate =
        checkedList.length > 0 && checkedList.length < tableOptions.length;

    const onCheckChange = (list: CheckboxValueType[]): void => {
        setCheckedList(list);
    };

    const onCheckAllChange = (e: CheckboxChangeEvent): void => {
        setCheckedList(
            e.target.checked
                ? tableOptions.reduce((valueArray, option) => {
                      valueArray.push(option.value);
                      return valueArray;
                  }, [])
                : []
        );
    };

    return (
        <Space direction="vertical">
            <Checkbox
                className="mb-2"
                name="All"
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={checkAll}
            >
                {t('basic.selectAll')}
            </Checkbox>
            <CheckboxGroup
                options={tableOptions}
                value={checkedList}
                onChange={onCheckChange}
            />
        </Space>
    );
};

export default TableSelector;
