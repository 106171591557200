import React, { useEffect, useState } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { useTranslation } from 'react-i18next';
import EditForm from '~/pages/MasterData/Edit/components/EditForm';
import ToastrService from '~/services/toastr-service';
import MasterDataService from '~/services/masterdata-service';
import { MasterData } from '~/types/services/masterdata-service';
import setFieldBackendError from '~/shared/helpers/backend-responses';

interface MasterDataEditPageProps {
    handleClose: () => void;
    masterDataId: number;
}

const MasterDataServiceInstance = new MasterDataService();

const MasterDataEditPage: React.FC<MasterDataEditPageProps> = ({
    masterDataId,
    handleClose
}) => {
    const { t } = useTranslation();
    const [masterData, setMasterData] = useState<MasterData>();
    const area = 'master-data-item';
    const { promiseInProgress } = usePromiseTracker({
        area
    });

    useEffect(() => {
        if (masterDataId) {
            MasterDataServiceInstance.fetchMasterData(masterDataId, area)
                .then(res => setMasterData(res.data))
                .catch(() => {
                    ToastrService.error(t('pages.masterData.edit.notFound'));
                    handleClose();
                });
            return;
        }
        setMasterData(null);
    }, [handleClose, masterDataId]); // eslint-disable-line react-hooks/exhaustive-deps

    const updateMasterData = (values, helpers): void => {
        MasterDataServiceInstance.updateMasterData(masterDataId, values)
            .then(() => {
                ToastrService.success(t('pages.masterData.edit.successSave'));
                handleClose();
            })
            .catch(e => {
                if (e.response.status === 422) {
                    setFieldBackendError(e.response.data, helpers);
                } else {
                    ToastrService.error(t('pages.masterData.edit.failedSave'));
                }
            });
    };

    return (
        <EditForm
            onSubmit={updateMasterData}
            masterData={masterData}
            loading={promiseInProgress}
        />
    );
};

export default MasterDataEditPage;
