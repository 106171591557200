import find from 'lodash/find';
import SapClientModulesBuilderFieldNames from '~/types/shared/sap-client-modules-field-names';

const isSapClientField = (fieldName, fieldNameEnums): boolean => {
    return !!find(fieldNameEnums, fields => {
        if (Object.values(fields).some(element => element instanceof Object)) {
            return isSapClientField(fieldName, fields);
        }
        return Object.values(fields).includes(fieldName);
    });
};

const sapClientFieldIsDisable = (value, fieldName): boolean => {
    if (!value) return false;
    return isSapClientField(fieldName, SapClientModulesBuilderFieldNames);
};

export default sapClientFieldIsDisable;
