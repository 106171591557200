import React, { ReactElement, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { Form, SubmitButton } from 'formik-antd';
import Spinner from '~/components/Spinner';
import { MasterData } from '~/types/services/masterdata-service';
import EditForm from '~/components/EditForm';
import FormBuilder from '~/containers/FormBuilder';
import ServiceEnums from '~/types/shared/service-enums';
import MasterDataSelect from '~/components/MasterDataSelect';
import EditFormSchema from './EditForm.validation';
import { OtherDocument } from '~/types/services/other-document';
import SapClientNumber from '~/components/SapClientNumber';
import CaseNumberField from '~/components/CaseNumberField';
import SapClientModulesBuilderFieldNames from '~/types/shared/sap-client-modules-field-names';
import MasterDataEnums from '~/types/shared/master-data-enums';

interface OtherDocumentFormProps {
    onSubmit: (values, helpers: FormikHelpers<[]>) => void;
    otherDocument: OtherDocument;
    isEditing: boolean;
    loading: boolean;
    isViewing: boolean;
}

const OtherDocumentEditForm: React.FC<OtherDocumentFormProps> = ({
    onSubmit,
    otherDocument,
    loading,
    isEditing,
    isViewing
}) => {
    const { t } = useTranslation();
    const [sapClientFields, setSapClientFields] = useState([]);
    const [sapClientValue, setSapClientValue] = useState(false);
    const buttonItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 }
    };

    if (!otherDocument && isEditing) return <Spinner />;
    const initialStatus = get(otherDocument, 'status') as MasterData;
    const initialCaseNumber = get(otherDocument, 'business_case_number');
    const getBuilderFields = (fields): void => {
        setSapClientFields(fields);
    };
    const sapClientHasValue = (hasValue): void => {
        setSapClientValue(hasValue);
    };

    return (
        <Formik
            validationSchema={EditFormSchema}
            validateOnBlur
            onSubmit={onSubmit}
            initialValues={{
                status: initialStatus && {
                    key: initialStatus.id,
                    value: initialStatus.id,
                    label: initialStatus.label
                },
                business_case_number: initialCaseNumber && [
                    {
                        value: initialCaseNumber.id,
                        label: initialCaseNumber.label
                    }
                ],
                sap_client_number: get(
                    otherDocument,
                    'sap_client_number',
                    null
                ),
                builder: get(otherDocument, 'builder', {})
            }}
        >
            {({ handleChange, handleBlur, initialValues }): ReactElement => (
                <EditForm>
                    <CaseNumberField disabled={isViewing || isEditing} />
                    <SapClientNumber
                        name="sap_client_number"
                        label={t('pages.content.fields.sap_client_number')}
                        placeholder={t(
                            'pages.content.fields.sap_client_number'
                        )}
                        disabled={isViewing}
                        searchable
                        initialValue={initialValues.sap_client_number}
                        moduleSapClientFields={sapClientFields}
                        setFieldEnums={
                            SapClientModulesBuilderFieldNames.otherDocument
                        }
                        getSapClientHasValue={sapClientHasValue}
                    />
                    <FormBuilder
                        formKey="other_document_form"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isViewing}
                        getBuilderFields={getBuilderFields}
                        sapClientHasValue={sapClientValue}
                    />
                    <MasterDataSelect
                        required
                        module={ServiceEnums.Content}
                        placeholder={t('pages.content.edit.selectStatus')}
                        type={MasterDataEnums.DocumentEditableStatus}
                        name="status"
                        label={t('pages.content.fields.status')}
                        isFormik
                        disabled={isViewing}
                        initialKey={MasterDataEnums.ContentStatusInProgress}
                        isCreate={!isViewing && !isEditing}
                    />
                    {!isViewing && (
                        <Form.Item name="submit" {...buttonItemLayout}>
                            <SubmitButton type="primary" loading={loading}>
                                {t('basic.saveButton')}
                            </SubmitButton>
                        </Form.Item>
                    )}
                </EditForm>
            )}
        </Formik>
    );
};

export default OtherDocumentEditForm;
