/* eslint-disable @typescript-eslint/camelcase */
import Yup from '~/shared/utils/yup';
import {
    getClientNumberMaxValue,
    getClientNumberMinValue
} from '~/shared/helpers/sap-client-number';

export default Yup.object().shape({
    sap_client_number: Yup.number()
        .required()
        .min(getClientNumberMinValue())
        .max(getClientNumberMaxValue())
        .typeError('validations.number.number'),
    company: Yup.object().required('pages.content.validations.requiredType')
});
