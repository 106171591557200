import { LabeledValue } from 'antd/es/select';
import BaseService from '~/services/base-service';
import ServiceEnums from '~/types/shared/service-enums';
import { ApiResponse, PaginatedResponse } from '~/types/services/common';
import { Pylon } from '~/types/services/pylon-interface';

export default class PylonService extends BaseService {
    constructor() {
        super(ServiceEnums.Pylon);
    }

    fetchClientPylons(
        filter: object,
        sort: object,
        page: number
    ): Promise<PaginatedResponse<Pylon>> {
        return this.getRequest(``, {
            filter,
            sort,
            page
        });
    }

    exportPylons(filter: object, sort: object): void {
        this.download('export', {
            filter,
            sort
        });
    }

    fetchClientPylonsZipCodes(
        sapClientId,
        zipCode
    ): Promise<ApiResponse<LabeledValue[]>> {
        return this.getRequest(`${sapClientId}/zip-codes`, { zipCode });
    }
}
