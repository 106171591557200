import BaseService from '~/services/base-service';
import ServiceEnums from '~/types/shared/service-enums';
import { ApiResponse, PaginatedResponse } from '~/types/services/common';
import { OtherDocument } from '~/types/services/other-document';

export default class OtherDocumentService extends BaseService {
    constructor() {
        super(ServiceEnums.OtherDocument);
    }

    fetchOtherDocuments(
        filter: object,
        sort: object,
        page: number
    ): Promise<PaginatedResponse<OtherDocument>> {
        return this.getRequest(``, {
            filter,
            sort,
            page
        });
    }

    fetchOtherDocument(
        id: string | number
    ): Promise<ApiResponse<OtherDocument>> {
        return this.getRequest(`${id}`);
    }

    updateOtherDocument<T>(
        id: string | number,
        data: OtherDocument
    ): Promise<T> {
        return this.putRequest(`${id}`, data);
    }

    createOtherDocument<T>(data: OtherDocument): Promise<T> {
        return this.postRequest(``, data);
    }

    deleteOtherDocuments<T>(otherDocumentIds: string[] | number[]): Promise<T> {
        return this.deleteRequest(``, {
            otherDocuments: otherDocumentIds
        });
    }

    exportOtherDocuments(filter: object, sort: object): void {
        this.download('export', {
            filter,
            sort
        });
    }
}
