enum ServiceEnums {
    Auth = 'auth',
    Account = 'accounts',
    FeatureGate = 'features',
    Notification = 'notification',
    User = 'user',
    Permission = 'permission',
    Configurator = 'configurator',
    MasterData = 'master-data',
    Content = 'content',
    Taxonomy = 'taxonomy',
    FileUpload = 'files',
    FormBuilder = 'forms',
    Claim = 'claims',
    Transcription = 'transcriptions',
    BusinessCase = 'business-cases',
    PrinciplePermit = 'principle-permits',
    Planning = 'plannings',
    Lease = 'leases',
    Deletion = 'deletions',
    Expansion = 'expansions',
    SapClient = 'sap-clients',
    SapClientImport = 'sap-client-import',
    SapInvoiceImport = 'sap-invoice-import',
    OtherDocument = 'other-documents',
    Invoice = 'invoices',
    Pylon = 'pylons'
}

export default ServiceEnums;
