import React from 'react';
import {
    FormBuilderField,
    TaxonomySelectMeta
} from '~/types/services/form-service';
import MasterDataSelect from '~/components/MasterDataSelect';
import { getFieldName } from '~/shared/utils/form-builder';

interface BuilderSelectProps {
    field: FormBuilderField;
    disabled?: boolean;
    isArray?: boolean;
    arrayIndex?: number;
}

const BuilderMasterDataSelect: React.FC<BuilderSelectProps> = ({
    field,
    disabled,
    isArray,
    arrayIndex
}) => {
    const meta = field.meta as TaxonomySelectMeta;
    const fieldName = isArray
        ? `${getFieldName(field.id)}.${arrayIndex}`
        : getFieldName(field.id);
    const fieldIsRequired = field.required ? '*' : '';
    const fieldLabel = `${fieldIsRequired} ${field.label}`;

    return (
        <MasterDataSelect
            mode={meta.multiSelect ? 'multiple' : null}
            placeholder={field.label}
            module={meta.module}
            type={meta.type}
            name={fieldName}
            label={fieldLabel}
            disabled={disabled}
            isFormik
        />
    );
};

export default BuilderMasterDataSelect;
