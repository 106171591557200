import { Form } from 'formik-antd';
import React from 'react';
import { LabeledValue, SelectValue } from 'antd/es/select';
import AjaxSelect from '~/components/AjaxSelect';
import UserService from '~/services/user-service';
import i18n from '~/shared/utils/i18n';

const userService = new UserService();

const onSearch = (value): Promise<LabeledValue[]> =>
    userService.fetchUsers({ first_name: value }, {}, 0).then(data =>
        data.data.map(res => ({
            value: res.id,
            label: `${res.first_name} ${res.last_name}`
        }))
    );

interface MemberSelectorProps {
    name: string;
    label: string;
    setFieldValue: Function;
    data: LabeledValue[];
    initialValues: SelectValue;
}

const MemberSelector: React.FC<MemberSelectorProps> = ({
    name,
    label,
    setFieldValue,
    data,
    initialValues
}) => (
    <Form.Item name={name} label={label}>
        <AjaxSelect
            id={name}
            placeholder={i18n.t('basic.selectWithLabel', { label })}
            initialValues={initialValues}
            onSearch={onSearch}
            onSelect={(res): void => {
                setFieldValue(name, [...data, res.value]);
            }}
            onDeselect={(res): void => {
                setFieldValue(
                    name,
                    data.filter(role => role !== res.value)
                );
            }}
        />
    </Form.Item>
);

export default MemberSelector;
