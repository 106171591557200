enum ClaimBuilderFieldNameEnums {
    RegistrationNumber = 'claim_registration_number',
    ClaimRegistratorName = 'claim_registrator_name',
    ClaimRegistratorAddress = 'claim_registrator_address',
    CustomerAdministratorName = 'claim_customer_administrator_name',
    CustomerAdministratorPhone = 'claim_customer_administrator_phone',
    CustomerAdministratorEmail = 'claim_customer_administrator_email',
    Establishment = 'claim_permit_of_establishment'
}

export default ClaimBuilderFieldNameEnums;
