import BaseService from '~/services/base-service';
import ServiceEnums from '~/types/shared/service-enums';
import { ApiResponse, PaginatedResponse } from '~/types/services/common';
import { SapInvoiceImport } from '~/types/services/sap-invoice-import-service';

export default class SapInvoiceImportService extends BaseService {
    constructor() {
        super(ServiceEnums.SapInvoiceImport);
    }

    fetchSapInvoiceImports(
        filter: object,
        sort: object,
        page: number
    ): Promise<PaginatedResponse<SapInvoiceImport>> {
        return this.getRequest(``, {
            filter,
            sort,
            page
        });
    }

    fetchSapInvoiceImport(
        id: string | number
    ): Promise<ApiResponse<SapInvoiceImport>> {
        return this.getRequest(`${id}`);
    }

    createSapInvoiceImport<T>(data: SapInvoiceImport): Promise<T> {
        return this.postRequest(``, data);
    }

    exportSapInvoiceImports(filter: object, sort: object): void {
        this.download('export', {
            filter,
            sort
        });
    }
}
