import { FormikHelpers } from 'formik';
import _ from 'lodash';

const setFieldBackendError = (data, helpers: FormikHelpers<[]>): void => {
    _.forIn(data.errors, (errorMessages, fieldKey: string): void => {
        helpers.setFieldError(fieldKey, errorMessages[0]);
    });
};

export default setFieldBackendError;
