import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { usePromiseTracker } from 'react-promise-tracker';
import PrinciplePermitEditForm from '~/pages/PrinciplePermit/Edit/components/PrinciplePermitEditForm';
import ServiceEnums from '~/types/shared/service-enums';
import ToastrService from '~/services/toastr-service';
import setFieldBackendError from '~/shared/helpers/backend-responses';
import PrinciplePermitService from '~/services/principle-permit-service';
import { PrinciplePermit } from '~/types/services/principle-permit';
import { ApiResponse } from '~/types/services/common';

const principlePermitService = new PrinciplePermitService();

const PrinciplePermitEditPage: React.FC = () => {
    const { t } = useTranslation();
    const { id: permitId } = useParams();
    const [permit, setPermit] = useState<PrinciplePermit>();
    const history = useHistory();
    const { promiseInProgress } = usePromiseTracker({
        area: ServiceEnums.PrinciplePermit
    });

    const isEditing = permitId !== 'new';
    const isViewing = history.location.pathname.indexOf('view') !== -1;

    useEffect(() => {
        if (!isEditing) return;
        const promise = (): Promise<ApiResponse<PrinciplePermit>> =>
            principlePermitService.fetchPrinciplePermit(permitId);

        promise()
            .then(res => setPermit(res.data))
            .catch(() => {
                ToastrService.error(t('pages.content.edit.permitNotFound'));
                history.push('/content/elvi-engedelyek');
            });
    }, [history, permitId, isEditing]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = (values, helpers): void => {
        if (!isViewing) {
            const promise = (): Promise<unknown> =>
                isEditing
                    ? principlePermitService.updatePrinciplePermit(
                          permitId,
                          values
                      )
                    : principlePermitService.createPrinciplePermit(values);

            promise()
                .then(() => {
                    ToastrService.success(
                        t('pages.content.edit.permitSuccessSave')
                    );
                })
                .catch(e => {
                    if (e.response.status === 422) {
                        setFieldBackendError(e.response.data, helpers);
                    } else {
                        ToastrService.error(
                            t('pages.content.edit.permitFailedSave')
                        );
                    }
                });
        }
    };

    const getDispositionFile = (): void => {
        if (permitId) {
            principlePermitService.downloadDispositionFile(permitId);
        }
    };

    const action = isViewing
        ? t('pages.content.edit.permitViewTitle')
        : t(
              isEditing
                  ? 'pages.content.edit.permitEditTitle'
                  : 'pages.content.edit.permitCreateTitle'
          );
    return (
        <>
            <h1>{action}</h1>
            <PrinciplePermitEditForm
                onSubmit={handleSubmit}
                isEditing={isEditing}
                isViewing={isViewing}
                loading={promiseInProgress}
                principlePermit={permit}
                getDispositionFile={getDispositionFile}
            />
        </>
    );
};

export default PrinciplePermitEditPage;
