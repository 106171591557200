/* eslint-disable @typescript-eslint/camelcase */
import Yup from '~/shared/utils/yup';
import PasswordRules from '~/shared/validation/password-rules';

export default Yup.object().shape({
    firstName: Yup.string()
        .min(2)
        .max(50)
        .required(),
    lastName: Yup.string()
        .min(2)
        .max(50)
        .required(),
    userName: Yup.string()
        .min(2)
        .max(50)
        .required(),
    phone: Yup.string()
        .min(2)
        .max(50)
        .required(),
    email: Yup.string()
        .email()
        .required('basic.validations.requiredEmail'),
    password: PasswordRules.required('basic.validations.requiredPassword'),
    password_confirmation: Yup.string().oneOf(
        [Yup.ref('password'), null],
        'basic.validations.passwordMustMatch'
    ),
    gdpr: Yup.boolean().oneOf(
        [true],
        'pages.authentication.registration.validations.requiredGdpr'
    ),
    terms: Yup.boolean().oneOf(
        [true],
        'pages.authentication.registration.validations.requiredTermsAndCondition'
    )
});
