import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { usePromiseTracker } from 'react-promise-tracker';
import { useTranslation } from 'react-i18next';
import ServiceEnums from '~/types/shared/service-enums';
import ToastrService from '~/services/toastr-service';
import setFieldBackendError from '~/shared/helpers/backend-responses';
import { ApiResponse } from '~/types/services/common';
import LeaseService from '~/services/lease-service';
import { Lease } from '~/types/services/lease-service';
import LeaseEditForm from '~/pages/Lease/Edit/components/LeaseEditForm';

const leaseService = new LeaseService();

const LeaseEditPage: React.FC = () => {
    const { t } = useTranslation();
    const { id: leaseId } = useParams();
    const [lease, setLease] = useState<Lease>();
    const history = useHistory();
    const { promiseInProgress } = usePromiseTracker({
        area: ServiceEnums.Lease
    });

    const isEditing = leaseId !== 'new';
    const isViewing = history.location.pathname.indexOf('view') !== -1;

    useEffect(() => {
        if (!isEditing) return;
        const promise = (): Promise<ApiResponse<Lease>> =>
            leaseService.fetchLease(leaseId);

        promise()
            .then(res => setLease(res.data))
            .catch(() => {
                ToastrService.error(t('pages.content.edit.leaseNotFound'));
                history.push('/content/berleti-szerzodesek');
            });
    }, [history, leaseId, isEditing]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = (values, helpers): void => {
        if (!isViewing) {
            const { download, ...postValues } = values;

            const promise = (): Promise<ApiResponse<Lease>> =>
                isEditing
                    ? leaseService.updateLease(leaseId, postValues)
                    : leaseService.createLease(postValues);

            promise()
                .then(res => {
                    ToastrService.success(
                        t('pages.content.edit.leaseSuccessSave')
                    );
                    if (download) {
                        leaseService.downloadGeneratedFile(res.data.id);
                    }
                })
                .catch(e => {
                    if (e.response.status === 422) {
                        setFieldBackendError(e.response.data, helpers);
                    } else {
                        ToastrService.error(
                            t('pages.content.edit.leaseFailedSave')
                        );
                    }
                });
        }
    };

    const action = isViewing
        ? t('pages.content.edit.leaseViewTitle')
        : t(
              isEditing
                  ? 'pages.content.edit.leaseEditTitle'
                  : 'pages.content.edit.leaseCreateTitle'
          );

    return (
        <>
            <h1>{action}</h1>
            <LeaseEditForm
                isEditing={isEditing}
                isViewing={isViewing}
                onSubmit={handleSubmit}
                lease={lease}
                loading={promiseInProgress}
            />
        </>
    );
};

export default LeaseEditPage;
