import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Title from '~/components/Title';

const LinkListWrapper = styled.div`
    > * + * {
        margin-left: 8px;
    }
    margin-bottom: 24px;
`;

interface ListFilterButtonsProps {
    sapClientNumber: number;
}

const SapClientListPageFilterButtons: React.FC<ListFilterButtonsProps> = ({
    sapClientNumber
}) => {
    if (!sapClientNumber) return null;

    return (
        <>
            <Title type="secondary">Vevőhöz kapcsolódó ügyek</Title>
            <LinkListWrapper>
                <Link
                    to={`/content/igenybejelentesek?sapClientNumber=${sapClientNumber}`}
                >
                    <Button>Igénybejelentések</Button>
                </Link>
                <Link
                    to={`/content/elvi-engedelyek?sapClientNumber=${sapClientNumber}`}
                >
                    <Button>Elvi engedélyek</Button>
                </Link>
                <Link
                    to={`/content/tervezes?sapClientNumber=${sapClientNumber}`}
                >
                    <Button>Tervezés/Kivitelezés</Button>
                </Link>
                <Link
                    to={`/content/atirasok?sapClientNumber=${sapClientNumber}`}
                >
                    <Button>Átírások</Button>
                </Link>
                <Link
                    to={`/content/torlesek?sapClientNumber=${sapClientNumber}`}
                >
                    <Button>Törlések</Button>
                </Link>
                <Link
                    to={`/content/bovitesek?sapClientNumber=${sapClientNumber}`}
                >
                    <Button>Bővítések</Button>
                </Link>
                <Link
                    to={`/content/berleti-szerzodesek?sapClientNumber=${sapClientNumber}`}
                >
                    <Button>Bérleti szerződések</Button>
                </Link>
            </LinkListWrapper>
        </>
    );
};

export default SapClientListPageFilterButtons;
