import React, { useContext, useState } from 'react';
import { Typography } from 'antd';
import { usePromiseTracker } from 'react-promise-tracker';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ServiceEnums from '~/types/shared/service-enums';
import Table from '~/components/Table';
import { ActionProps } from '~/components/Table/types/ActionProps';
import { PermissionContext } from '~/shared/context/permission';
import SapInvoiceImportService from '~/services/sap-invoice-import-service';
import { SapInvoiceImport } from '~/types/services/sap-invoice-import-service';
import columns from '~/pages/SapInvoiceImport/List/columns';

const sapInvoiceImportService = new SapInvoiceImportService();

const SapInvoiceImportListPage: React.FC = () => {
    const { t } = useTranslation();
    const { push } = useHistory();
    const { promiseInProgress: sapInvoiceImportLoading } = usePromiseTracker({
        area: ServiceEnums.SapInvoiceImport
    });
    const [sapInvoiceImports, setSapInvoiceImports] = useState<
        SapInvoiceImport[]
    >([]);
    const [total, setTotal] = useState(0);

    const { permissions, userHasPermission } = useContext(PermissionContext);
    const userPermissionNames =
        permissions && permissions.map(permission => permission.name);

    const fetchSapInvoiceImports = (
        filters: object = {},
        orders: object = {},
        page = 1
    ): void => {
        sapInvoiceImportService
            .fetchSapInvoiceImports(filters, orders, page)
            .then(res => {
                setSapInvoiceImports(res.data);
                setTotal(res.total);
            });
    };

    const exportSapInvoiceImports = (
        selectedRows: number[],
        filters: object = {},
        orders: object = {}
    ): void => {
        sapInvoiceImportService.exportSapInvoiceImports(filters, orders);
    };

    const actions: ActionProps[] = [
        {
            label: t('basic.exportButton'),
            type: 'ghost',
            onClick: exportSapInvoiceImports
        },
        {
            label: t('basic.addNewButton'),
            onClick: (): void => push('/content/sap-szamlak/new')
        }
    ];

    const cols = columns(t);

    return (
        <div>
            <Typography.Title level={1}>
                {t('pages.content.list.sapInvoiceImport')}
            </Typography.Title>
            <Table
                rowKey="id"
                loading={sapInvoiceImportLoading}
                columns={cols}
                dataSource={sapInvoiceImports}
                actions={
                    userHasPermission(
                        userPermissionNames,
                        'sap.szamla.import.manage'
                    )
                        ? actions
                        : []
                }
                fetchSource={fetchSapInvoiceImports}
                total={total}
            />
        </div>
    );
};

export default SapInvoiceImportListPage;
