import React, { ReactElement } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Form, Input, SubmitButton, Switch } from 'formik-antd';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import Spinner from '~/components/Spinner';
import EditForm from '~/components/EditForm';
import EditFormSchema from './EditForm.validation';
import {
    Group,
    GroupMember,
    GroupRole
} from '~/types/services/permission-service';
import RoleSelector from './RoleSelector';
import MemberSelector from './MemberSelector';

interface EditFormProps {
    onSubmit: (values, helpers: FormikHelpers<[]>) => void;
    group: Group;
    roles: GroupRole[];
    members: GroupMember[];
    isEditing: boolean;
    loading: boolean;
}

const GroupEditForm: React.FC<EditFormProps> = ({
    onSubmit,
    group,
    roles,
    members,
    loading,
    isEditing
}) => {
    const { t } = useTranslation();

    const buttonItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 }
    };

    if ((!group || !roles || !members) && isEditing) return <Spinner />;

    const allMemberArray = members || [];
    const ownerArray = allMemberArray.filter(member => member.is_admin);
    const memberArray = allMemberArray.filter(member => !member.is_admin);

    const initialMembers = memberArray.map(member => ({
        value: member.id,
        label: member.name
    }));
    const initialMemberIds = memberArray.map(member => member.id);

    const initialOwners = ownerArray.map(member => ({
        value: member.id,
        label: member.name
    }));
    const initialOwnerIds = ownerArray.map(member => member.id);

    const roleArray = roles || [];
    const initialRoles = roleArray.map(role => ({
        value: role.id,
        label: role.name
    }));
    const initialRoleIds = roleArray.map(role => role.id);

    return (
        <Formik
            validationSchema={EditFormSchema}
            validateOnBlur
            onSubmit={onSubmit}
            initialValues={{
                name: get(group, 'name', ''),
                description: get(group, 'description', ''),
                owners: initialOwnerIds,
                members: initialMemberIds,
                roles: initialRoleIds,
                invoice_notification: get(group, 'invoice_notification', false),
                eegis_notification: get(group, 'eegis_notification', false),
                bo_notification: get(group, 'bo_notification', false)
            }}
        >
            {({ values, setFieldValue }): ReactElement => (
                <EditForm>
                    <Form.Item
                        name="name"
                        label={t('pages.permissions.groups.fields.name')}
                        required
                    >
                        <Input
                            name="name"
                            placeholder={t(
                                'pages.permissions.groups.fields.name'
                            )}
                        />
                    </Form.Item>
                    <Form.Item
                        name="description"
                        label={t('pages.permissions.groups.fields.description')}
                    >
                        <Input.TextArea
                            name="description"
                            placeholder={t(
                                'pages.permissions.groups.fields.description'
                            )}
                        />
                    </Form.Item>
                    <MemberSelector
                        name="owners"
                        label={t('pages.permissions.groups.fields.owners')}
                        setFieldValue={setFieldValue}
                        initialValues={initialOwners}
                        data={values.owners}
                    />
                    <MemberSelector
                        name="members"
                        label={t('pages.permissions.groups.fields.members')}
                        setFieldValue={setFieldValue}
                        initialValues={initialMembers}
                        data={values.members}
                    />
                    <RoleSelector
                        name="roles"
                        label={t('pages.permissions.groups.fields.roles')}
                        setFieldValue={setFieldValue}
                        initialValues={initialRoles}
                        data={values.roles}
                    />
                    <Form.Item
                        name="invoice_notification"
                        label={t(
                            'pages.permissions.groups.fields.invoiceNotification'
                        )}
                    >
                        <Switch name="invoice_notification" />
                    </Form.Item>
                    <Form.Item
                        name="eegis_notification"
                        label={t(
                            'pages.permissions.groups.fields.eegisNotification'
                        )}
                    >
                        <Switch name="eegis_notification" />
                    </Form.Item>
                    <Form.Item
                        name="bo_notification"
                        label={t(
                            'pages.permissions.groups.fields.boNotification'
                        )}
                    >
                        <Switch name="bo_notification" />
                    </Form.Item>
                    <Form.Item name="submit" {...buttonItemLayout}>
                        <SubmitButton type="primary" loading={loading}>
                            {t('basic.saveButton')}
                        </SubmitButton>
                    </Form.Item>
                </EditForm>
            )}
        </Formik>
    );
};

export default GroupEditForm;
