import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { usePromiseTracker } from 'react-promise-tracker';
import { useTranslation } from 'react-i18next';
import ServiceEnums from '~/types/shared/service-enums';
import ToastrService from '~/services/toastr-service';
import setFieldBackendError from '~/shared/helpers/backend-responses';
import { ApiResponse } from '~/types/services/common';
import ClaimService from '~/services/claim-service';
import { Claim } from '~/types/services/claim-service';
import ClaimEditForm from '~/pages/Claim/Edit/components/ClaimEditForm';

const claimService = new ClaimService();

const ClaimEditPage: React.FC = () => {
    const { t } = useTranslation();
    const { id: claimId } = useParams();
    const [claim, setClaim] = useState<Claim>();
    const history = useHistory();
    const { promiseInProgress } = usePromiseTracker({
        area: ServiceEnums.Claim
    });

    const isEditing = claimId !== 'new';
    const isViewing = history.location.pathname.indexOf('view') !== -1;

    useEffect(() => {
        if (!isEditing) return;
        const promise = (): Promise<ApiResponse<Claim>> =>
            claimService.fetchClaim(claimId);

        promise()
            .then(res => setClaim(res.data))
            .catch(() => {
                ToastrService.error(t('pages.content.edit.claimNotFound'));
                history.push('/content/igenybejelentesek');
            });
    }, [history, claimId, isEditing]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = (values, helpers): void => {
        if (!isViewing) {
            const promise = (): Promise<unknown> =>
                isEditing
                    ? claimService.updateClaim(claimId, values)
                    : claimService.createClaim(values);

            promise()
                .then(() => {
                    ToastrService.success(
                        t('pages.content.edit.claimSuccessSave')
                    );
                })
                .catch(e => {
                    if (e.response.status === 422) {
                        setFieldBackendError(e.response.data, helpers);
                        if (e.response.data.errors.pylons) {
                            ToastrService.error(e.response.data.errors.pylons);
                        }
                    } else {
                        ToastrService.error(
                            t('pages.content.edit.claimFailedSave')
                        );
                    }
                });
        }
    };

    const action = isViewing
        ? t('pages.content.edit.claimViewTitle')
        : t(
              isEditing
                  ? 'pages.content.edit.claimEditTitle'
                  : 'pages.content.edit.claimCreateTitle'
          );

    return (
        <>
            <h1>{action}</h1>
            <ClaimEditForm
                isEditing={isEditing}
                isViewing={isViewing}
                onSubmit={handleSubmit}
                claim={claim}
                loading={promiseInProgress}
            />
        </>
    );
};

export default ClaimEditPage;
