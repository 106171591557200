enum SapClientBuilderFieldNameEnums {
    SapClientNumber = 'sap_client_number',
    SapClientName = 'sap_client_name',
    SapClientAddress = 'sap_client_address',
    SapClientTaxNumber = 'sap_client_tax_number',
    SapClientProblematic = 'sap_client_problematic',
    SapClientProblematicComment = 'sap_client_problematic_label'
}

export default SapClientBuilderFieldNameEnums;
