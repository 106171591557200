import BaseService from '~/services/base-service';
import ServiceEnums from '~/types/shared/service-enums';
import { ApiResponse, PaginatedResponse } from '~/types/services/common';
import { Deletion } from '~/types/services/deletion';

export default class DeletionService extends BaseService {
    constructor() {
        super(ServiceEnums.Deletion);
    }

    fetchDeletions(
        filter: object,
        sort: object,
        page: number
    ): Promise<PaginatedResponse<Deletion>> {
        return this.getRequest(``, {
            filter,
            sort,
            page
        });
    }

    fetchDeletion(id: string | number): Promise<ApiResponse<Deletion>> {
        return this.getRequest(`${id}`);
    }

    updateDeletion<T>(id: string | number, data: Deletion): Promise<T> {
        return this.putRequest(`${id}`, data);
    }

    createDeletion<T>(data: Deletion): Promise<T> {
        return this.postRequest(``, data);
    }

    deleteDeletions<T>(deletionIds: string[] | number[]): Promise<T> {
        return this.deleteRequest(``, {
            deletions: deletionIds
        });
    }

    exportDeletions(filter: object, sort: object): void {
        this.download('export', {
            filter,
            sort
        });
    }

    downloadAnnex(id: string | number): Promise<void> {
        return this.download(`annex/${id}`).catch(error => {
            return error.response.data.text().then(text => {
                throw JSON.parse(text);
            });
        });
    }

    sendBillingEmail<T>(id: string): Promise<T> {
        return this.getRequest(`billing-email/${id}`);
    }
}
