import React, { ReactElement, useContext } from 'react';
import { Form, DatePicker } from 'formik-antd';
import get from 'lodash/get';
import moment from 'moment';
import { useFormikContext } from 'formik';
import {
    DatePickerMeta,
    FormBuilderField
} from '~/types/services/form-service';
import { LanguageContext } from '~/shared/context/language';
import { getFieldName } from '~/shared/utils/form-builder';

interface BuilderTextProps {
    field: FormBuilderField;
    disabled?: boolean;
    isArray?: boolean;
    arrayIndex?: number;
}

const BuilderDatePicker: React.FC<BuilderTextProps> = ({
    field,
    disabled,
    isArray,
    arrayIndex
}) => {
    const { getFieldMeta } = useFormikContext();
    const { selectedLanguage } = useContext(LanguageContext);
    const fieldName = isArray
        ? `${getFieldName(field.id)}.${arrayIndex}`
        : getFieldName(field.id);
    const fieldIsRequired = field.required ? '*' : '';
    const fieldLabel = `${fieldIsRequired} ${field.label}`;
    const meta = field.meta as DatePickerMeta;

    const getDisabledDate = (current): boolean =>
        moment(current).isBefore(meta.minDate || '') ||
        moment(current).isAfter(meta.maxDate || '');

    const initialValue = get(getFieldMeta(fieldName), 'value');

    const hoursMinutes = { format: 'HH:mm' };

    const pickerProps = {
        'data-testid': fieldName,
        name: fieldName,
        showTime: meta.timePicker ? hoursMinutes : null,
        disabledDate: getDisabledDate
    };

    const getPicker = (): ReactElement =>
        meta.isRangePicker ? (
            <DatePicker.RangePicker
                value={
                    initialValue && [
                        moment(get(initialValue, '0')),
                        moment(get(initialValue, '1'))
                    ]
                }
                locale={selectedLanguage.datePicker}
                disabled={disabled}
                {...pickerProps}
            />
        ) : (
            <DatePicker
                defaultValue={initialValue && moment(initialValue)}
                placeholder={field.label}
                locale={selectedLanguage.datePicker}
                disabled={disabled}
                {...pickerProps}
            />
        );
    return (
        <Form.Item name={fieldName} label={fieldLabel}>
            {getPicker()}
        </Form.Item>
    );
};

export default BuilderDatePicker;
