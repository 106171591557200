import React from 'react';
import { Form, Input } from 'formik-antd';
import { FormikHandlers, useFormikContext } from 'formik';
import get from 'lodash/get';
import { FormBuilderField, TextMeta } from '~/types/services/form-service';
import { getFieldName } from '~/shared/utils/form-builder';

interface BuilderTextProps {
    field: FormBuilderField;
    onChange: FormikHandlers['handleChange'];
    onBlur: FormikHandlers['handleBlur'];
    disabled?: boolean;
    isArray?: boolean;
    arrayIndex?: number;
}

const BuilderTextArea: React.FC<BuilderTextProps> = ({
    field,
    onChange,
    onBlur,
    disabled,
    isArray,
    arrayIndex
}) => {
    const { getFieldMeta } = useFormikContext();
    const fieldName = isArray
        ? `${getFieldName(field.id)}.${arrayIndex}`
        : getFieldName(field.id);
    const fieldIsRequired = field.required ? '*' : '';
    const fieldLabel = `${fieldIsRequired} ${field.label}`;
    const meta = field.meta as TextMeta;
    const InputElement = meta.isPassword ? Input.Password : Input.TextArea;
    const size = { minRows: 4, maxRows: 12 };
    return (
        <Form.Item name={fieldName} label={fieldLabel} key={field.id}>
            <InputElement
                autoSize={size}
                data-testid={fieldName}
                name={fieldName}
                placeholder={field.label}
                value={get(getFieldMeta(fieldName), 'value')}
                onChange={onChange}
                onBlur={onBlur}
                disabled={disabled}
            />
        </Form.Item>
    );
};

export default BuilderTextArea;
