import BaseService from '~/services/base-service';
import ServiceEnums from '~/types/shared/service-enums';
import { ApiResponse, PaginatedResponse } from '~/types/services/common';
import { Lease } from '~/types/services/lease-service';

export default class LeaseService extends BaseService {
    constructor() {
        super(ServiceEnums.Lease);
    }

    fetchLeases(
        filter: object,
        sort: object,
        page: number
    ): Promise<PaginatedResponse<Lease>> {
        return this.getRequest(``, {
            filter,
            sort,
            page
        });
    }

    fetchLease(id: string | number): Promise<ApiResponse<Lease>> {
        return this.getRequest(`${id}`);
    }

    createLease<T>(data: Lease): Promise<ApiResponse<Lease>> {
        return this.postRequest(``, data);
    }

    updateLease<T>(
        id: string | number,
        data: Lease
    ): Promise<ApiResponse<Lease>> {
        return this.putRequest(`${id}`, data);
    }

    exportLeases(filter: object, sort: object): void {
        this.download('export', {
            filter,
            sort
        });
    }

    deleteLeases<T>(leasesIds: string[] | number[]): Promise<T> {
        return this.deleteRequest(``, {
            leases: leasesIds
        });
    }

    downloadGeneratedFile(id: string | number): void {
        this.download(`downloadGeneratedLeaseFile/${id}`);
    }
}
