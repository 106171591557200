import React, { ReactElement, useEffect, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { Form, SubmitButton } from 'formik-antd';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { Deletion } from '~/types/services/deletion';
import Spinner from '~/components/Spinner';
import { MasterData } from '~/types/services/masterdata-service';
import EditForm from '~/components/EditForm';
import FormBuilder from '~/containers/FormBuilder';
import ServiceEnums from '~/types/shared/service-enums';
import MasterDataSelect from '~/components/MasterDataSelect';
import EditFormSchema from './EditForm.validation';
import SapClientNumber from '~/components/SapClientNumber';
import CaseNumberField from '~/components/CaseNumberField';
import SapClientModulesBuilderFieldNames from '~/types/shared/sap-client-modules-field-names';
import MasterDataEnums from '~/types/shared/master-data-enums';
import ActionButtonWrapper from '~/components/Table/components/ActionButtonWrapper';
import PylonTable from '~/components/PylonTable';

interface DeletionFormProps {
    onSubmit: (values, helpers: FormikHelpers<[]>) => void;
    onGenerateAnnex: () => void;
    onSendBillingEmail: () => void;
    deletion: Deletion;
    isEditing: boolean;
    loading: boolean;
    isViewing: boolean;
}

const DeletionsEditForm: React.FC<DeletionFormProps> = ({
    onSubmit,
    onGenerateAnnex,
    onSendBillingEmail,
    deletion,
    loading,
    isEditing,
    isViewing
}) => {
    const { t } = useTranslation();
    const [sapClientFields, setSapClientFields] = useState([]);
    const [sapClientValue, setSapClientValue] = useState(false);
    const initialPylons = get(deletion, 'pylons', []);
    const [pylons, setPylonsData] = useState(initialPylons);
    const buttonItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 }
    };

    useEffect(() => {
        if (initialPylons.length) {
            setPylonsData(initialPylons);
        }
    }, [initialPylons]);

    if (!deletion && isEditing) return <Spinner />;
    const initialStatus = get(deletion, 'status') as MasterData;
    const initialType = get(deletion, 'company') as MasterData;
    const initialCaseNumber = get(deletion, 'business_case_number');
    const getBuilderFields = (fields): void => {
        setSapClientFields(fields);
    };
    const sapClientHasValue = (hasValue): void => {
        setSapClientValue(hasValue);
    };
    const setPylons = (key, data): void => {
        setPylonsData(data);
    };

    return (
        <Formik
            validationSchema={EditFormSchema}
            validateOnBlur
            onSubmit={onSubmit}
            initialValues={{
                status: initialStatus && {
                    key: initialStatus.id,
                    value: initialStatus.id,
                    label: initialStatus.label
                },
                business_case_number: initialCaseNumber && [
                    {
                        value: initialCaseNumber.id,
                        label: initialCaseNumber.label
                    }
                ],
                sap_client_number: get(deletion, 'sap_client_number', null),
                company: initialType && {
                    key: initialType.id,
                    value: initialType.id,
                    label: initialType.label
                },
                builder: get(deletion, 'builder', {}),
                pylons: initialPylons || pylons
            }}
        >
            {({
                values,
                handleChange,
                handleBlur,
                initialValues,
                isValid
            }): ReactElement => (
                <EditForm>
                    <CaseNumberField
                        disabled={isViewing || isEditing}
                        disableLiveSearch
                    />
                    <SapClientNumber
                        name="sap_client_number"
                        label={t('pages.content.fields.sap_client_number')}
                        placeholder={t(
                            'pages.content.fields.sap_client_number'
                        )}
                        disabled={isViewing}
                        searchable
                        initialValue={initialValues.sap_client_number}
                        moduleSapClientFields={sapClientFields}
                        setFieldEnums={
                            SapClientModulesBuilderFieldNames.deletion
                        }
                        getSapClientHasValue={sapClientHasValue}
                        required
                    />
                    <FormBuilder
                        formKey="deletion_form_customer_renter_doc"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isViewing}
                        getBuilderFields={getBuilderFields}
                        sapClientHasValue={sapClientValue}
                    />
                    <MasterDataSelect
                        required
                        module={ServiceEnums.Content}
                        placeholder={t('pages.content.edit.selectStatus')}
                        type={MasterDataEnums.DocumentEditableStatus}
                        name="status"
                        label={t('pages.content.fields.status')}
                        isFormik
                        disabled={isViewing}
                        initialKey={MasterDataEnums.ContentStatusInProgress}
                        isCreate={!isViewing && !isEditing}
                    />
                    <MasterDataSelect
                        required
                        module={ServiceEnums.Content}
                        placeholder={t('pages.content.edit.company')}
                        type="document_company"
                        name="company"
                        label={t('pages.content.fields.company')}
                        isFormik
                        disabled={isViewing}
                    />
                    <FormBuilder
                        formKey="deletion_form_date_pickers_files"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isViewing}
                        getFileResponse={setPylons}
                    />
                    <PylonTable
                        pylons={values.pylons}
                        setPylonsData={setPylons}
                        disabled={isViewing}
                        paymentTypeNeed={false}
                    />
                    <Form.Item name="submit" {...buttonItemLayout}>
                        <ActionButtonWrapper>
                            {!isViewing && (
                                <SubmitButton type="primary" loading={loading}>
                                    {t('basic.saveButton')}
                                </SubmitButton>
                            )}
                            {isEditing && (
                                <>
                                    <Button
                                        loading={loading}
                                        icon={<DownloadOutlined />}
                                        onClick={onGenerateAnnex}
                                    >
                                        {t('pages.content.edit.annexButton')}
                                    </Button>
                                    <Button
                                        disabled={!isValid}
                                        loading={loading}
                                        onClick={onSendBillingEmail}
                                    >
                                        Email küldése számlázáshoz
                                    </Button>
                                </>
                            )}
                        </ActionButtonWrapper>
                    </Form.Item>
                </EditForm>
            )}
        </Formik>
    );
};

export default DeletionsEditForm;
