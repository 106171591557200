import React, { ReactElement, useEffect, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { Form, SubmitButton } from 'formik-antd';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import styled from 'styled-components';
import { DownloadOutlined } from '@ant-design/icons';
import Spinner from '~/components/Spinner';
import EditForm from '~/components/EditForm';
import MasterDataSelect from '~/components/MasterDataSelect';
import ServiceEnums from '~/types/shared/service-enums';
import { MasterData } from '~/types/services/masterdata-service';
import FormBuilder from '~/containers/FormBuilder';
import EditFormSchema from './EditForm.validation';
import { Transcription } from '~/types/services/transcription-service';
import SapClientNumber from '~/components/SapClientNumber';
import SapClientModulesBuilderFieldNames from '~/types/shared/sap-client-modules-field-names';
import CaseNumberField from '~/components/CaseNumberField';
import MasterDataEnums from '~/types/shared/master-data-enums';
import PylonTable from '~/components/PylonTable';

interface EditFormProps {
    onSubmit: (values, helpers: FormikHelpers<[]>) => void;
    onGenerateAnnex: (sapClientNumber) => void;
    onSendBillingEmail: () => void;
    transcription: Transcription;
    isEditing: boolean;
    loading: boolean;
    isViewing: boolean;
}

const GenerateButton = styled(Button)`
    margin-left: 15px;
`;

const ButtonWrapper = styled.div`
    display: flex;
`;

const TranscriptionEditForm: React.FC<EditFormProps> = ({
    onSubmit,
    onGenerateAnnex,
    onSendBillingEmail,
    transcription,
    loading,
    isEditing,
    isViewing
}) => {
    const { t } = useTranslation();
    const [recipientSapClientFields, setRecipientSapClientFields] = useState(
        []
    );
    const [recipientSapClientValue, setRecipientSapClientValue] = useState(
        false
    );
    const [deliveringSapClientFields, setDeliveringSapClientFields] = useState(
        []
    );
    const [deliveringSapClientValue, setDeliveringSapClientValue] = useState(
        false
    );
    const initialPylons = get(transcription, 'pylons', []);
    const [pylons, setPylonsData] = useState(initialPylons);
    const buttonItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 }
    };

    useEffect(() => {
        if (initialPylons.length) {
            setPylonsData(initialPylons);
        }
    }, [initialPylons]);

    if (!transcription && isEditing) return <Spinner />;

    const initialStatus = get(transcription, 'status') as MasterData;
    const initialType = get(transcription, 'company') as MasterData;
    const initialCaseNumber = get(transcription, 'business_case_number');
    const getRecipientBuilderFields = (fields): void => {
        setRecipientSapClientFields(fields);
    };
    const getDeliveringBuilderFields = (fields): void => {
        setDeliveringSapClientFields(fields);
    };
    const recipientSapClientHasValue = (hasValue): void => {
        setRecipientSapClientValue(hasValue);
    };
    const deliveringSapClientHasValue = (hasValue): void => {
        setDeliveringSapClientValue(hasValue);
    };
    const setPylons = (key, data): void => {
        setPylonsData(data);
    };

    return (
        <Formik
            validationSchema={EditFormSchema}
            validateOnBlur
            onSubmit={onSubmit}
            initialValues={{
                status: initialStatus && {
                    key: initialStatus.id,
                    value: initialStatus.id,
                    label: initialStatus.label
                },
                business_case_number: initialCaseNumber && [
                    {
                        value: initialCaseNumber.id,
                        label: initialCaseNumber.label
                    }
                ],
                recipient_sap_client_number: get(
                    transcription,
                    'recipient_sap_client_number',
                    null
                ),
                delivering_sap_client_number: get(
                    transcription,
                    'delivering_sap_client_number',
                    null
                ),
                company: initialType && {
                    key: initialType.id,
                    value: initialType.id,
                    label: initialType.label
                },
                builder: get(transcription, 'builder', {}),
                pylons: initialPylons || pylons
            }}
        >
            {({
                values,
                handleChange,
                handleBlur,
                initialValues,
                isValid
            }): ReactElement => (
                <EditForm>
                    <CaseNumberField
                        disabled={isViewing || isEditing}
                        disableLiveSearch
                    />
                    <SapClientNumber
                        name="recipient_sap_client_number"
                        label={t(
                            'pages.content.fields.recipient_sap_client_number'
                        )}
                        placeholder={t(
                            'pages.content.fields.recipient_sap_client_number'
                        )}
                        disabled={isViewing}
                        searchable
                        initialValue={initialValues.recipient_sap_client_number}
                        moduleSapClientFields={recipientSapClientFields}
                        setFieldEnums={
                            SapClientModulesBuilderFieldNames.transcription
                                .recipient
                        }
                        getSapClientHasValue={recipientSapClientHasValue}
                        required
                    />
                    <FormBuilder
                        formKey="transcription_recipient_form"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isViewing}
                        getBuilderFields={getRecipientBuilderFields}
                        sapClientHasValue={recipientSapClientValue}
                    />
                    <SapClientNumber
                        name="delivering_sap_client_number"
                        label={t(
                            'pages.content.fields.delivering_sap_client_number'
                        )}
                        placeholder={t(
                            'pages.content.fields.delivering_sap_client_number'
                        )}
                        disabled={isViewing}
                        searchable
                        initialValue={
                            initialValues.delivering_sap_client_number
                        }
                        moduleSapClientFields={deliveringSapClientFields}
                        setFieldEnums={
                            SapClientModulesBuilderFieldNames.transcription
                                .delivering
                        }
                        getSapClientHasValue={deliveringSapClientHasValue}
                    />
                    <FormBuilder
                        formKey="transcription_delivering_form"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isViewing}
                        getBuilderFields={getDeliveringBuilderFields}
                        sapClientHasValue={deliveringSapClientValue}
                    />
                    <MasterDataSelect
                        required
                        module={ServiceEnums.Content}
                        placeholder={t('pages.content.edit.selectStatus')}
                        type={MasterDataEnums.DocumentEditableStatus}
                        name="status"
                        label={t('pages.content.fields.status')}
                        isFormik
                        disabled={isViewing}
                        initialKey={MasterDataEnums.ContentStatusInProgress}
                        isCreate={!isViewing && !isEditing}
                    />
                    <MasterDataSelect
                        required
                        module={ServiceEnums.Content}
                        placeholder={t('pages.content.edit.company')}
                        type="document_company"
                        name="company"
                        label={t('pages.content.fields.company')}
                        isFormik
                        disabled={isViewing}
                    />
                    <FormBuilder
                        formKey="transcription_documents_form"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isViewing}
                        getFileResponse={setPylons}
                    />
                    <PylonTable
                        pylons={values.pylons}
                        setPylonsData={setPylons}
                        disabled={isViewing}
                    />
                    <ButtonWrapper>
                        {!isViewing && (
                            <Form.Item name="submit" {...buttonItemLayout}>
                                <SubmitButton type="primary" loading={loading}>
                                    {t('basic.saveButton')}
                                </SubmitButton>
                            </Form.Item>
                        )}
                        {isEditing && (
                            <>
                                <GenerateButton
                                    loading={loading}
                                    icon={<DownloadOutlined />}
                                    onClick={(): void =>
                                        onGenerateAnnex(
                                            values.recipient_sap_client_number
                                        )
                                    }
                                >
                                    {t(
                                        'pages.content.edit.recipientAnnexButton'
                                    )}
                                </GenerateButton>
                                <GenerateButton
                                    loading={loading}
                                    icon={<DownloadOutlined />}
                                    disabled={
                                        !values.delivering_sap_client_number
                                    }
                                    onClick={(): void =>
                                        onGenerateAnnex(
                                            values.delivering_sap_client_number
                                        )
                                    }
                                >
                                    {t(
                                        'pages.content.edit.deliveringAnnexButton'
                                    )}
                                </GenerateButton>
                                <Button
                                    disabled={!isValid}
                                    loading={loading}
                                    onClick={onSendBillingEmail}
                                >
                                    Email küldése számlázáshoz
                                </Button>
                            </>
                        )}
                    </ButtonWrapper>
                </EditForm>
            )}
        </Formik>
    );
};

export default TranscriptionEditForm;
