/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { FieldArray, FormikHandlers, useFormikContext } from 'formik';
import styled from 'styled-components';
import get from 'lodash/get';
import size from 'lodash/size';
import { Card, Button, Modal } from 'antd';
import { usePromiseTracker } from 'react-promise-tracker';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import ServiceEnums from '~/types/shared/service-enums';
import FormBuilder from '~/containers/FormBuilder/index';

const FormBuilderArrayCard = styled(Card)`
    margin: 15px 0;
`;

const FormBuilderArrayButton = styled(Button)`
    margin: 15px 0;
`;

interface FormBuilderArrayProps {
    formKey: string;
    area?: ServiceEnums;
    onChange: FormikHandlers['handleChange'];
    onBlur: FormikHandlers['handleBlur'];
    disabled?: boolean;
    addButtonTitle: string;
    removeConfirmLabel: string;
    notificationHandler?: (arrayIndex) => void;
    isEditing?: boolean;
}

const FormBuilderArray: React.FC<FormBuilderArrayProps> = ({
    area,
    formKey,
    onChange,
    onBlur,
    disabled,
    addButtonTitle,
    removeConfirmLabel,
    isEditing = false,
    notificationHandler
}) => {
    const { getFieldMeta, setFieldValue } = useFormikContext();
    const { t } = useTranslation();
    const [initialCount, setInitialCount] = useState<number>(0);
    const { promiseInProgress } = usePromiseTracker({
        area: area || ServiceEnums.FormBuilder
    });

    const arrayFormFieldKeys =
        Object.keys(
            get(
                getFieldMeta('builderArrayFieldNames'),
                `initialValue.${formKey}`,
                {}
            ) || {}
        ) || [];

    useEffect(() => {
        let maxCount = 0;

        arrayFormFieldKeys.forEach(fieldKey => {
            const count = size(
                get(getFieldMeta(`builder`), `initialValue.${fieldKey}`, [])
            );
            if (count > maxCount) {
                maxCount = count;
            }
        });

        setInitialCount(maxCount);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (initialCount !== 0) {
            const arrayValues = [];
            for (let i = 0; i !== initialCount; i += 1) {
                arrayValues.push([]);
            }
            setFieldValue(`builderArray.${formKey}`, arrayValues);
        }
    }, [initialCount]); // eslint-disable-line react-hooks/exhaustive-deps

    const showDeleteConfirm = (arrayHelpers, index): void => {
        Modal.confirm({
            title: '',
            icon: <ExclamationCircleOutlined />,
            content: removeConfirmLabel,
            okText: t('basic.okText'),
            okType: 'danger',
            cancelText: t('basic.cancelText'),
            onOk() {
                arrayFormFieldKeys.forEach(fieldName => {
                    setFieldValue(
                        `builder.${fieldName}`,
                        get(
                            arrayHelpers,
                            `form.values.builder.${fieldName}`,
                            []
                        ).filter((v, i) => i !== index)
                    );
                });
                arrayHelpers.remove(index);
            }
        });
    };

    return (
        <FieldArray
            name={`builderArray.${formKey}`}
            render={(arrayHelpers): React.ReactNode => {
                const values = get(
                    arrayHelpers,
                    `form.values.builderArray.${formKey}`,
                    []
                );

                return (
                    <div>
                        {values.length !== 0 &&
                            values.map((data, index) => {
                                if (data === null) return null;
                                return (
                                    <FormBuilderArrayCard
                                        key={`formBuilderArray${index}`}
                                    >
                                        <FormBuilder
                                            formKey={formKey}
                                            area={area}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            disabled={disabled}
                                            isArray
                                            arrayIndex={index}
                                        />
                                        <FormBuilderArrayButton
                                            danger
                                            onClick={(): void =>
                                                showDeleteConfirm(
                                                    arrayHelpers,
                                                    index
                                                )
                                            }
                                            disabled={disabled}
                                            loading={promiseInProgress}
                                        >
                                            {t('basic.deleteButton')}
                                        </FormBuilderArrayButton>
                                        {isEditing && (
                                            <FormBuilderArrayButton
                                                onClick={(): void =>
                                                    notificationHandler(index)
                                                }
                                                disabled={disabled}
                                                loading={promiseInProgress}
                                            >
                                                {t(
                                                    'pages.content.edit.planningNotificationWorkPlace'
                                                )}
                                            </FormBuilderArrayButton>
                                        )}
                                    </FormBuilderArrayCard>
                                );
                            })}
                        <FormBuilderArrayButton
                            type="primary"
                            onClick={(): void => arrayHelpers.push([])}
                            disabled={disabled}
                            loading={promiseInProgress}
                        >
                            {addButtonTitle}
                        </FormBuilderArrayButton>
                    </div>
                );
            }}
        />
    );
};

export default FormBuilderArray;
