/* eslint-disable @typescript-eslint/camelcase */
import Yup from '~/shared/utils/yup';
import {
    getClientNumberMaxValue,
    getClientNumberMinValue
} from '~/shared/helpers/sap-client-number';

export default Yup.object().shape({
    business_case_number: Yup.array()
        .of(
            Yup.object().shape({
                value: Yup.number(),
                label: Yup.string()
            })
        )
        .required('pages.content.validations.requiredCaseNumber'),
    sap_client_number: Yup.number()
        .nullable(true)
        .min(getClientNumberMinValue())
        .max(getClientNumberMaxValue())
        .typeError('validations.number.number'),
    company: Yup.object().required('pages.content.validations.requiredType'),
    status: Yup.object().required('pages.content.validations.requiredStatus')
});
