import React, { useContext, useState } from 'react';
import { Typography, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { usePromiseTracker } from 'react-promise-tracker';
import { useHistory } from 'react-router-dom';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import ServiceEnums from '~/types/shared/service-enums';
import Table from '~/components/Table';
import { ActionProps } from '~/components/Table/types/ActionProps';
import { ConfiguratorContext } from '~/shared/context/configurator';
import { PermissionContext } from '~/shared/context/permission';
import ConfiguratorEnums from '~/types/shared/configurator-enums';
import SapClientService from '~/services/sap-client-service';
import { SapClient } from '~/types/services/sap-client-service';
import columns from '~/pages/SapClient/List/columns';

const sapClientService = new SapClientService();

const SapClientListPage: React.FC = () => {
    const { t } = useTranslation();
    const { push } = useHistory();
    const { promiseInProgress: buyerLoading } = usePromiseTracker({
        area: ServiceEnums.SapClient
    });
    const { promiseInProgress: configLoading } = usePromiseTracker({
        area: ServiceEnums.Configurator
    });
    const [sapClients, setSapClients] = useState<SapClient[]>([]);
    const [total, setTotal] = useState(0);
    const configurations = useContext(ConfiguratorContext);
    const { permissions, userHasPermission } = useContext(PermissionContext);
    const userPermissionNames =
        permissions && permissions.map(permission => permission.name);

    const documentArchiveConfig = get(
        configurations.getConfig(ConfiguratorEnums.ContentArchiveConfigKey),
        'value[0].name'
    );

    const fetchSapClients = (
        filters: object = {},
        orders: object = {},
        page = 1
    ): void => {
        sapClientService.fetchSapClients(filters, orders, page).then(res => {
            setSapClients(res.data);
            setTotal(res.total);
        });
    };

    const exportSapClients = (
        selectedRows: number[],
        filters: object = {},
        orders: object = {}
    ): void => {
        sapClientService.exporSapClients(filters, orders);
    };

    const handleGroupDelete = (selectedRows): void => {
        sapClientService
            .deleteSapClients(selectedRows)
            .then(() => fetchSapClients());
    };

    const showDeleteConfirm = (selectedRows): void => {
        Modal.confirm({
            title: t(
                documentArchiveConfig ===
                    ConfiguratorEnums.ContentOnlyArchivable
                    ? 'pages.content.list.confirmArchivesTitle'
                    : 'pages.content.list.confirmDeleteTitle'
            ),
            icon: <ExclamationCircleOutlined />,
            content: t('pages.content.list.confirmDeleteMessage'),
            okText: t('basic.okText'),
            okType: 'danger',
            cancelText: t('basic.cancelText'),
            onOk() {
                handleGroupDelete(selectedRows);
            }
        });
    };

    const actions: ActionProps[] = [
        {
            multiple: true,
            label: t(
                documentArchiveConfig ===
                    ConfiguratorEnums.ContentOnlyArchivable
                    ? 'basic.archiveSelectedButton'
                    : 'basic.deleteSelectedButton'
            ),
            danger: true,
            onClick: showDeleteConfirm
        },
        {
            label: t('basic.exportButton'),
            type: 'ghost',
            onClick: exportSapClients
        },
        {
            label: t('basic.addNewButton'),
            onClick: (): void => push('/content/vevok/new')
        }
    ];

    const cols = columns(
        documentArchiveConfig,
        userPermissionNames,
        showDeleteConfirm,
        t
    );

    return (
        <div>
            <Typography.Title level={1}>
                {t('pages.content.list.buyerList')}
            </Typography.Title>
            <Table
                rowKey="id"
                loading={buyerLoading || configLoading}
                columns={cols}
                dataSource={sapClients}
                actions={
                    userHasPermission(userPermissionNames, 'vevok.manage')
                        ? actions
                        : []
                }
                fetchSource={fetchSapClients}
                total={total}
            />
        </div>
    );
};

export default SapClientListPage;
