enum TranscriptionBuilderFieldNameEnums {
    RecipientName = 'recipient_name',
    RecipientAddress = 'recipient_address',
    RecipientIsProblematic = 'recipient_tenant_problematic',
    RecipientProblematicComment = 'recipient_problematic_label',
    DeliveringName = 'delivering_name',
    DeliveringAddress = 'delivering_address',
    DeliveringIsProblematic = 'delivering_tenant_problematic',
    DeliveringProblematicComment = 'delivering_problematic_label',
    TranscriptionPoles = 'modified_columns',
    Establishment = 'transcription_establishment'
}

export default TranscriptionBuilderFieldNameEnums;
