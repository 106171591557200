import React, { ReactElement, useState } from 'react';
import { Formik } from 'formik';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import Spinner from '~/components/Spinner';
import EditForm from '~/components/EditForm';
import MasterDataSelect from '~/components/MasterDataSelect';
import ServiceEnums from '~/types/shared/service-enums';
import { MasterData } from '~/types/services/masterdata-service';
import FormBuilder from '~/containers/FormBuilder';
import { Invoice } from '~/types/services/invoice-service';
import CaseNumberField from '~/components/CaseNumberField';
import SapClientModulesBuilderFieldNames from '~/types/shared/sap-client-modules-field-names';
import SapClientNumber from '~/components/SapClientNumber';

interface EditFormProps {
    invoice: Invoice;
    isViewing: boolean;
}

const InvoiceEditForm: React.FC<EditFormProps> = ({ invoice, isViewing }) => {
    const { t } = useTranslation();
    const [sapClientFields, setSapClientFields] = useState([]);
    const [sapClientValue, setSapClientValue] = useState(false);

    if (!invoice && isViewing) return <Spinner />;

    const initialStatus = get(invoice, 'status') as MasterData;
    const initialCaseNumber = get(invoice, 'business_case_number');
    const getBuilderFields = (fields): void => {
        setSapClientFields(fields);
    };
    const sapClientHasValue = (hasValue): void => {
        setSapClientValue(hasValue);
    };

    return (
        <Formik
            validateOnBlur
            onSubmit={(): null => null}
            initialValues={{
                status: initialStatus && {
                    key: initialStatus.id,
                    value: initialStatus.id,
                    label: initialStatus.label
                },
                business_case_number: initialCaseNumber && [
                    {
                        value: initialCaseNumber.id,
                        label: initialCaseNumber.label
                    }
                ],
                sap_client_number: get(invoice, 'sap_client_number', null),
                builder: get(invoice, 'builder', {})
            }}
        >
            {({ handleChange, handleBlur, initialValues }): ReactElement => (
                <EditForm>
                    <CaseNumberField disabled={isViewing} disableGenerate />
                    <SapClientNumber
                        name="sap_client_number"
                        label={t('pages.content.fields.sap_client_number')}
                        placeholder={t(
                            'pages.content.fields.sap_client_number'
                        )}
                        disabled={isViewing}
                        searchable
                        initialValue={initialValues.sap_client_number}
                        moduleSapClientFields={sapClientFields}
                        setFieldEnums={
                            SapClientModulesBuilderFieldNames.invoice
                        }
                        getSapClientHasValue={sapClientHasValue}
                    />
                    <FormBuilder
                        formKey="invoice_form_basics"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isViewing}
                        getBuilderFields={getBuilderFields}
                        sapClientHasValue={sapClientValue}
                    />
                    <MasterDataSelect
                        required
                        module={ServiceEnums.Content}
                        placeholder={t('pages.content.edit.selectStatus')}
                        type="document_status"
                        name="status"
                        label={t('pages.content.fields.status')}
                        isFormik
                        disabled={isViewing}
                    />
                    <FormBuilder
                        formKey="invoice_data"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isViewing}
                    />
                </EditForm>
            )}
        </Formik>
    );
};

export default InvoiceEditForm;
